var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "control has-icons-left w-100 mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.listStore.query,
              expression: "listStore.query",
            },
          ],
          staticClass: "form-control border-primary",
          attrs: {
            type: "text",
            placeholder: "Enter a keyword",
            autocomplete: "off",
            autofocus: "",
          },
          domProps: { value: _vm.listStore.query },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.listStore, "query", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c(
        "LoadingOverlay",
        {
          attrs: {
            loading: _vm.listStore.isLoading,
            failed: _vm.listStore.failed,
          },
          on: { retry: _vm.listStore.reload },
        },
        [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-hover mb-0" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Date")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("User")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Changes")]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.listStore.items, function (log) {
                    return _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("toLocaleDateTimeString")(log.createdAt)
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      log.user
                        ? _c("td", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(log.user.firstName) +
                                " " +
                                _vm._s(log.user.lastName) +
                                "\n                        "
                            ),
                          ])
                        : _c("td", [_vm._v("n/a")]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "ul",
                          _vm._l(log.changes, function (change) {
                            return _c("li", [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(change.path) +
                                  ": " +
                                  _vm._s(JSON.stringify(change.value)) +
                                  "\n                                "
                              ),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  _vm.listStore.noRecords ? _c("NoRecords") : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("v-pagination", {
        attrs: {
          pageCount: _vm.listStore.pageCount,
          value: _vm.listStore.page,
        },
        on: { input: _vm.listStore.changePage },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }