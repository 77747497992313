var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "row mb-5" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("FormInput", {
              attrs: {
                label: "Project Title",
                error: _vm.errors.title,
                required: "",
              },
              model: {
                value: _vm.values.title,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "title", $$v)
                },
                expression: "values.title",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("FormEnumMultiSelect", {
              attrs: {
                label: "Type",
                enum: _vm.PastProjectType,
                error: _vm.errors.projectType,
                required: "",
              },
              model: {
                value: _vm.values.projectType,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "projectType", $$v)
                },
                expression: "values.projectType",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("FormTextarea", {
              attrs: {
                label: "Project Description",
                rows: "5",
                error: _vm.errors.description,
                required: "",
              },
              model: {
                value: _vm.values.description,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "description", $$v)
                },
                expression: "values.description",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("FormTreeselect", {
              attrs: {
                label: "Product List",
                placeholder:
                  "If you delivered a product, please select or enter the most relevant product category.",
                options: _vm.productOptions,
                sortValueBy: "LEVEL",
                error: _vm.errors.productList,
                multiple: "",
                flat: "",
              },
              model: {
                value: _vm.values.productList,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "productList", $$v)
                },
                expression: "values.productList",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("FormTreeselect", {
              attrs: {
                label: "Service List",
                placeholder:
                  "If you delivered a service, please select or enter the most relevant service category.",
                options: _vm.serviceOptions,
                sortValueBy: "LEVEL",
                error: _vm.errors.serviceTags,
                multiple: "",
                flat: "",
              },
              model: {
                value: _vm.values.serviceTags,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "serviceTags", $$v)
                },
                expression: "values.serviceTags",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("FormInput", {
              attrs: {
                label: "Date Started",
                type: "date",
                error: _vm.errors.dateConducted,
                required: "",
              },
              model: {
                value: _vm.values.dateConducted,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "dateConducted", $$v)
                },
                expression: "values.dateConducted",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("FormInput", {
              attrs: {
                label: "Date Completed",
                type: "date",
                error: _vm.errors.dateCompleted,
              },
              model: {
                value: _vm.values.dateCompleted,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "dateCompleted", $$v)
                },
                expression: "values.dateCompleted",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("FormInput", {
              attrs: {
                label: "Who is/was the customer?",
                error: _vm.errors.customer,
              },
              model: {
                value: _vm.values.customer,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "customer", $$v)
                },
                expression: "values.customer",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("hr", { staticClass: "my-3" }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "ActionButton",
                {
                  staticClass: "btn-primary",
                  attrs: { type: "submit", loading: _vm.loading },
                },
                [_vm._v("\n                    Submit\n                ")]
              ),
              _vm._v(" "),
              _vm.allowDestroy
                ? _c(
                    "ActionButton",
                    {
                      staticClass: "btn-danger",
                      attrs: { type: "button", loading: _vm.destroying },
                      on: { click: _vm.destroy },
                    },
                    [_vm._v("\n                    Delete\n                ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }