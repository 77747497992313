var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    {
      staticClass: "d-table w-100 p-3 box-shadow mb-3 text-decoration-none",
      attrs: { href: _vm.organizationUrl },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-table-cell align-middle",
          staticStyle: { width: "60px" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../images/placeholder.jpg"),
              alt: _vm.title,
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-table-cell align-middle pl-3" }, [
        _c("h6", { staticClass: "text-md font-weight-bold mb-1" }, [
          _vm._v("\n            " + _vm._s(_vm.title) + "\n        "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mb-0 text-muted font-weight-bold" }, [
          _vm._v("\n            " + _vm._s(_vm.subtitle) + "\n        "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }