var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "author-card-profile" }, [
    _c("div", { staticClass: "row author-card-avatar" }, [
      _c(
        "div",
        {
          staticClass: "small-12 medium-2 large-2 columns",
          staticStyle: { position: "relative" },
        },
        [
          _c("div", { staticClass: "circle" }, [
            _vm.authStore.user.pictureProfile
              ? _c("img", {
                  staticClass: "profile-pic",
                  attrs: { src: _vm.authStore.user.pictureProfile },
                })
              : _vm.authStore.user.lastName.length
              ? _c("div", { staticClass: "center avatar-letter" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.authStore.user.lastName.charAt(0)) +
                      "\n                "
                  ),
                ])
              : _c("i", {
                  staticClass: "fa fa-user fa-5x",
                  staticStyle: {
                    transform: "scale(0.75)",
                    display: "flex",
                    "justify-content": "center",
                  },
                }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "p-image",
              on: { click: _vm.profilePictureUploadInput },
            },
            [
              _c("i", { staticClass: "fa fa-camera upload-button" }),
              _vm._v(" "),
              _c("input", {
                staticClass: "file-upload",
                attrs: { type: "file", accept: "image/*" },
                on: { change: _vm.submitProfilePicture },
              }),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "author-card-details" }, [
      _c("h5", { staticClass: "author-card-name text-lg" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.authStore.user.firstName) +
            " " +
            _vm._s(_vm.authStore.user.lastName) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "author-card-position" }, [
        _vm._v("Joined " + _vm._s(_vm.joinDate)),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "author-card-position" }, [
        _vm._v(_vm._s(_vm.memberStatus)),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "author-card-position" }, [
        _vm._v(_vm._s(_vm.quiltyMemberStatus)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }