var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "LoadingOverlay",
        {
          attrs: {
            loading: _vm.listStore.isLoading,
            failed: _vm.listStore.failed,
          },
          on: { retry: _vm.listStore.reload },
        },
        [
          _vm._l(_vm.listStore.items, function (feedback) {
            return _c("Ticket", {
              key: feedback.feedbackid,
              attrs: {
                author: feedback.user
                  ? `${feedback.user.firstName} ${feedback.user.lastName}`
                  : null,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "ticket-field" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("Date Submitted"),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toLocaleDateString")(
                                  feedback.submissionDate
                                )
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ticket-field" }, [
                          _c("div", { staticClass: "title" }, [_vm._v("Type")]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-capitalize" }, [
                            _vm._v(_vm._s(feedback.type)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "ticket-field text-truncate" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("Page Triggered"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              { attrs: { to: feedback.pageTriggered } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(feedback.pageTriggered) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ticket-field" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("Status"),
                          ]),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "badge m-0 text-capitalize",
                            class: _vm.statusClass(feedback),
                            domProps: { textContent: _vm._s(feedback.status) },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "ticket-field" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("Actions"),
                            ]),
                            _vm._v(" "),
                            _c("MoreActions", [
                              _c(
                                "span",
                                {
                                  staticClass: "dropdown-item clickable",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeStatus(
                                        feedback,
                                        _vm.FeedbackStatus.New
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Status: New\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "dropdown-item clickable",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeStatus(
                                        feedback,
                                        _vm.FeedbackStatus.Planned
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Status: Planned\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "dropdown-item clickable",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeStatus(
                                        feedback,
                                        _vm.FeedbackStatus.Incorporated
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Status: Incorporated\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "dropdown-item clickable",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeStatus(
                                        feedback,
                                        _vm.FeedbackStatus.Rejected
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Status: Rejected\n                        "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("h6", { staticClass: "block-title mb-0 pb-0" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(feedback.title) +
                              "\n                    "
                          ),
                          _c("small", [_vm._v(_vm._s(feedback.description))]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _vm.listStore.noRecords
            ? _c("div", { staticClass: "text-center w-100 p-5 h6" }, [
                _vm._v("No records"),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("v-pagination", {
        attrs: {
          pageCount: _vm.listStore.pageCount,
          value: _vm.listStore.page,
        },
        on: { input: _vm.listStore.changePage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }