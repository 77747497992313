var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "header",
    { staticClass: "navbar-flex navbar-wrapper navbar-boxed" },
    [
      _c("a", { attrs: { href: "/dashboard/member", exact: "" } }, [
        _c("div", { staticClass: "mx-3" }, [
          _c("img", {
            staticStyle: { height: "60px" },
            attrs: {
              src: require("../../../../../common/images/Quilty_Logo_Assets/Full/PNG/Web/Quilty_Black_and_Blue_on_Transparent_Web.png"),
              alt: "GSX",
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "px-sm-3 w-100" }, [
        _c("div", { staticClass: "navbar justify-content-end" }, [
          _c(
            "form",
            {
              staticClass: "search-box",
              class: { "is-open": _vm.isSearchBoxVisible },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.goToSearch.apply(null, arguments)
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchQuery,
                    expression: "searchQuery",
                  },
                ],
                ref: "searchInput",
                attrs: {
                  type: "text",
                  placeholder: "Enter a Keyword, Phrase, Part Number, etc..",
                  autocomplete: "off",
                  required: "",
                },
                domProps: { value: _vm.searchQuery },
                on: {
                  blur: _vm.closeSearch,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape",
                      ])
                    )
                      return null
                    return _vm.closeSearch.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchQuery = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "search-close", on: { click: _vm.closeSearch } },
                [_c("i", { staticClass: "fe-icon-x" })]
              ),
            ]
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "navbar-nav d-none d-lg-block" }, [
            _vm.isRegistedUser
              ? _c("li", { staticClass: "nav-item" }, [_vm._m(0)])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _vm.user
                ? _c(
                    "ul",
                    { staticClass: "navbar-nav d-inline-block align-middle" },
                    [
                      _c(
                        "li",
                        { staticClass: "nav-item dropdown-toggle" },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "nav-link member-link d-none d-lg-inline-block",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.user.firstName) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("ProfileItems", {
                            staticClass: "dropdown-menu user-dropdown",
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.user
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "btn btn-primary ml-3 px-3 d-none d-lg-inline-block",
                      staticStyle: { "background-color": "#035c96" },
                      attrs: { to: "/signup" },
                    },
                    [
                      _vm._v(
                        "\n                    Try for Free\n                "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.user
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "btn btn-primary ml-3 px-3 d-none d-lg-inline-block",
                      staticStyle: { "background-color": "#035c96" },
                      attrs: { to: "/signin" },
                    },
                    [_vm._v("\n                    Login\n                ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      { staticClass: "nav-link text-tertiary", attrs: { href: "/dashboard" } },
      [
        _vm._v(
          "\n                        I/O Platform\n                        "
        ),
        _c("i", { staticClass: "fe-icon-arrow-right" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }