var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isInviteLicenseModalVisible
        ? _c("InviteLicenseModal", {
            attrs: { userid: _vm.licenseUserId },
            on: {
              submit: _vm.onInviteSubmit,
              close: _vm.hideInviteLicenseModal,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h5", [_vm._v(_vm._s(_vm.userListStore.count) + " Users")]),
      _vm._v(" "),
      _vm.hasAdminAccess
        ? _c("h5", [
            _vm._v(
              "\n        GSX: " +
                _vm._s(_vm.userListStore.gsxCount.individualSubsCount) +
                " Individual |\n        " +
                _vm._s(_vm.userListStore.gsxCount.enterpriseSubsCount) +
                " Enterprise\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasThirdPartyAdminAccess || _vm.hasAdminAccess
        ? _c("h5", [
            _vm._v(
              "\n        QRP: " +
                _vm._s(_vm.userListStore.quiltyCount.basicSubsCount) +
                " Basic |\n        " +
                _vm._s(_vm.userListStore.quiltyCount.intelligenceSubsCount) +
                " Intelligence |\n        " +
                _vm._s(_vm.userListStore.quiltyCount.enterpriseSubsCount) +
                " Enterprise\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "control has-icons-left w-100" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userListStore.query,
                  expression: "userListStore.query",
                },
              ],
              staticClass: "form-control border-primary",
              attrs: {
                type: "text",
                placeholder: "Enter a keyword",
                autocomplete: "off",
                autofocus: "",
              },
              domProps: { value: _vm.userListStore.query },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.userListStore, "query", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "LoadingOverlay",
        {
          attrs: {
            loading: _vm.userListStore.isLoading,
            failed: _vm.userListStore.failed,
          },
          on: { retry: _vm.userListStore.reload },
        },
        [
          _c(
            "div",
            {
              staticClass: "table-responsive",
              staticStyle: { "padding-bottom": "20px" },
            },
            [
              _c("table", { staticClass: "table table-hover mb-0" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _vm._v(" "),
                    _c("th", [_vm._v("Name")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Affiliated Organization")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Membership")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Affiliation Type")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Registration Date")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Actions")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.userCollectionStore.items, function (user) {
                      return [
                        _c(
                          "tr",
                          {
                            key: user.userid,
                            class: { opened: _vm.opened.includes(user.userid) },
                          },
                          [
                            _c(
                              "td",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggle(user.userid)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-list text-muted",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(user.firstName) +
                                  " " +
                                  _vm._s(user.lastName)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", [_vm._v(_vm._s(_vm.orgName(user)))]),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "mt-1 p-1 border-primary",
                                attrs: {
                                  type: "search",
                                  placeholder:
                                    _vm.orgName(user) || "Search Organization",
                                  autocomplete: "off",
                                  list: "organizations",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.orgSearch(
                                      $event.target.value,
                                      user
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "datalist",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: { id: "organizations" },
                                },
                                _vm._l(
                                  _vm.organizationListStore.items,
                                  function (organization) {
                                    return _c(
                                      "option",
                                      { key: organization.organizationid },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              organization.organizationName
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm.hasAdminAccess
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { padding: "4px 4px 4px 0" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    GSX\n                                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasAdminAccess
                                ? _c(
                                    "select",
                                    {
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeUserSubscriptionStatus(
                                            user,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: { value: "registered" },
                                          domProps: {
                                            selected:
                                              !_vm.subscriptionStatus(user) ||
                                              !_vm.hasSubscription(user),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Registered\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        {
                                          attrs: { value: "individual" },
                                          domProps: {
                                            selected:
                                              _vm.hasSubscription(user) &&
                                              _vm.subscriptionStatus(user) &&
                                              _vm.subscriptionStatus(user) ===
                                                "individual",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Individual\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        {
                                          attrs: { value: "gse_enterprise" },
                                          domProps: {
                                            selected:
                                              _vm.hasSubscription(user) &&
                                              _vm.subscriptionStatus(user) &&
                                              _vm.subscriptionStatus(user) !==
                                                "individual",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Enterprise\n                                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.subscriptionEndsAt(user) &&
                              _vm.hasSubscription(user) &&
                              _vm.hasAdminAccess
                                ? _c("div", [
                                    _c("input", {
                                      attrs: { type: "date" },
                                      domProps: {
                                        value: _vm.subscriptionEndsAt(user),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.updateSubscriptionEndsAt(
                                            user,
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { padding: "4px 4px 4px 0" } },
                                [_vm._v("QRP")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeQuiltySubscriptionStatus(
                                        user,
                                        $event
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { value: "free" },
                                      domProps: {
                                        selected:
                                          !_vm.quiltySubscriptionStatus(user) ||
                                          !_vm.hasQuiltySubscription(user),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Free\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    {
                                      attrs: { value: "basic" },
                                      domProps: {
                                        selected:
                                          _vm.hasQuiltySubscription(user) &&
                                          _vm.quiltySubscriptionStatus(user) &&
                                          _vm.quiltySubscriptionStatus(user) ===
                                            "basic",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Basic\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    {
                                      attrs: { value: "intelligence" },
                                      domProps: {
                                        selected:
                                          _vm.hasQuiltySubscription(user) &&
                                          _vm.quiltySubscriptionStatus(user) &&
                                          _vm.quiltySubscriptionStatus(user) ===
                                            "intelligence",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Intelligence\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    {
                                      attrs: { value: "enterprise" },
                                      domProps: {
                                        selected:
                                          _vm.hasQuiltySubscription(user) &&
                                          _vm.quiltySubscriptionStatus(user) &&
                                          _vm.quiltySubscriptionStatus(user) ===
                                            "enterprise",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Enterprise\n                                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.quiltySubscriptionEndsAt(user) &&
                              _vm.hasQuiltySubscription(user)
                                ? _c("div", [
                                    _c("input", {
                                      attrs: { type: "date" },
                                      domProps: {
                                        value:
                                          _vm.quiltySubscriptionEndsAt(user),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.updateQuiltySubscriptionEndsAt(
                                            user,
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(user.affiliation))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.joinDate(user.registrationDate))
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("MoreActions", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteUser(
                                            user.userid,
                                            user.firstName
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Delete\n                                    "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.opened.includes(user.userid)
                          ? _c("tr", { key: `${user.userid}-row2` }, [
                              _c("td", { attrs: { colspan: "7" } }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("h6", [_vm._v("Address")]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "ml-2 mb-4" }, [
                                      _c("div", [
                                        _vm._v(_vm._s(user.streetAddress)),
                                      ]),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(user.city) +
                                          " " +
                                          _vm._s(user.city ? "," : "") +
                                          "\n                                        " +
                                          _vm._s(user.state) +
                                          "\n                                        "
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(user.country) +
                                            "\n                                        "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("h6", [_vm._v("Contact")]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "ml-2 mb-4" }, [
                                      _c("div", [_vm._v(_vm._s(user.email))]),
                                      _vm._v(" "),
                                      _c("div", [_vm._v(_vm._s(user.phone))]),
                                    ]),
                                    _vm._v(" "),
                                    _c("h6", [_vm._v("Misc.")]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "ml-2 mb-4" }, [
                                      user.dateOfBirth
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                                            DOB: " +
                                                _vm._s(user.dateOfBirth) +
                                                "\n                                        "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      user.roleInOrganization
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                                            Role in Org: " +
                                                _vm._s(
                                                  user.roleInOrganization
                                                ) +
                                                "\n                                        "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      user.expertise
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                                            Expertise: " +
                                                _vm._s(user.expertise) +
                                                "\n                                        "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        [
                                          _c("InlineFormInput", {
                                            key: `${user.userid}-${user.contractId}`,
                                            staticStyle: { "flex-grow": "1" },
                                            attrs: { label: "Contract Id" },
                                            model: {
                                              value: user.contractId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  user,
                                                  "contractId",
                                                  $$v
                                                )
                                              },
                                              expression: "user.contractId",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-primary",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateUser(
                                                    user.userid,
                                                    user.contractId
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                Update contract\n                                            "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.hasQuiltySubscription(user)
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                                            License Manager\n                                            "
                                            ),
                                            _c("input", {
                                              attrs: {
                                                type: "checkbox",
                                                disabled:
                                                  user.licenses.length > 0,
                                              },
                                              domProps: {
                                                checked:
                                                  _vm.isLicenseManager[
                                                    user.userid
                                                  ] || user.licenses.length > 0,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  _vm.isLicenseManager = {
                                                    ..._vm.isLicenseManager,
                                                    [user.userid]:
                                                      !_vm.isLicenseManager[
                                                        user.userid
                                                      ],
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _vm.isLicenseManager[user.userid] ||
                                            user.licenses.length > 0
                                              ? _c(
                                                  "table",
                                                  {
                                                    staticClass:
                                                      "table table-hover mb-0",
                                                  },
                                                  [
                                                    _c("thead", [
                                                      _c("tr", [
                                                        _c("th", [
                                                          _vm._v("Name"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("th", [
                                                          _vm._v("Email"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("th", [
                                                          _vm._v("Status"),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("th", [
                                                          _vm._v("Action"),
                                                        ]),
                                                      ]),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "tbody",
                                                      [
                                                        _vm._l(
                                                          user.licenses,
                                                          function (license) {
                                                            return [
                                                              _c("tr", [
                                                                _c("td", [
                                                                  _vm._v(
                                                                    "\n                                                                " +
                                                                      _vm._s(
                                                                        license.firstName
                                                                      ) +
                                                                      "\n                                                                " +
                                                                      _vm._s(
                                                                        license.lastName
                                                                      ) +
                                                                      "\n                                                            "
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    "\n                                                                " +
                                                                      _vm._s(
                                                                        license.email
                                                                      ) +
                                                                      "\n                                                            "
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    "\n                                                                " +
                                                                      _vm._s(
                                                                        license.status
                                                                      ) +
                                                                      "\n                                                            "
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "td",
                                                                  [
                                                                    _c(
                                                                      "MoreActions",
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "dropdown-item clickable text-danger",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.deleteLicense(
                                                                                    user.userid,
                                                                                    license.email
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                                                        Delete License\n                                                                    "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]),
                                                            ]
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    _c("tfoot", [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                            attrs: {
                                                              colspan: "10",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-secondary btn-add",
                                                                attrs: {
                                                                  type: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.displayInviteLicenseModal(
                                                                        user.userid
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-plus text-primary",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    }),
                    _vm._v(" "),
                    _vm.userListStore.noRecords ? _c("NoRecords") : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("v-pagination", {
        attrs: {
          pageCount: _vm.userCollectionStore.pageCount,
          value: _vm.userCollectionStore.page,
        },
        on: { input: _vm.userCollectionStore.changePage },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }