var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "author-card pb-0" },
    [
      _c("LogoCard", {
        attrs: {
          logo: _vm.editStore.data.corpLogo,
          isProcessing: _vm.transaction.isProcessing,
        },
        on: {
          change: function ($event) {
            return _vm.transaction.run(_vm.updateLogo)
          },
        },
        model: {
          value: _vm.logoFile,
          callback: function ($$v) {
            _vm.logoFile = $$v
          },
          expression: "logoFile",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }