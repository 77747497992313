var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group row" },
    [
      _c("FormLabel", {
        staticClass: "col-5 col-form-label",
        class: _vm.labelClass,
        attrs: { label: _vm.label, help: _vm.help, required: _vm.required },
        on: { click: _vm.focus },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-7" },
        [
          _vm.focused
            ? _c(
                "textarea",
                _vm._b(
                  {
                    ref: "textarea",
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.error },
                    attrs: {
                      required: _vm.required,
                      rows: _vm.rows,
                      placeholder: _vm.placeholder,
                    },
                    domProps: { value: _vm.value },
                    on: {
                      input: function ($event) {
                        return _vm.$emit("input", $event.target.value)
                      },
                      blur: _vm.unfocus,
                    },
                  },
                  "textarea",
                  _vm.$attrs,
                  false
                )
              )
            : _c("InputField", {
                attrs: {
                  value: _vm.value,
                  error: _vm.error,
                  placeholder: _vm.placeholder,
                },
                on: { focus: _vm.focus },
              }),
          _vm._v(" "),
          _c("FormFeedback", { attrs: { error: _vm.error } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }