var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "Modal",
        {
          attrs: { title: "Edit address" },
          on: { close: _vm.close },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "ActionButton",
                    {
                      staticClass: "btn-primary btn-sm",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("Edit")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c(
                    "FormSelect",
                    {
                      attrs: {
                        customClass: "text-capitalize",
                        label: "Type",
                        placeholder: "Select type",
                        required: "",
                      },
                      model: {
                        value: _vm.description,
                        callback: function ($$v) {
                          _vm.description = $$v
                        },
                        expression: "description",
                      },
                    },
                    _vm._l(_vm.addressDescriptions, function (item) {
                      return _c("option", { domProps: { value: item } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item) +
                            "\n                    "
                        ),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("AddressFormGroup", { attrs: { values: _vm.address } }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }