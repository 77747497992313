var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "center" }, [
      _c("div", [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _vm._v(
              "\n                Already a Quilty Space research subscriber?\n                "
            ),
            _c(
              "router-link",
              {
                staticStyle: {
                  "text-decoration": "none",
                  "font-weight": "bold",
                },
                attrs: { to: "/password-reset", target: "_blank" },
              },
              [_vm._v("\n                    Click here\n                ")]
            ),
            _vm._v("\n                to retrieve your password\n            "),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "container my-5",
            attrs: { id: "signup-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.signUp.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("FormInput", {
                    attrs: {
                      label: "First Name",
                      error: _vm.errors.firstName,
                      required: "",
                    },
                    model: {
                      value: _vm.values.firstName,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "firstName", $$v)
                      },
                      expression: "values.firstName",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("FormInput", {
                    attrs: {
                      label: "Last Name",
                      error: _vm.errors.lastName,
                      required: "",
                    },
                    model: {
                      value: _vm.values.lastName,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "lastName", $$v)
                      },
                      expression: "values.lastName",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("FormInput", {
                    attrs: {
                      label: "E-MAIL ADDRESS",
                      type: "email",
                      error: _vm.errors.email,
                      required: "",
                    },
                    model: {
                      value: _vm.values.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "email", $$v)
                      },
                      expression: "values.email",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("FormInput", {
                    attrs: {
                      label: "Best Number to Reach You",
                      type: "tel",
                      error: _vm.errors.phone,
                    },
                    model: {
                      value: _vm.values.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "phone", $$v)
                      },
                      expression: "values.phone",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("FormInput", {
                    attrs: {
                      label: "Company",
                      error: _vm.errors.organizationName,
                    },
                    model: {
                      value: _vm.values.organizationName,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "organizationName", $$v)
                      },
                      expression: "values.organizationName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("FormEnumMultiSelect", {
                    attrs: {
                      label: "Role",
                      enum: _vm.QuiltyUserRoleInOrganization,
                      error: _vm.errors.roleInOrganization,
                      allowEmpty: "",
                    },
                    model: {
                      value: _vm.values.roleInOrganization,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "roleInOrganization", $$v)
                      },
                      expression: "values.roleInOrganization",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c("FormInput", {
                    attrs: {
                      label: "Password",
                      type: "password",
                      error: _vm.errors.password,
                      help: _vm.PASSWORD_HELP,
                      required: "",
                    },
                    model: {
                      value: _vm.values.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "password", $$v)
                      },
                      expression: "values.password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c("FormInput", {
                    attrs: {
                      label: "Confirm Password",
                      type: "password",
                      error: _vm.errors.confirmPassword,
                      required: "",
                    },
                    model: {
                      value: _vm.values.confirmPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "confirmPassword", $$v)
                      },
                      expression: "values.confirmPassword",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-sm-12",
                  staticStyle: { "font-size": "12px", "margin-bottom": "16px" },
                },
                [
                  _c("p", [_vm._v("Password must:")]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "span",
                      {
                        style: `color: ${
                          _vm.hasEightCharacters ? "green" : "red"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n                                ✓\n                            "
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n                            Be a minimum of 8 characters\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "span",
                      {
                        style: `color: ${_vm.hasOneCapital ? "green" : "red"}`,
                      },
                      [
                        _vm._v(
                          "\n                                ✓\n                            "
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n                            Include at least 1 capital letter\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "span",
                      { style: `color: ${_vm.hasOneNumber ? "green" : "red"}` },
                      [
                        _vm._v(
                          "\n                                ✓\n                            "
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n                            Include at least 1 number\n                        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "span",
                      {
                        style: `color: ${
                          _vm.hasOneSpecialCharacter ? "green" : "red"
                        }`,
                      },
                      [
                        _vm._v(
                          "\n                                ✓\n                            "
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n                            Include at least 1 special character\n                        "
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [_c("RecaptchaDisclosure")],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-12" }, [
                _c("hr", { staticClass: "mt-2 mb-3" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-wrap justify-content-between align-items-center",
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("FormCheckbox", {
                          attrs: { required: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n                                        I agree to the\n                                        "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://www.globalspaceexchange.com/terms-of-service",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            Terms & Conditions\n                                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                                        and\n                                        "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://www.globalspaceexchange.com/privacy-policy",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                            Privacy & Cookie Policy\n                                        "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.terms1,
                            callback: function ($$v) {
                              _vm.terms1 = $$v
                            },
                            expression: "terms1",
                          },
                        }),
                        _vm._v(" "),
                        _c("FormCheckbox", {
                          attrs: { required: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(
                                    "\n                                        I acknowledge that Quilty Analytics LLC dba Quilty Space\n                                        is a financial research publishing company (publisher)\n                                        and does not provide investment advice to its\n                                        subscribers or users or advocate for the purchase or\n                                        sale of any investment or security, and Quilty Analytics\n                                        and its employees, representatives, content providers\n                                        and licensors will not be liable to you or any other\n                                        person as a result of your use of the publication\n                                        services for any consequential, indirect, punitive,\n                                        special, or exemplary damages, including without\n                                        limitation, losses or lost profits, whether based on\n                                        contract, tort, or other legal theory, in connection\n                                        with your use of the Site, our information or services.\n                                    "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.terms2,
                            callback: function ($$v) {
                              _vm.terms2 = $$v
                            },
                            expression: "terms2",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "ActionButton",
                      {
                        staticClass: "btn-primary",
                        staticStyle: { "background-color": "#035c96" },
                        attrs: {
                          type: "submit",
                          loading: _vm.signUpStore.isLoading,
                          disabled: !_vm.canSubmitForm,
                        },
                      },
                      [
                        _vm._v(
                          "\n                                Sign Up\n                            "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text-center my-5" }, [
      _c("h3", { staticClass: "h4 pb-1" }, [_vm._v("Create a Free Account")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }