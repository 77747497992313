var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("InlineFormMultiSelect", {
    attrs: {
      label: "Satellite Product Tier Selection",
      options: _vm.options,
      value: _vm.formattedValue,
      multiple: "",
    },
    on: { input: _vm.input },
    scopedSlots: _vm._u([
      {
        key: "option",
        fn: function (props) {
          return [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c("span", [_vm._v(_vm._s(props.option.name))]),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(props.option.definition)),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }