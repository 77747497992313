import { marshal, Nullable } from "@gsx/common";
import { injectable } from "inversify";
import { Api } from "../../../../../common/src/api/Api";
import { SubmitStore } from "../../../../../common/src/stores/general/SubmitStore";
import { SpotlightBody } from "@gsx/common/src/types/http/admin/spotlight";
import { createSpotlightEmptyBody } from "../../../utility/entity/spotlight";

@injectable()
export class SpotlightCreateStore extends SubmitStore<SpotlightBody> {
    readonly data: Nullable<SpotlightBody> = createSpotlightEmptyBody();
    spotlightMedia: FormData = new FormData();

    constructor(private readonly api: Api) {
        super();
    }

    protected async request(): Promise<void> {
        const body = marshal(this.data);
        await this.api.admin.spotlight.create(body, this.spotlightMedia);
    }
}
