var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("Breadcrumbs", {
        attrs: { title: _vm.title, breadcrumbs: _vm.breadcrumbs },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container mb-4 admin-view" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-3 pb-5" }, [
            _c(
              "div",
              { staticClass: "author-card pb-3" },
              [
                _c("div", { staticClass: "author-card-cover default" }),
                _vm._v(" "),
                _c("AuthorCardProfile"),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "wizard" }, [
              _c(
                "nav",
                { staticClass: "list-group list-group-flush" },
                [
                  _c("ListGroupItem", {
                    attrs: {
                      name: "Requests",
                      icon: "fe-icon-tag",
                      to: "/quiltyAdmin/requests",
                    },
                  }),
                  _vm._v(" "),
                  _c("ListGroupItem", {
                    attrs: {
                      name: "User Accounts",
                      icon: "fas fa-users",
                      to: "/quiltyAdmin/accounts",
                    },
                  }),
                  _vm._v(" "),
                  _c("ListGroupItem", {
                    attrs: {
                      name: "Activity Logs",
                      icon: "fa fa-list",
                      to: "/quiltyAdmin/activity",
                    },
                  }),
                  _vm._v(" "),
                  _c("ListGroupItem", {
                    attrs: {
                      name: "Organizations",
                      icon: "far fa-building",
                      to: "/quiltyAdmin/organizations",
                    },
                  }),
                  _vm._v(" "),
                  _c("ListGroupItem", {
                    attrs: {
                      name: "Reports",
                      icon: "far fa-file-alt",
                      to: "/quiltyAdmin/reports",
                    },
                  }),
                  _vm._v(" "),
                  _c("ListGroupItem", {
                    attrs: {
                      name: "Account Creation",
                      icon: "fa fa-user-plus",
                      to: "/quiltyAdmin/accountCreation",
                    },
                  }),
                  _vm._v(" "),
                  _c("ListGroupItem", {
                    attrs: {
                      name: "Flash News",
                      icon: "far fa-newspaper",
                      to: "/quiltyAdmin/flashNews",
                    },
                  }),
                  _vm._v(" "),
                  _c("ListGroupItem", {
                    attrs: {
                      name: "Merger Acquisitions",
                      icon: "far fa-object-group",
                      to: "/quiltyAdmin/mergerAcquisitions",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-9 pb-5" }, [_c("router-view")], 1),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }