var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "author-card-cover", style: _vm.logoStyle }, [
    _vm.value
      ? _c(
          "div",
          { staticClass: "btn btn-group" },
          [
            _c(
              "ActionButton",
              {
                staticClass: "btn btn-secondary btn-cancel-logo",
                attrs: { loading: _vm.isProcessing },
                on: { click: _vm.clear },
              },
              [_vm._v("\n            Cancel\n        ")]
            ),
            _vm._v(" "),
            _c(
              "ActionButton",
              {
                staticClass: "btn btn-secondary btn-save-logo",
                attrs: { loading: _vm.isProcessing },
                on: { click: _vm.change },
              },
              [_vm._v("\n            Save\n        ")]
            ),
          ],
          1
        )
      : _c(
          "label",
          {
            staticClass: "btn btn-secondary btn-upload-logo",
            attrs: { for: "organizationLogo" },
          },
          [_vm._v("\n        Change Organization Logo\n    ")]
        ),
    _vm._v(" "),
    _c("input", {
      ref: "organizationLogo",
      attrs: { id: "organizationLogo", type: "file", accept: "image/*" },
      on: { change: _vm.changeOrganizationLogo },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }