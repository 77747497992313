var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isModalVisible
        ? _c("SubmitRequestModal", {
            on: { close: _vm.closeModal, submit: _vm.onSubmitRequest },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-end pb-3" }, [
        _c("div", { staticClass: "form-inline" }, [
          _c(
            "label",
            { staticClass: "text-muted mr-3", attrs: { for: "order-sort" } },
            [_vm._v("Sort Requests")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.sortOrder,
                  expression: "sortOrder",
                },
              ],
              staticClass: "form-control text-capitalize",
              attrs: { id: "order-sort" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.sortOrder = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { domProps: { value: null } }, [_vm._v("All")]),
              _vm._v(" "),
              _vm._l(_vm.requestStatuses, function (option) {
                return _c("option", { domProps: { value: option } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(option) +
                      "\n                "
                  ),
                ])
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "LoadingOverlay",
        {
          attrs: {
            loading: _vm.listStore.isLoading,
            failed: _vm.listStore.failed,
          },
          on: { retry: _vm.listStore.reload },
        },
        [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-hover mb-0" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Title")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Type")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Submission Date")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Status")]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.requests, function (request) {
                    return _c("tr", [
                      _c("td", { staticClass: "navi-link" }, [
                        _vm._v(_vm._s(request.title)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-capitalize" }, [
                        _vm._v(_vm._s(request.type)),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("toLocaleDateString")(request.submissionDate)
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", {
                          staticClass: "badge m-0 text-capitalize",
                          class: _vm.badgeClass(request),
                          domProps: { textContent: _vm._s(request.status) },
                        }),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  _vm.noRecords ? _c("NoRecords") : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "mb-4" }),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button" },
            on: { click: _vm.showModal },
          },
          [_vm._v("\n            Submit New Request\n        ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }