var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("nav", { staticClass: "pagination" }, [
    _c(
      "a",
      {
        class: ["prev-btn", _vm.firstPageSelected() ? _vm.disabledClass : ""],
        attrs: { tabindex: _vm.firstPageSelected() ? -1 : 0 },
        on: {
          click: function ($event) {
            return _vm.prevPage()
          },
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.prevPage()
          },
        },
      },
      [_c("i", { staticClass: "fe-icon-chevron-left" }), _vm._v("Prev\n  ")]
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "pages" },
      _vm._l(_vm.pages, function (page) {
        return _c(
          "li",
          {
            class: [
              "d-none d-sm-inline-block",
              page.selected ? _vm.activeClass : "",
              page.disabled ? _vm.disabledClass : "",
              page.breakView ? _vm.breakViewClass : "",
            ],
          },
          [
            page.breakView
              ? _c(
                  "a",
                  { attrs: { tabindex: "0" } },
                  [
                    _vm._t("breakViewContent", function () {
                      return [_vm._v(_vm._s(_vm.breakViewText))]
                    }),
                  ],
                  2
                )
              : page.disabled
              ? _c("a", { attrs: { tabindex: "0" } }, [
                  _vm._v(_vm._s(page.content)),
                ])
              : _c(
                  "a",
                  {
                    attrs: { tabindex: "0" },
                    on: {
                      click: function ($event) {
                        return _vm.handlePageSelected(page.index + 1)
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handlePageSelected(page.index + 1)
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(page.content) + "\n      ")]
                ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        class: ["next-btn", _vm.lastPageSelected() ? _vm.disabledClass : ""],
        attrs: { tabindex: _vm.lastPageSelected() ? -1 : 0 },
        on: {
          click: function ($event) {
            return _vm.nextPage()
          },
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.nextPage()
          },
        },
      },
      [_vm._v("\n    Next"), _c("i", { staticClass: "fe-icon-chevron-right" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }