var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("tr", [
    _c(
      "td",
      [
        _c(
          "router-link",
          { staticClass: "navi-link", attrs: { to: _vm.pastProjectUrl } },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.pastProject.title) + "\n        "
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("td", { staticClass: "text-capitalize" }, [
      _vm._v(_vm._s(_vm.pastProject.projectType)),
    ]),
    _vm._v(" "),
    _c("td", [
      _vm._v(_vm._s(_vm._f("toDateUtcString")(_vm.pastProject.dateConducted))),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("span", {
        staticClass: "badge badge-accent m-0 text-capitalize",
        domProps: { textContent: _vm._s(_vm.status) },
      }),
    ]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c(
          "MoreActions",
          [
            _c(
              "router-link",
              {
                staticClass: "dropdown-item",
                attrs: { to: _vm.pastProjectUrl },
              },
              [_vm._v("View")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dropdown-item clickable text-danger",
                on: { click: _vm.destroy },
              },
              [_vm._v("Delete")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }