var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _vm.isAddAddressModalVisible
        ? _c("AddAddressModal", {
            on: { submit: _vm.addAddress, close: _vm.hideAddAddressModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isEditAddressModalVisible
        ? _c("EditAddressModal", {
            attrs: { value: _vm.address },
            on: { submit: _vm.editAddress, close: _vm.hideEditAddressModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isMainModalVisible
        ? _c(
            "Modal",
            {
              attrs: { title: "Add point of contact" },
              on: { close: _vm.close },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "ActionButton",
                          {
                            staticClass: "btn-primary btn-sm",
                            attrs: { type: "submit" },
                          },
                          [_vm._v("Add")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                745080084
              ),
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("FormInput", {
                      attrs: {
                        label: "First Name",
                        required: "",
                        autofocus: "",
                      },
                      model: {
                        value: _vm.firstName,
                        callback: function ($$v) {
                          _vm.firstName = $$v
                        },
                        expression: "firstName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("FormInput", {
                      attrs: { label: "Last Name", required: "" },
                      model: {
                        value: _vm.lastName,
                        callback: function ($$v) {
                          _vm.lastName = $$v
                        },
                        expression: "lastName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("FormTel", {
                      attrs: { label: "Phone Number" },
                      model: {
                        value: _vm.phoneNumber,
                        callback: function ($$v) {
                          _vm.phoneNumber = $$v
                        },
                        expression: "phoneNumber",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("FormInput", {
                      attrs: { label: "E-mail", type: "email" },
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("FormInput", {
                      attrs: { label: "Title" },
                      model: {
                        value: _vm.title,
                        callback: function ($$v) {
                          _vm.title = $$v
                        },
                        expression: "title",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("FormLabel", { attrs: { label: "Address" } }),
                    _vm._v(" "),
                    !_vm.address
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary btn-add",
                            attrs: { type: "button" },
                            on: { click: _vm.displayAddAddressModal },
                          },
                          [_c("i", { staticClass: "fas fa-plus text-primary" })]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary btn-add",
                            attrs: { type: "button" },
                            on: { click: _vm.displayEditAddressModal },
                          },
                          [_c("i", [_vm._v("Edit")])]
                        ),
                    _vm._v(" "),
                    _vm.address
                      ? _c("p", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.address.streetAddress) +
                              "\n                    "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.address.country) +
                              ", " +
                              _vm._s(_vm.address.state) +
                              "\n                "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("FormCheckbox", {
                      attrs: { label: "Display Publicly" },
                      model: {
                        value: _vm.publicInfo,
                        callback: function ($$v) {
                          _vm.publicInfo = $$v
                        },
                        expression: "publicInfo",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }