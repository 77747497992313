var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("h1", { staticClass: "h3 bar tertiary" }, [_vm._v("Cookie Policy")]),
      _vm._v(" "),
      _c("div", { staticClass: "container my-5" }, [
        _c("strong", [_vm._v("Last updated October 01, 2019")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          '\n        This Cookie Policy explains how Global Space Exchange, Inc. ("Company", "we", "us", and\n        "our") uses cookies to recognize you when you visit our websites at\n        www.globalspaceexchange.com, ("Websites"). It explains what these technologies are and\n        why we use them, as well as your rights to control our use of them.\n        '
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        In some cases we may use cookies to collect personal information, or that becomes\n        personal information if we combine it with other information.\n        "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("What are cookies?")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        Cookies are small data files that are placed on your computer or mobile device when you\n        visit a website. Cookies are widely used by website owners in order to make their\n        websites work, or to work more efficiently, as well as to provide reporting information.\n        "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          '\n        Cookies set by the website owner (in this case, Global Space Exchange, Inc.) are called\n        "first party cookies". Cookies set by parties other than the website owner are called\n        "third party cookies". Third party cookies enable third party features or functionality\n        to be provided on or through the website.\n        '
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Why do we use cookies?")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          '\n        We use first and third party cookies for several reasons. Some cookies are required for\n        technical reasons in order for our Websites to operate, and we refer to these as\n        "essential" or "strictly necessary" cookies. Other cookies also enable us to track and\n        target the interests of our users to enhance the experience on our site. Third parties\n        serve cookies through our Websites for advertising, analytics and other purposes.\n        '
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("How can I control cookies?")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        You have the right to decide whether to accept or reject cookies. You can exercise your\n        cookie rights by setting your preferences in your browser settings. Essential cookies\n        cannot be rejected as they are strictly necessary to provide you with services. If you\n        choose to reject cookies, you may still use our website though your access to some\n        functionality and areas of our website may be restricted. You may also set or amend your\n        web browser controls to accept or refuse cookies. As the means by which you can refuse\n        cookies through your web browser controls vary from browser-to-browser, you should visit\n        your browser's help menu for more information.\n        "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        Several online sources provide up to date procedures for disabling cookies. For your\n        convivence we've provided one example below as a reference.\n        "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: { href: "https://www.cookiesandyou.com/", target: "_blank" },
          },
          [_vm._v("\n            https://www.cookiesandyou.com/\n        ")]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        The specific types of first and third party cookies served through our Websites and the\n        purposes they perform are described in below (please note that the specific cookies\n        served may vary depending on the specific Online Properties you visit):\n        "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Essential website cookies:")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        These cookies are strictly necessary to provide you with services available through our\n        Websites and to use some of its features, such as access to secure areas.\n        "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Performance and functionality cookies:")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        These cookies are used to enhance the performance and functionality of our Websites but\n        are non-essential to their use. However, without these cookies, certain functionality\n        (like videos) may become unavailable.\n        "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Analytics and customization cookies:")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        These cookies collect information that is used either in aggregate form to help us\n        understand how our Websites are being used or how effective our marketing campaigns are,\n        or to help us customize our Websites for you.\n        "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Unclassified cookies:")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        These are cookies that have not yet been categorized. We are in the process of\n        classifying these cookies with the help of their providers.\n        "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("How often will you update this Cookie Policy?")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        We may update this Cookie Policy from time to time in order to reflect, for example,\n        changes to the cookies we use or for other operational, legal or regulatory reasons.\n        Please therefore re-visit this Cookie Policy regularly to stay informed about our use of\n        cookies and related technologies.\n        "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        The date at the top of this Cookie Policy indicates when it was last updated.\n        "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("strong", [_vm._v("Where can I get further information?")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        If you have any questions about our use of cookies or other technologies, please email\n        us at corporate@globalspaceexdhange.com or by post to:\n        "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v("\n        800 Maine Avenue, Suite 200\n        "),
        _c("br"),
        _vm._v("\n        Washington, DC 20024\n        "),
        _c("br"),
        _vm._v("\n        Phone: 202.600.8252\n    "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }