var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.sidebarStore.close,
          expression: "sidebarStore.close",
        },
      ],
      staticClass:
        "offcanvas-container is-triggered offcanvas-container-reverse",
      class: { "in-view": _vm.sidebarStore.isVisible },
    },
    [
      _c(
        "span",
        {
          staticClass: "offcanvas-close p-3",
          on: { click: _vm.sidebarStore.close },
        },
        [_c("i", { staticClass: "fe-icon-x" })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "px-4 pb-4" }, [
        _c("h6", [_vm._v("Menu")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "py-2 text-center" },
          [_c("ProvideFeedback"), _vm._v(" "), _c("RecommendOrganization")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            !_vm.authStore.authorized
              ? _c(
                  "router-link",
                  {
                    staticClass: "btn btn-gradient px-3",
                    attrs: { to: "/signin" },
                  },
                  [
                    _c("i", { staticClass: "fe-icon-user" }),
                    _vm._v(
                      "\n                 Sign-In / Sign-Up\n            "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "offcanvas-scrollable-area border-top",
          staticStyle: { height: "calc(100% - 235px)", top: "200px" },
        },
        [
          _c(
            "div",
            {
              staticClass: "accordion mobile-menu",
              attrs: { id: "accordion-menu" },
            },
            [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card-header" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "mobile-menu-link",
                        attrs: { to: "/", exact: "" },
                      },
                      [_vm._v("Home")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.hasDashboardAccess
                ? _c("div", { staticClass: "card" }, [_vm._m(0)])
                : _vm._e(),
              _vm._v(" "),
              _vm.authStore.authorized
                ? _c(
                    "AccordionCard",
                    { attrs: { title: _vm.authStore.user.firstName } },
                    [_c("ProfileItems")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "AccordionCard",
                { attrs: { title: "Insights", to: "/insights" } },
                [
                  _c("ul", [
                    _c(
                      "li",
                      { staticClass: "dropdown-item" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/insights", exact: "" } },
                          [_vm._v("About Insights")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "dropdown-item" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/insights#explorer-tool" } },
                          [_vm._v("Explorer Tool")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "dropdown-item" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/insights#industry-analytics" } },
                          [
                            _vm._v(
                              "\n                            Industry Analytics\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "AccordionCard",
                { attrs: { title: "Services", to: "/services" } },
                [
                  _c("ul", [
                    _c(
                      "li",
                      { staticClass: "dropdown-item" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/services", exact: "" } },
                          [_vm._v("About Services")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "dropdown-item" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/services#industry-consulting" } },
                          [
                            _vm._v(
                              "\n                            Consulting Services\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "dropdown-item" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/services#inroads-services" } },
                          [
                            _vm._v(
                              "\n                            Connections Services\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "dropdown-item" },
                      [
                        _c("router-link", { attrs: { to: "/request" } }, [
                          _vm._v("Request For Services"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card-header" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "mobile-menu-link",
                        attrs: { to: "/about" },
                      },
                      [_vm._v("About Us")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "card-header" }, [
      _c(
        "a",
        {
          staticClass: "mobile-menu-link text-tertiary",
          attrs: { href: "/dashboard" },
        },
        [
          _vm._v(
            "\n                        I/O Platform\n                        "
          ),
          _c("i", { staticClass: "fe-icon-arrow-right" }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }