
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { SpotlightEditStore } from "../../../stores/admin/spotlight/SpotlightEditStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import SpotlightForm from "../../../molecules/spotlight/SpotlightForm.vue";
import { Api } from "../../../../../common/src/api/Api";
import ActionButton from "../../../../../common/src/components/ActionButton.vue";
import { SpotlightListStore } from "../../../stores/admin/spotlight/SpotlightListStore";
@Component({
    components: {
        SpotlightForm,
        ActionButton,
    },
})
export default class SpotlightGeneralView extends Vue {
    @Inject("container") readonly container!: Container;
    editStore: SpotlightEditStore = this.container.get(SpotlightEditStore);
    api: Api = this.container.get(Api);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly listStore: SpotlightListStore = this.container.get(SpotlightListStore);
    media?: { picture?: File } = {};
    created() {
        this.editStore.setId(this.spotlightId);
        this.editStore.load().catch(handleError);
    }
    get spotlightId(): string {
        return this.$route.params.spotlightId;
    }
    async updateSpotlight() {
        this.editStore.spotlightMedia.set("picture", this.media?.picture!);
        await this.editStore.update();
        if (this.editStore.hasUpdatingFailed) {
            this.notificationStore.warning("Spotlight news update failed.");
        } else {
            this.notificationStore.success("You have updated spotlight successfully.");
        }
    }

    async destroySpotlight() {
        try {
            var confirmation = confirm("Are you sure you want to delete this spotlight?");
            if (confirmation) {
                await this.api.admin.flashNews.delete(this.spotlightId);
                this.notificationStore.success("Spotlight item has been deleted.");
                this.listStore.reload();
                this.$router.push(`/admin/spotlight`);
            }
        } catch (e) {
            this.notificationStore.warning("Failure deleting spotlight item.");
        }
    }
}
