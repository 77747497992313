var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("ul", { staticClass: "nav nav-tabs nav-tabs-style-1" }, [
      _c(
        "li",
        { staticClass: "nav-item" },
        [
          _c(
            "router-link",
            { staticClass: "nav-link", attrs: { to: _vm.profileUrl } },
            [_vm._v("Profile")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAdminAccess
        ? _c(
            "li",
            { staticClass: "nav-item" },
            [
              _c(
                "router-link",
                { staticClass: "nav-link", attrs: { to: _vm.logoUrl } },
                [_vm._v("Logo")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAdminAccess
        ? _c(
            "li",
            { staticClass: "nav-item" },
            [
              _c(
                "router-link",
                { staticClass: "nav-link", attrs: { to: _vm.filesUrl } },
                [_vm._v("Attach Files")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAdminAccess
        ? _c(
            "li",
            { staticClass: "nav-item" },
            [
              _c(
                "router-link",
                { staticClass: "nav-link", attrs: { to: _vm.pastProjectsUrl } },
                [_vm._v("Past Projects")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAdminAccess
        ? _c(
            "li",
            { staticClass: "nav-item" },
            [
              _c(
                "router-link",
                { staticClass: "nav-link", attrs: { to: _vm.checkListUrl } },
                [_vm._v("Check List")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAdminAccess
        ? _c(
            "li",
            { staticClass: "nav-item" },
            [
              _c(
                "router-link",
                { staticClass: "nav-link", attrs: { to: _vm.logUrl } },
                [_vm._v("Log")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "li",
        { staticClass: "nav-item" },
        [
          _c(
            "router-link",
            { staticClass: "nav-link", attrs: { to: _vm.quiltyResourcesUrl } },
            [_vm._v("\n                Quilty Resources\n            ")]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content" }, [
      _c(
        "div",
        { staticClass: "tab-pane fade show active" },
        [
          _c("router-view", {
            attrs: {
              editStore: _vm.editStore,
              organizationMergerAcquisitionsEditStore:
                _vm.organizationMergerAcquisitionsEditStore,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }