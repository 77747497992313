var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ul", [
    _vm.hasAdminAccess
      ? _c(
          "li",
          { staticClass: "dropdown-item" },
          [
            _c(
              "router-link",
              { staticClass: "text-danger", attrs: { to: "/admin" } },
              [_vm._v("Admin")]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "li",
      { staticClass: "dropdown-item" },
      [
        _c("router-link", { attrs: { to: "/member/profile" } }, [
          _vm._v("Profile"),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("li", { staticClass: "dropdown-item" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-item clickable",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.signout.apply(null, arguments)
            },
          },
        },
        [_vm._v("Sign out")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", { staticClass: "dropdown-item" }, [
      _c("a", { attrs: { href: "/dashboard/member" } }, [_vm._v("Dashboard")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }