import { Nullable, Spotlight } from "@gsx/common";
import get from "lodash.get";
import { SpotlightBody } from "@gsx/common/src/types/http/admin/spotlight";

export const createSpotlightEmptyBody = (data?: Partial<Spotlight>): Nullable<SpotlightBody> => ({
    applicationTags: get(data, "applicationTags", null),
    operatingRegimeTags: get(data, "operatingRegimeTags", null),
    picture: get(data, "picture", null),
    title: get(data, "title", null),
    author: get(data, "author", null),
    postDate: get(data, "postDate", null),
    narrative: get(data, "narrative", null),
    highlightBullets: get(data, "highlightBullets", null),
    tags: get(data, "tags", null),
    organizationNames: get(data, "organizationNames", null),
    products: get(data, "products", null),
    services: get(data, "services", null),
    url: get(data, "url", null),
    likes: get(data, "likes", null),
});
