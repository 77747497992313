
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { SpotlightEditStore } from "../../../stores/admin/spotlight/SpotlightEditStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
@Component
export default class SpotlightEditView extends Vue {
    @Inject("container") readonly container!: Container;
    editStore: SpotlightEditStore = this.container.get(SpotlightEditStore);

    created() {
        this.editStore.setId(this.spotlightId);
        this.editStore.load().catch(handleError);
    }
    get spotlightId(): string {
        return this.$route.params.spotlightId;
    }

    get generalUrl(): string {
        return `/admin/spotlight/${this.spotlightId}/general`;
    }
    get mediaUrl(): string {
        return `/admin/spotlight/${this.spotlightId}/media`;
    }
}
