var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "ul",
      _vm._l(Object.entries(_vm.productsAndServices), function ([ps, count]) {
        return _c("li", { key: ps, staticStyle: { display: "flex" } }, [
          _c("div", { staticStyle: { "flex-grow": "1" } }, [
            _vm._v("\n                " + _vm._s(ps) + "\n            "),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n                " + _vm._s(count) + "\n            "),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }