var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-2 mb-3" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn btn-primary",
                attrs: { to: "/admin/reports/create" },
              },
              [_vm._v("Create")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-10" }, [
          _c("div", { staticClass: "control has-icons-left w-100" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.listStore.query,
                  expression: "listStore.query",
                },
              ],
              staticClass: "form-control border-primary",
              attrs: {
                type: "text",
                placeholder: "Enter a keyword",
                autocomplete: "off",
                autofocus: "",
              },
              domProps: { value: _vm.listStore.query },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.listStore, "query", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("ReportTable", {
        attrs: { collectionStore: _vm.collectionStore },
        on: { rowSelect: _vm.editReport },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }