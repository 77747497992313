var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "control has-icons-left w-100" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.userActivityLogList.query,
                  expression: "userActivityLogList.query",
                },
              ],
              staticClass: "form-control border-primary",
              attrs: {
                type: "text",
                placeholder: "Enter a keyword",
                autocomplete: "off",
                autofocus: "",
              },
              domProps: { value: _vm.userActivityLogList.query },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.userActivityLogList,
                    "query",
                    $event.target.value
                  )
                },
              },
            }),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "LoadingOverlay",
        {
          attrs: {
            loading: _vm.userActivityLogList.isLoading,
            failed: _vm.userActivityLogList.failed,
          },
          on: { retry: _vm.userActivityLogList.reload },
        },
        [
          _c(
            "div",
            {
              staticClass: "table-responsive",
              staticStyle: { "padding-bottom": "20px" },
            },
            [
              _c("table", { staticClass: "dataTable table table-hover mb-0" }, [
                _c("thead", [
                  _c(
                    "tr",
                    _vm._l(_vm.enrichedUserActivityColumns, function (column) {
                      return _c("th", {
                        key: column.name,
                        class: column.customClass,
                        domProps: { textContent: _vm._s(column.name) },
                        on: {
                          click: function ($event) {
                            return _vm.userActivityLogList.changeSort(column.id)
                          },
                        },
                      })
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.userActivityLogList.items, function (userLog) {
                      return [
                        _c("tr", { key: `${userLog.createdAt}` }, [
                          userLog.user
                            ? _c("td", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(userLog.user.firstName) +
                                    " " +
                                    _vm._s(userLog.user.lastName) +
                                    "\n                            "
                                ),
                              ])
                            : userLog.action === _vm.Action.SignInUserNotFound
                            ? _c("td", [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(userLog.value) +
                                    "\n                            "
                                ),
                              ])
                            : _c("td", [_vm._v("NO NAME")]),
                          _vm._v(" "),
                          _c("td", [
                            _c("span", [_vm._v(_vm._s(userLog.action))]),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toLocaleDateTimeString")(
                                  userLog.createdAt
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]
                    }),
                    _vm._v(" "),
                    _vm.userActivityLogList.noRecords
                      ? _c("NoRecords")
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("v-pagination", {
        attrs: {
          pageCount: _vm.userActivityLogList.pageCount,
          value: _vm.userActivityLogList.page,
        },
        on: { input: _vm.userActivityLogList.changePage },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }