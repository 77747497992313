var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("table", { staticClass: "table table-hover" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.formattedValue, function (item, index) {
          return _c("tr", { key: index }, [
            _c(
              "td",
              [
                _c("FormInput", {
                  model: {
                    value: item.year,
                    callback: function ($$v) {
                      _vm.$set(item, "year", $$v)
                    },
                    expression: "item.year",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("FormSimpleMultiSelect", {
                  staticStyle: { width: "100px" },
                  attrs: { options: _vm.yearlyFinancialType },
                  model: {
                    value: item.type,
                    callback: function ($$v) {
                      _vm.$set(item, "type", $$v)
                    },
                    expression: "item.type",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("FormInput", {
                  model: {
                    value: item.revenue,
                    callback: function ($$v) {
                      _vm.$set(item, "revenue", $$v)
                    },
                    expression: "item.revenue",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("FormInput", {
                  model: {
                    value: item.grossProfit,
                    callback: function ($$v) {
                      _vm.$set(item, "grossProfit", $$v)
                    },
                    expression: "item.grossProfit",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("FormInput", {
                  model: {
                    value: item.ebitda,
                    callback: function ($$v) {
                      _vm.$set(item, "ebitda", $$v)
                    },
                    expression: "item.ebitda",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("FormInput", {
                  model: {
                    value: item.capex,
                    callback: function ($$v) {
                      _vm.$set(item, "capex", $$v)
                    },
                    expression: "item.capex",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("FormInput", {
                  model: {
                    value: item.ebitdaMargin,
                    callback: function ($$v) {
                      _vm.$set(item, "ebitdaMargin", $$v)
                    },
                    expression: "item.ebitdaMargin",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("td", [
              _c(
                "span",
                {
                  staticClass: "clickable text-danger",
                  on: {
                    click: function ($event) {
                      return _vm.destroy(index)
                    },
                  },
                },
                [_vm._v("Delete")]
              ),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("tfoot", [
        _c("tr", [
          _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary btn-add",
                attrs: { type: "button" },
                on: { click: _vm.add },
              },
              [_c("i", { staticClass: "fas fa-plus text-primary" })]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Year")]),
        _vm._v(" "),
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Revenue")]),
        _vm._v(" "),
        _c("th", [_vm._v("Gross Profit")]),
        _vm._v(" "),
        _c("th", [_vm._v("EBITDA")]),
        _vm._v(" "),
        _c("th", [_vm._v("Capex")]),
        _vm._v(" "),
        _c("th", [_vm._v("EBITDA Margin")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }