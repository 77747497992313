var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "FormMultiSelect",
    _vm._b(
      {
        attrs: {
          options: _vm.countries,
          error: _vm.error,
          value: _vm.value,
          taggable: "",
        },
        on: { input: _vm.input },
      },
      "FormMultiSelect",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }