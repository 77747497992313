var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "signin-box" }, [
    _c(
      "form",
      {
        staticClass: "wizard",
        staticStyle: { "border-radius": "4px" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "wizard-body py-3" }, [
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v("Enter and Confirm Your Password Below"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12 input-group form-group mb-2" },
              [
                _c("i", {
                  class: `far ${
                    _vm.passwordIsVisible ? "fa-eye" : "fa-eye-slash"
                  }`,
                  style: `position: absolute; top: 50%; transform: translate(100%, -50% ); z-index: 5; cursor: pointer;`,
                  on: {
                    click: function ($event) {
                      _vm.passwordIsVisible = !_vm.passwordIsVisible
                    },
                  },
                }),
                _vm._v(" "),
                `${_vm.passwordIsVisible ? "text" : "password"}` === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value.password,
                          expression: "value.password",
                        },
                      ],
                      class: ["form-control", "pl-5"],
                      attrs: {
                        placeholder: "Password (8 or more characters)",
                        autocomplete: "current-password",
                        required: "",
                        pattern:
                          "^(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+,.?])[A-Za-z\\d!@#$%^&*()_+,.?]{8,}$",
                        title:
                          "Password must be at least 8 characters long, including 1 number, 1 capital letter, and 1 special character.",
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.value.password)
                          ? _vm._i(_vm.value.password, null) > -1
                          : _vm.value.password,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.value.password,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.value,
                                  "password",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.value,
                                  "password",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.value, "password", $$c)
                          }
                        },
                      },
                    })
                  : `${_vm.passwordIsVisible ? "text" : "password"}` === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value.password,
                          expression: "value.password",
                        },
                      ],
                      class: ["form-control", "pl-5"],
                      attrs: {
                        placeholder: "Password (8 or more characters)",
                        autocomplete: "current-password",
                        required: "",
                        pattern:
                          "^(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+,.?])[A-Za-z\\d!@#$%^&*()_+,.?]{8,}$",
                        title:
                          "Password must be at least 8 characters long, including 1 number, 1 capital letter, and 1 special character.",
                        type: "radio",
                      },
                      domProps: { checked: _vm._q(_vm.value.password, null) },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.value, "password", null)
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value.password,
                          expression: "value.password",
                        },
                      ],
                      class: ["form-control", "pl-5"],
                      attrs: {
                        placeholder: "Password (8 or more characters)",
                        autocomplete: "current-password",
                        required: "",
                        pattern:
                          "^(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+,.?])[A-Za-z\\d!@#$%^&*()_+,.?]{8,}$",
                        title:
                          "Password must be at least 8 characters long, including 1 number, 1 capital letter, and 1 special character.",
                        type: `${_vm.passwordIsVisible ? "text" : "password"}`,
                      },
                      domProps: { value: _vm.value.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.value, "password", $event.target.value)
                        },
                      },
                    }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "form-help",
                    style: `position: absolute; top: 50%; right: 0; transform: translate(-200%, -50%); z-index: 5; cursor: help;`,
                    attrs: { title: _vm.PASSWORD_HELP },
                  },
                  [_c("i", { staticClass: "fe-icon-help-circle" })]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-12 input-group form-group mb-2" },
              [
                _c("i", {
                  class: `far ${
                    _vm.confirmPasswordIsVisible ? "fa-eye" : "fa-eye-slash"
                  }`,
                  style: `position: absolute; top: 50%; transform: translate(100%, -50% ); z-index: 5; cursor: pointer;`,
                  on: {
                    click: function ($event) {
                      _vm.confirmPasswordIsVisible =
                        !_vm.confirmPasswordIsVisible
                    },
                  },
                }),
                _vm._v(" "),
                `${_vm.confirmPasswordIsVisible ? "text" : "password"}` ===
                "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value.confirmPassword,
                          expression: "value.confirmPassword",
                        },
                      ],
                      class: ["form-control", "pl-5"],
                      attrs: {
                        placeholder: "Confirm Password",
                        required: "",
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.value.confirmPassword)
                          ? _vm._i(_vm.value.confirmPassword, null) > -1
                          : _vm.value.confirmPassword,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.value.confirmPassword,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.value,
                                  "confirmPassword",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.value,
                                  "confirmPassword",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.value, "confirmPassword", $$c)
                          }
                        },
                      },
                    })
                  : `${_vm.confirmPasswordIsVisible ? "text" : "password"}` ===
                    "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value.confirmPassword,
                          expression: "value.confirmPassword",
                        },
                      ],
                      class: ["form-control", "pl-5"],
                      attrs: {
                        placeholder: "Confirm Password",
                        required: "",
                        type: "radio",
                      },
                      domProps: {
                        checked: _vm._q(_vm.value.confirmPassword, null),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.value, "confirmPassword", null)
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value.confirmPassword,
                          expression: "value.confirmPassword",
                        },
                      ],
                      class: ["form-control", "pl-5"],
                      attrs: {
                        placeholder: "Confirm Password",
                        required: "",
                        type: `${
                          _vm.confirmPasswordIsVisible ? "text" : "password"
                        }`,
                      },
                      domProps: { value: _vm.value.confirmPassword },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.value,
                            "confirmPassword",
                            $event.target.value
                          )
                        },
                      },
                    }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "font-size": "12px", "margin-top": "16px" } },
            [
              _c("p", [_vm._v("Password must:")]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "span",
                  {
                    style: `color: ${_vm.hasEightCharacters ? "green" : "red"}`,
                  },
                  [_vm._v("\n                        ✓\n                    ")]
                ),
                _vm._v(
                  "\n                    Be a minimum of 8 characters\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "span",
                  { style: `color: ${_vm.hasOneCapital ? "green" : "red"}` },
                  [_vm._v("✓")]
                ),
                _vm._v(
                  "\n                    Include at least 1 capital letter\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "span",
                  { style: `color: ${_vm.hasOneNumber ? "green" : "red"}` },
                  [_vm._v("✓")]
                ),
                _vm._v(
                  "\n                    Include at least 1 number\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "span",
                  {
                    style: `color: ${
                      _vm.hasOneSpecialCharacter ? "green" : "red"
                    }`,
                  },
                  [_vm._v("\n                        ✓\n                    ")]
                ),
                _vm._v(
                  "\n                    Include at least 1 special character\n                "
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "wizard-footer",
            staticStyle: {
              "flex-direction": "column",
              "align-items": "center",
              gap: "8px",
              border: "0",
            },
          },
          [
            _c(
              "ActionButton",
              {
                staticClass: "btn-primary signup-action-btn",
                attrs: { type: "submit", disabled: !_vm.isFormValid },
              },
              [_vm._v("\n                Login\n            ")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }