var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group row" },
    [
      _c("FormLabel", {
        staticClass: "col-5 col-form-label",
        class: _vm.labelClass,
        attrs: { label: _vm.label, help: _vm.help, required: _vm.required },
        on: { click: _vm.focus },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-7" },
        [
          _vm.focused
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.unfocus,
                      expression: "unfocus",
                    },
                  ],
                  staticClass: "form-inline",
                },
                [
                  _c(
                    "input",
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.quantity,
                            expression: "quantity",
                          },
                        ],
                        ref: "quantityElement",
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.error },
                        attrs: { type: "number" },
                        domProps: { value: _vm.quantity },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.quantity = $event.target.value
                          },
                        },
                      },
                      "input",
                      _vm.$attrs,
                      false
                    )
                  ),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currency,
                          expression: "currency",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.error },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.currency = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    _vm._l(_vm.currencies, function (currency) {
                      return _c("option", { domProps: { value: currency } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(currency) +
                            "\n                "
                        ),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _c("InputField", {
                attrs: {
                  value: _vm.displayValue,
                  error: _vm.error,
                  placeholder: _vm.placeholder,
                },
                on: { focus: _vm.focus },
              }),
          _vm._v(" "),
          _c("FormFeedback", { attrs: { error: _vm.error } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }