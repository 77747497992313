var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "LoadingOverlay",
        {
          attrs: {
            loading: _vm.listStore.isLoading,
            failed: _vm.listStore.failed,
          },
          on: { retry: _vm.listStore.reload },
        },
        [
          _vm._l(this.listStore.items, function (request) {
            return _c("Ticket", {
              key: request.requestid,
              attrs: { author: `${request.firstName} ${request.lastName}` },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "ticket-field" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("Date Submitted"),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("toLocaleDateString")(
                                  request.submissionDate
                                )
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ticket-field" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("Phone"),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(request.phone))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ticket-field" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("E-mail"),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(request.email))]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ticket-field" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("Organization"),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(request.affiliatedOrganization)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ticket-field" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("Status"),
                          ]),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "badge m-0 text-capitalize",
                            class: _vm.statusClass(request),
                            domProps: { textContent: _vm._s(request.status) },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "ticket-field" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("Actions"),
                            ]),
                            _vm._v(" "),
                            !_vm.isSubscriptionRequestType(request)
                              ? _c("MoreActions", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown-item clickable",
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeStatus(
                                            request,
                                            _vm.RequestStatus.Open
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Status: Open\n                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "dropdown-item clickable",
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeStatus(
                                            request,
                                            _vm.RequestStatus.Closed
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Status: Closed\n                        "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm.isSubscriptionRequestType(request)
                              ? _c("MoreActions", [
                                  request.status === _vm.RequestStatus.Open ||
                                  request.status === _vm.RequestStatus.Denied
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "dropdown-item clickable",
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeStatus(
                                                request,
                                                _vm.RequestStatus.Accepted
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Status: Accepted\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  request.status === _vm.RequestStatus.Accepted
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "dropdown-item clickable",
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeStatus(
                                                request,
                                                _vm.RequestStatus
                                                  .PaymentLinkSent
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Send Payment Link\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  request.status === _vm.RequestStatus.Open
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "dropdown-item clickable",
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeStatus(
                                                request,
                                                _vm.RequestStatus.Denied
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Status: Denied\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "body",
                    fn: function () {
                      return [
                        _c("h6", { staticClass: "block-title mb-0 pb-0" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm._f("capitalize")(request.title)) +
                              "\n                    "
                          ),
                          _vm.isSubscriptionRequestType(request)
                            ? _c("span", [
                                _vm._v(
                                  "\n                        | subscription request\n                    "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("small", [_vm._v(_vm._s(request.description))]),
                          _vm._v(" "),
                          request.type === _vm.RequestType.ThirdParty
                            ? _c("small", [
                                _c("hr"),
                                _vm._v(" "),
                                _c("div", [
                                  _c("b", [_vm._v("License Count:")]),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(request.licenceCount) +
                                      "\n                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("b", [_vm._v("Payment Preference:")]),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(request.paymentPreference) +
                                      "\n                        "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            })
          }),
          _vm._v(" "),
          _vm.listStore.noRecords
            ? _c("div", { staticClass: "text-center w-100 p-5 h6" }, [
                _vm._v("No records"),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("v-pagination", {
        attrs: {
          pageCount: _vm.listStore.pageCount,
          value: _vm.listStore.page,
        },
        on: { input: _vm.listStore.changePage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }