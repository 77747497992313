var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "layout" },
    [
      _c("Header"),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("Footer"),
      _vm._v(" "),
      _c("Sidebar"),
      _vm._v(" "),
      _vm.feedbackModalStore.isVisible ? _c("SubmitFeedbackModal") : _vm._e(),
      _vm._v(" "),
      _vm.individualSubscriptionRedirectModalStore.isVisible
        ? _c("IndividualSubscriptionRedirectModal")
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }