var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "ul",
      { staticClass: "nav nav-tabs nav-tabs-style-3" },
      _vm._l(_vm.tabs, function (tab, index) {
        return _c("li", { key: index, staticClass: "nav-item" }, [
          _c("a", {
            staticClass: "nav-link center clickable",
            class: { active: tab.active },
            domProps: { innerHTML: _vm._s(tab.title) },
            on: {
              click: function ($event) {
                return _vm.activateTab(index)
              },
            },
          }),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", [_vm.tabs[0].active ? _c("RequestListView") : _vm._e()], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }