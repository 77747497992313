var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "organization-edit" }, [
    _c("div", { staticClass: "progress mb-3" }, [
      _c(
        "div",
        { staticClass: "progress-bar", style: { width: `${_vm.progress}%` } },
        [
          _c("span", [
            _c("i", { staticClass: "fe-icon-globe" }),
            _vm._v(
              "\n                Organization Profile Entry - " +
                _vm._s(_vm.progress) +
                "%\n            "
            ),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("h4", [
      _vm._v("\n        " + _vm._s(_vm.values.organizationName) + "\n    "),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _vm.hasQuiltyAdminAccess
          ? _c(
              "ul",
              { staticClass: "nav nav-tabs nav-tabs-style-3" },
              _vm._l(_vm.quiltyEnrichedTabs, function (thirdPartyTabs, index) {
                return _c(
                  "li",
                  { key: thirdPartyTabs.id, staticClass: "nav-item" },
                  [
                    _c("a", {
                      staticClass: "nav-link center clickable",
                      class: [{ active: thirdPartyTabs.active }],
                      domProps: { innerHTML: _vm._s(thirdPartyTabs.title) },
                      on: {
                        click: function ($event) {
                          return _vm.activateTab(index)
                        },
                      },
                    }),
                  ]
                )
              }),
              0
            )
          : _c(
              "ul",
              { staticClass: "nav nav-tabs nav-tabs-style-3" },
              _vm._l(_vm.enrichedTabs, function (tab, index) {
                return _c("li", { key: tab.id, staticClass: "nav-item" }, [
                  _c("a", {
                    staticClass: "nav-link center clickable",
                    class: [{ active: tab.active }],
                    domProps: { innerHTML: _vm._s(tab.title) },
                    on: {
                      click: function ($event) {
                        return _vm.activateTab(index)
                      },
                    },
                  }),
                ])
              }),
              0
            ),
        _vm._v(" "),
        _c("div", { staticClass: "tab-content border" }, [
          _c(
            "div",
            { staticClass: "tab-pane fade show active" },
            [
              _vm.hasQuiltyAdminAccess
                ? _c(_vm.thirdPartyTabs[_vm.activeTab].component, {
                    tag: "component",
                    attrs: {
                      admin: _vm.admin,
                      values: _vm.values,
                      errors: _vm.errors,
                      data: _vm.thirdPartyTabs[_vm.activeTab].data,
                    },
                  })
                : _c(_vm.tabs[_vm.activeTab].component, {
                    tag: "component",
                    attrs: {
                      admin: _vm.admin,
                      values: _vm.values,
                      errors: _vm.errors,
                      data: _vm.tabs[_vm.activeTab].data,
                    },
                  }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "my-3" }),
        _vm._v(" "),
        _c(
          "ActionButton",
          { staticClass: "btn-primary", attrs: { loading: _vm.loading } },
          [_vm._v("\n            " + _vm._s(_vm.submitText) + "\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }