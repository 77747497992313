var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Structure")]),
      _vm._v(" "),
      _c(
        "InlineFormSelect",
        {
          attrs: {
            label: "Organization Type",
            error: _vm.errors.organizationType,
          },
          model: {
            value: _vm.values.organizationType,
            callback: function ($$v) {
              _vm.$set(_vm.values, "organizationType", $$v)
            },
            expression: "values.organizationType",
          },
        },
        [
          _c("option", { attrs: { value: "" } }),
          _vm._v(" "),
          _vm._l(_vm.organizationTypes, function (item) {
            return _c("option", { domProps: { value: item } }, [
              _vm._v(_vm._s(item)),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "# of people on Board of Directors",
          placeholder: "Enter a Number",
          type: "number",
          error: _vm.errors.numBOD,
        },
        model: {
          value: _vm.values.numBOD,
          callback: function ($$v) {
            _vm.$set(_vm.values, "numBOD", $$v)
          },
          expression: "values.numBOD",
        },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Names of Board Members",
          options: [],
          error: _vm.errors.nameBODmembers,
          taggable: "",
          multiple: "",
        },
        model: {
          value: _vm.values.nameBODmembers,
          callback: function ($$v) {
            _vm.$set(_vm.values, "nameBODmembers", $$v)
          },
          expression: "values.nameBODmembers",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTextarea", {
        attrs: {
          label: "Organizational Goal",
          placeholder: "Enter description here.",
          error: _vm.errors.corpGoals,
        },
        model: {
          value: _vm.values.corpGoals,
          callback: function ($$v) {
            _vm.$set(_vm.values, "corpGoals", $$v)
          },
          expression: "values.corpGoals",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Corporate Owner(s)",
          placeholder: "Enter description here.",
          error: _vm.errors.corpOwners,
        },
        model: {
          value: _vm.values.corpOwners,
          callback: function ($$v) {
            _vm.$set(_vm.values, "corpOwners", $$v)
          },
          expression: "values.corpOwners",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTextarea", {
        attrs: {
          label: "Percent foreign ownership",
          error: _vm.errors.foreignOwnership,
        },
        model: {
          value: _vm.values.foreignOwnership,
          callback: function ($$v) {
            _vm.$set(_vm.values, "foreignOwnership", $$v)
          },
          expression: "values.foreignOwnership",
        },
      }),
      _vm._v(" "),
      _c(
        "InlineFormSelect",
        {
          attrs: {
            customClass: "text-capitalize",
            label: "Ownership type",
            error: _vm.errors.ownership,
          },
          model: {
            value: _vm.values.ownership,
            callback: function ($$v) {
              _vm.$set(_vm.values, "ownership", $$v)
            },
            expression: "values.ownership",
          },
        },
        [
          _c("option", { attrs: { value: "" } }),
          _vm._v(" "),
          _vm._l(_vm.ownershipTypes, function (item) {
            return _c("option", { domProps: { value: item } }, [
              _vm._v(_vm._s(item)),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "InlineFormSelect",
        {
          attrs: {
            label: "Subsidiary of other organization(s)?",
            error: _vm.errors.subsidiary,
          },
          model: {
            value: _vm.values.subsidiary,
            callback: function ($$v) {
              _vm.$set(_vm.values, "subsidiary", $$v)
            },
            expression: "values.subsidiary",
          },
        },
        [
          _c("option", { attrs: { value: "" } }),
          _vm._v(" "),
          _vm._l(_vm.subsidiaryTypes, function (item) {
            return _c("option", { domProps: { value: item } }, [
              _vm._v(_vm._s(item)),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "InlineFormSelect",
        {
          attrs: {
            customClass: "text-capitalize",
            label: "Vertically Integrated?",
            error: _vm.errors.verticalIntegration,
          },
          model: {
            value: _vm.values.verticalIntegration,
            callback: function ($$v) {
              _vm.$set(_vm.values, "verticalIntegration", $$v)
            },
            expression: "values.verticalIntegration",
          },
        },
        [
          _c("option", { attrs: { value: "" } }),
          _vm._v(" "),
          _c("option", { attrs: { value: "yes" } }, [_vm._v("Yes")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "no" } }, [_vm._v("No")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "n/a" } }, [_vm._v("n/a")]),
        ]
      ),
      _vm._v(" "),
      _c("InlineFormTextarea", {
        attrs: {
          label: "Please list your organization subsidiaries.",
          error: _vm.errors.subsidiaryList,
        },
        model: {
          value: _vm.values.subsidiaryList,
          callback: function ($$v) {
            _vm.$set(_vm.values, "subsidiaryList", $$v)
          },
          expression: "values.subsidiaryList",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Financials")]),
      _vm._v(" "),
      _c("InlineFormCurrency", {
        attrs: {
          label: "Estimated Annual Profit",
          min: "0",
          error: _vm.errors.corpProfit,
        },
        model: {
          value: _vm.values.corpProfit,
          callback: function ($$v) {
            _vm.$set(_vm.values, "corpProfit", $$v)
          },
          expression: "values.corpProfit",
        },
      }),
      _vm._v(" "),
      _c("InlineFormCurrency", {
        attrs: {
          label: "Estimated Annual Revenue",
          min: "0",
          error: _vm.errors.corpRevenue,
        },
        model: {
          value: _vm.values.corpRevenue,
          callback: function ($$v) {
            _vm.$set(_vm.values, "corpRevenue", $$v)
          },
          expression: "values.corpRevenue",
        },
      }),
      _vm._v(" "),
      _c("InlineFormCurrency", {
        attrs: {
          label: "Total Organization Funding",
          min: "0",
          error: _vm.errors.investmentsAndFunding,
        },
        model: {
          value: _vm.values.investmentsAndFunding,
          callback: function ($$v) {
            _vm.$set(_vm.values, "investmentsAndFunding", $$v)
          },
          expression: "values.investmentsAndFunding",
        },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Initial Funding Source",
          options: _vm.initFundings,
          error: _vm.errors.initFunding,
          multiple: "",
        },
        model: {
          value: _vm.values.initFunding,
          callback: function ($$v) {
            _vm.$set(_vm.values, "initFunding", $$v)
          },
          expression: "values.initFunding",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTextarea", {
        attrs: {
          label:
            "Has your organization accepted foreign funding or investment?",
          error: _vm.errors.foreignFunding,
        },
        model: {
          value: _vm.values.foreignFunding,
          callback: function ($$v) {
            _vm.$set(_vm.values, "foreignFunding", $$v)
          },
          expression: "values.foreignFunding",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [
        _vm._v("Historical Corporate Status & Transitions"),
      ]),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Date Last Acquired or Merged",
          type: "date",
          error: _vm.errors.acquired,
        },
        model: {
          value: _vm.values.acquired,
          callback: function ($$v) {
            _vm.$set(_vm.values, "acquired", $$v)
          },
          expression: "values.acquired",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Name of Parent Organization",
          error: _vm.errors.parentOrganizationName,
        },
        model: {
          value: _vm.values.parentOrganizationName,
          callback: function ($$v) {
            _vm.$set(_vm.values, "parentOrganizationName", $$v)
          },
          expression: "values.parentOrganizationName",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }