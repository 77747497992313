var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { class: ["iziToast fadeInDown", `iziToast-${_vm.type}`] }, [
    _c(
      "div",
      { staticClass: "iziToast-body", staticStyle: { "padding-left": "33px" } },
      [
        _c("i", { staticClass: "iziToast-icon fe-icon-bell" }),
        _vm._v(" "),
        _c("strong", [_vm._v(_vm._s(_vm._f("capitalize")(_vm.type)))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.title))]),
      ]
    ),
    _vm._v(" "),
    _c("button", { staticClass: "iziToast-close", on: { click: _vm.close } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }