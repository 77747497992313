var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("General Information")]),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Legal Organization Name",
          error: _vm.errors.organizationName,
          required: "",
        },
        model: {
          value: _vm.values.organizationName,
          callback: function ($$v) {
            _vm.$set(_vm.values, "organizationName", $$v)
          },
          expression: "values.organizationName",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: '"Doing Business As" (DBA) name',
          error: _vm.errors.organizationDBA,
        },
        model: {
          value: _vm.values.organizationDBA,
          callback: function ($$v) {
            _vm.$set(_vm.values, "organizationDBA", $$v)
          },
          expression: "values.organizationDBA",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTextarea", {
        attrs: {
          label: "GSE Organization Description",
          rows: 6,
          error: _vm.errors.description,
          readonly: !_vm.admin,
        },
        model: {
          value: _vm.values.description,
          callback: function ($$v) {
            _vm.$set(_vm.values, "description", $$v)
          },
          expression: "values.description",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTextarea", {
        attrs: {
          label: "Organization Self-Description",
          rows: 6,
          error: _vm.errors.selfDescription,
        },
        model: {
          value: _vm.values.selfDescription,
          callback: function ($$v) {
            _vm.$set(_vm.values, "selfDescription", $$v)
          },
          expression: "values.selfDescription",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Web Address (URL)",
          placeholder: "https://example.com",
          type: "url",
          error: _vm.errors.webAddr,
        },
        model: {
          value: _vm.values.webAddr,
          callback: function ($$v) {
            _vm.$set(_vm.values, "webAddr", $$v)
          },
          expression: "values.webAddr",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Year Founded",
          type: "year",
          error: _vm.errors.dateYearFounded,
          min: "0",
        },
        model: {
          value: _vm.values.dateYearFounded,
          callback: function ($$v) {
            _vm.$set(_vm.values, "dateYearFounded", $$v)
          },
          expression: "values.dateYearFounded",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Month Founded",
          type: "number",
          error: _vm.errors.dateMonthFounded,
          min: "1",
          max: "12",
        },
        model: {
          value: _vm.values.dateMonthFounded,
          callback: function ($$v) {
            _vm.$set(_vm.values, "dateMonthFounded", $$v)
          },
          expression: "values.dateMonthFounded",
        },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Corporate Status",
          options: _vm.organizationStatuses,
          error: _vm.errors.status,
        },
        model: {
          value: _vm.values.status,
          callback: function ($$v) {
            _vm.$set(_vm.values, "status", $$v)
          },
          expression: "values.status",
        },
      }),
      _vm._v(" "),
      _vm.values.acquired
        ? [
            _c("InlineFormInput", {
              attrs: {
                label: "Who was the acquirer?",
                error: _vm.errors.acquirer,
              },
              model: {
                value: _vm.values.acquirer,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "acquirer", $$v)
                },
                expression: "values.acquirer",
              },
            }),
            _vm._v(" "),
            _c("InlineFormCurrency", {
              attrs: {
                label: "What was the acquisition amount?",
                min: "0",
                error: _vm.errors.acquisitionAmount,
              },
              model: {
                value: _vm.values.acquisitionAmount,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "acquisitionAmount", $$v)
                },
                expression: "values.acquisitionAmount",
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "UF Federal Commercial and Government Entity Code (CAGE Code)",
          type: "text",
          error: _vm.errors.ufFederalCage,
          min: "1",
          max: "12",
        },
        model: {
          value: _vm.values.ufFederalCage,
          callback: function ($$v) {
            _vm.$set(_vm.values, "ufFederalCage", $$v)
          },
          expression: "values.ufFederalCage",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Dun & Bradstreet D-U-N-S Number",
          type: "text",
          error: _vm.errors.duns,
          min: "1",
          max: "12",
        },
        model: {
          value: _vm.values.duns,
          callback: function ($$v) {
            _vm.$set(_vm.values, "duns", $$v)
          },
          expression: "values.duns",
        },
      }),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: {
          label: "Are you a certified Veteran owned business?",
          error: _vm.errors.veteranOwned,
        },
        model: {
          value: _vm.values.veteranOwned,
          callback: function ($$v) {
            _vm.$set(_vm.values, "veteranOwned", $$v)
          },
          expression: "values.veteranOwned",
        },
      }),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: {
          label: "Are you a certified Small Business?",
          error: _vm.errors.smallBusiness,
        },
        model: {
          value: _vm.values.smallBusiness,
          callback: function ($$v) {
            _vm.$set(_vm.values, "smallBusiness", $$v)
          },
          expression: "values.smallBusiness",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Contact Information")]),
      _vm._v(" "),
      _c("InlineFormTextarea", {
        attrs: { label: "HQ Street Address", error: _vm.errors.streetAddr },
        model: {
          value: _vm.values.streetAddr,
          callback: function ($$v) {
            _vm.$set(_vm.values, "streetAddr", $$v)
          },
          expression: "values.streetAddr",
        },
      }),
      _vm._v(" "),
      _c("InlineAddressFormGroup", {
        attrs: { values: _vm.values, errors: _vm.errors },
      }),
      _vm._v(" "),
      _c(
        "h3",
        { staticClass: "h6 mt-3", staticStyle: { "font-weight": "normal" } },
        [_vm._v("Additional Organization Addresses")]
      ),
      _vm._v(" "),
      _c("OrganizationAddressTable", {
        model: {
          value: _vm.values.addresses,
          callback: function ($$v) {
            _vm.$set(_vm.values, "addresses", $$v)
          },
          expression: "values.addresses",
        },
      }),
      _vm._v(" "),
      _c(
        "h3",
        { staticClass: "h6 mt-3", staticStyle: { "font-weight": "normal" } },
        [_vm._v("Organization Phone Numbers")]
      ),
      _vm._v(" "),
      _c("OrganizationPhoneNumberTable", {
        model: {
          value: _vm.values.phoneNumbers,
          callback: function ($$v) {
            _vm.$set(_vm.values, "phoneNumbers", $$v)
          },
          expression: "values.phoneNumbers",
        },
      }),
      _vm._v(" "),
      _c(
        "h3",
        { staticClass: "h6 mt-3", staticStyle: { "font-weight": "normal" } },
        [_vm._v("Organization Email Addresses")]
      ),
      _vm._v(" "),
      _c("OrganizationEmailTable", {
        model: {
          value: _vm.values.emails,
          callback: function ($$v) {
            _vm.$set(_vm.values, "emails", $$v)
          },
          expression: "values.emails",
        },
      }),
      _vm._v(" "),
      _c(
        "h3",
        { staticClass: "h6 mt-3", staticStyle: { "font-weight": "normal" } },
        [_vm._v("Point of Contact")]
      ),
      _vm._v(" "),
      _c("OrganizationPointOfContactTable", {
        model: {
          value: _vm.values.pointOfContact,
          callback: function ($$v) {
            _vm.$set(_vm.values, "pointOfContact", $$v)
          },
          expression: "values.pointOfContact",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Additional Information")]),
      _vm._v(" "),
      _c("InlineFormTextarea", {
        attrs: {
          label: "Additional/Affiliated (URLs)",
          error: _vm.errors.otherURLs,
        },
        model: {
          value: _vm.values.otherURLs,
          callback: function ($$v) {
            _vm.$set(_vm.values, "otherURLs", $$v)
          },
          expression: "values.otherURLs",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Organization Overview Briefing Url",
          error: _vm.errors.briefUpload,
        },
        model: {
          value: _vm.values.briefUpload,
          callback: function ($$v) {
            _vm.$set(_vm.values, "briefUpload", $$v)
          },
          expression: "values.briefUpload",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Building Address Image Link",
          error: _vm.errors.bldgImagelink,
        },
        model: {
          value: _vm.values.bldgImagelink,
          callback: function ($$v) {
            _vm.$set(_vm.values, "bldgImagelink", $$v)
          },
          expression: "values.bldgImagelink",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h5 mt-5" }, [_vm._v("Resources")]),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "What standards do you utilize?",
          placeholder:
            "(Select all that apply and/or Add other test capabilities)",
          loading: _vm.standardOptionStore.isLoading,
          options: _vm.standardOptions,
          error: _vm.errors.corpStandards,
          taggable: "",
          multiple: "",
        },
        model: {
          value: _vm.values.corpStandards,
          callback: function ($$v) {
            _vm.$set(_vm.values, "corpStandards", $$v)
          },
          expression: "values.corpStandards",
        },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "What in house testing capability do you have?",
          placeholder:
            "(Select all that apply and/or Add other test capabilities)",
          options: _vm.environmentalTestTypes,
          error: _vm.errors.environmentalTest,
          taggable: "",
          multiple: "",
        },
        model: {
          value: _vm.values.environmentalTest,
          callback: function ($$v) {
            _vm.$set(_vm.values, "environmentalTest", $$v)
          },
          expression: "values.environmentalTest",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTextarea", {
        attrs: {
          label:
            "Please list any other specialized test capabilities or test ranges that you own. (Ex: Thermal, Anechoic, Rocket Engine Testing, Etc.)",
          error: _vm.errors.testRanges,
        },
        model: {
          value: _vm.values.testRanges,
          callback: function ($$v) {
            _vm.$set(_vm.values, "testRanges", $$v)
          },
          expression: "values.testRanges",
        },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Do you have an in-house manufacturing capability?",
          options: _vm.manufactureInternalTypes,
          error: _vm.errors.manufactureInternal,
          multiple: "",
        },
        model: {
          value: _vm.values.manufactureInternal,
          callback: function ($$v) {
            _vm.$set(_vm.values, "manufactureInternal", $$v)
          },
          expression: "values.manufactureInternal",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Team Size")]),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Number of Employees",
          type: "number",
          error: _vm.errors.numEmployees,
        },
        model: {
          value: _vm.values.numEmployees,
          callback: function ($$v) {
            _vm.$set(_vm.values, "numEmployees", $$v)
          },
          expression: "values.numEmployees",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Number of Volunteers",
          type: "number",
          error: _vm.errors.numVolunteers,
        },
        model: {
          value: _vm.values.numVolunteers,
          callback: function ($$v) {
            _vm.$set(_vm.values, "numVolunteers", $$v)
          },
          expression: "values.numVolunteers",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Social Media")]),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Facebook",
          type: "url",
          error: _vm.errors.socialFacebook,
        },
        model: {
          value: _vm.values.socialFacebook,
          callback: function ($$v) {
            _vm.$set(_vm.values, "socialFacebook", $$v)
          },
          expression: "values.socialFacebook",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Flickr", type: "url", error: _vm.errors.socialFlickr },
        model: {
          value: _vm.values.socialFlickr,
          callback: function ($$v) {
            _vm.$set(_vm.values, "socialFlickr", $$v)
          },
          expression: "values.socialFlickr",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Instagram",
          type: "url",
          error: _vm.errors.socialInstagram,
        },
        model: {
          value: _vm.values.socialInstagram,
          callback: function ($$v) {
            _vm.$set(_vm.values, "socialInstagram", $$v)
          },
          expression: "values.socialInstagram",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "LinkedIn",
          type: "url",
          error: _vm.errors.socialLinkedIn,
        },
        model: {
          value: _vm.values.socialLinkedIn,
          callback: function ($$v) {
            _vm.$set(_vm.values, "socialLinkedIn", $$v)
          },
          expression: "values.socialLinkedIn",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Livestream",
          type: "url",
          error: _vm.errors.socialLivestream,
        },
        model: {
          value: _vm.values.socialLivestream,
          callback: function ($$v) {
            _vm.$set(_vm.values, "socialLivestream", $$v)
          },
          expression: "values.socialLivestream",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Twitter",
          type: "url",
          error: _vm.errors.socialTwitter,
        },
        model: {
          value: _vm.values.socialTwitter,
          callback: function ($$v) {
            _vm.$set(_vm.values, "socialTwitter", $$v)
          },
          expression: "values.socialTwitter",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "YouTube",
          type: "url",
          error: _vm.errors.socialYouTube,
        },
        model: {
          value: _vm.values.socialYouTube,
          callback: function ($$v) {
            _vm.$set(_vm.values, "socialYouTube", $$v)
          },
          expression: "values.socialYouTube",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }