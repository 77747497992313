var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-sm-12" },
      [
        _c("FormLabel", {
          staticStyle: { display: "block" },
          attrs: { label: "Quilty Resource", for: "quiltyResource" },
        }),
        _vm._v(" "),
        _vm.fileName
          ? _c("div", [
              _vm._v("\n            " + _vm._s(_vm.fileName) + "\n        "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          style:
            _vm.fileName || _vm.values.resource
              ? { visibility: "hidden" }
              : { visibility: "visible" },
          attrs: {
            id: "quiltyResource",
            type: "file",
            accept:
              ".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv,.txt, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation",
          },
          on: { change: _vm.changeQuiltyResource },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }