var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.shouldGuard
    ? _c(
        "div",
        { staticClass: "center full-height", attrs: { id: "password-guard" } },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              staticClass: "form-control",
              attrs: { type: "password", placeholder: "Password" },
              on: { keyup: _vm.checkPassword },
            }),
          ]),
        ]
      )
    : !_vm.authStore.loaded
    ? _c("div", { staticClass: "center full-height" }, [_vm._m(0)])
    : _vm.checkThirdPartyUrl
    ? _c(
        "div",
        [
          _c("LayoutThirdParty", [_c("router-view")], 1),
          _vm._v(" "),
          _c("NotificationContainer"),
        ],
        1
      )
    : _c(
        "div",
        [
          _c("Layout", [_c("router-view")], 1),
          _vm._v(" "),
          _c("NotificationContainer"),
        ],
        1
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "spinner-grow text-primary" }, [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }