var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container py-5 mb-4 text-center" }, [
    _c("h1", { staticClass: "display-404" }, [_vm._v("404")]),
    _vm._v(" "),
    _c("h2", { staticClass: "h3" }, [_vm._v("Page Not Found")]),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "text-muted" },
      [
        _vm._v(
          "\n        It seems we can’t find the page you are looking for.\n        "
        ),
        _c(
          "router-link",
          { staticClass: "font-weight-medium", attrs: { to: "/", exact: "" } },
          [_vm._v("Go Back To Homepage")]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "\n        Or try using search at the top right corner of the page.\n    "
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }