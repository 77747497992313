var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticStyle: { "flex-grow": "1", display: "flex" } }, [
    _c(
      "section",
      { staticClass: "cred-container" },
      [
        _c(
          "h5",
          {
            staticStyle: {
              color: "white",
              "font-size": "1.4rem",
              "text-align": "center",
            },
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.steps[_vm.currentStep].title) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c(_vm.steps[_vm.currentStep].component, {
          tag: "component",
          on: { submit: _vm.stepCompletion },
          model: {
            value: _vm.values,
            callback: function ($$v) {
              _vm.values = $$v
            },
            expression: "values",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }