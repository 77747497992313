var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "text-muted text-center" }, [
        _vm._v(
          "\n        Answering the following questions will help us identify valuable opportunities for your\n        organization. Afterall, we are here to help you be more successful.\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "divider w-10 my-3" }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Opportunities")]),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: { label: "Are you currently hiring?", error: _vm.errors.hiring },
        model: {
          value: _vm.values.hiring,
          callback: function ($$v) {
            _vm.$set(_vm.values, "hiring", $$v)
          },
          expression: "values.hiring",
        },
      }),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: {
          label:
            "Would you like us to notify you of industry requests for proposals relevant to your organization?",
          error: _vm.errors.newReqs,
        },
        model: {
          value: _vm.values.newReqs,
          callback: function ($$v) {
            _vm.$set(_vm.values, "newReqs", $$v)
          },
          expression: "values.newReqs",
        },
      }),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: {
          label:
            "Would you like to be notified of research and development opportunities?",
          error: _vm.errors.notifyRandD,
        },
        model: {
          value: _vm.values.notifyRandD,
          callback: function ($$v) {
            _vm.$set(_vm.values, "notifyRandD", $$v)
          },
          expression: "values.notifyRandD",
        },
      }),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: {
          label:
            "Would you like us to identify: Partners who would like to consider you for manufacture of their developments?",
          error: _vm.errors.identifyManufacture,
        },
        model: {
          value: _vm.values.identifyManufacture,
          callback: function ($$v) {
            _vm.$set(_vm.values, "identifyManufacture", $$v)
          },
          expression: "values.identifyManufacture",
        },
      }),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: {
          label:
            "Would you like us to identify: Parnters to manufacture the things you develop?",
          error: _vm.errors.identifyPartners,
        },
        model: {
          value: _vm.values.identifyPartners,
          callback: function ($$v) {
            _vm.$set(_vm.values, "identifyPartners", $$v)
          },
          expression: "values.identifyPartners",
        },
      }),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: {
          label: "Are you open to commercial partnerships?",
          error: _vm.errors.openToPartners,
        },
        model: {
          value: _vm.values.openToPartners,
          callback: function ($$v) {
            _vm.$set(_vm.values, "openToPartners", $$v)
          },
          expression: "values.openToPartners",
        },
      }),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: {
          label: "Are you currently seeking investment?",
          error: _vm.errors.seekInvest,
        },
        model: {
          value: _vm.values.seekInvest,
          callback: function ($$v) {
            _vm.$set(_vm.values, "seekInvest", $$v)
          },
          expression: "values.seekInvest",
        },
      }),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: {
          label: "Are you currently seeking sale of the organization?",
          error: _vm.errors.seekSale,
        },
        model: {
          value: _vm.values.seekSale,
          callback: function ($$v) {
            _vm.$set(_vm.values, "seekSale", $$v)
          },
          expression: "values.seekSale",
        },
      }),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: {
          label: "Are you interested in international sales opportunities.",
          error: _vm.errors.wantIntlSales,
        },
        model: {
          value: _vm.values.wantIntlSales,
          callback: function ($$v) {
            _vm.$set(_vm.values, "wantIntlSales", $$v)
          },
          expression: "values.wantIntlSales",
        },
      }),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: {
          label:
            "Are you interested in advertising solutions to help you expand your target market?",
          error: _vm.errors.advertisingInterest,
        },
        model: {
          value: _vm.values.advertisingInterest,
          callback: function ($$v) {
            _vm.$set(_vm.values, "advertisingInterest", $$v)
          },
          expression: "values.advertisingInterest",
        },
      }),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: {
          label:
            "Would you like to participate in the funding of R&D Activities?",
          error: _vm.errors.fundRandD,
        },
        model: {
          value: _vm.values.fundRandD,
          callback: function ($$v) {
            _vm.$set(_vm.values, "fundRandD", $$v)
          },
          expression: "values.fundRandD",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label:
            "Please enter any industry challenges you face or other topics you would like to see us address on behalf of the community.",
          error: _vm.errors.industryChallenges,
        },
        model: {
          value: _vm.values.industryChallenges,
          callback: function ($$v) {
            _vm.$set(_vm.values, "industryChallenges", $$v)
          },
          expression: "values.industryChallenges",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Are there any problems you would like Academia to work on?",
          error: _vm.errors.academiaProbs,
        },
        model: {
          value: _vm.values.academiaProbs,
          callback: function ($$v) {
            _vm.$set(_vm.values, "academiaProbs", $$v)
          },
          expression: "values.academiaProbs",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "What would you change about standards if you could?",
          error: _vm.errors.standardsUpdates,
        },
        model: {
          value: _vm.values.standardsUpdates,
          callback: function ($$v) {
            _vm.$set(_vm.values, "standardsUpdates", $$v)
          },
          expression: "values.standardsUpdates",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTextarea", {
        attrs: {
          label:
            "If you could make any changes to the space industry, what would they be?",
          error: _vm.errors.industryChanges,
        },
        model: {
          value: _vm.values.industryChanges,
          callback: function ($$v) {
            _vm.$set(_vm.values, "industryChanges", $$v)
          },
          expression: "values.industryChanges",
        },
      }),
      _vm._v(" "),
      _c("InlineFormBoolSelect", {
        attrs: {
          label:
            "Would you like to leverage our service to offer your capabilities to others?",
          error: _vm.errors.hiringHelp,
        },
        model: {
          value: _vm.values.hiringHelp,
          callback: function ($$v) {
            _vm.$set(_vm.values, "hiringHelp", $$v)
          },
          expression: "values.hiringHelp",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }