var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isScheduleSessionModalVisible
        ? _c("ScheduleSessionModal", {
            on: { close: _vm.hideScheduleSessionModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h1", { staticClass: "h3 bar tertiary md-0" }, [
        _vm._v("Small Satellite Conference"),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "h5 block-title text-center my-5" }, [
        _vm._v("Welcome to Global Space Exchange, Inc."),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-5 mx-3 content-grid" }, [
        _c("div", { staticClass: "grid-item-buttons sticky" }, [
          _c("div", { staticClass: "sticky" }, [
            _c("img", {
              staticClass: "my-2",
              staticStyle: { cursor: "pointer" },
              attrs: {
                src: require("../../images/schedule_profile_session.png"),
              },
              on: { click: _vm.openScheduleSessionModal },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c(
                  "ActionButton",
                  {
                    staticClass: "btn-primary",
                    staticStyle: { width: "100%" },
                    attrs: { type: "button", loading: _vm.isPurchasingBook },
                    on: { click: _vm.purchaseBook },
                  },
                  [
                    _vm._v(
                      "\n                        Buy Insight 2020\n                    "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mb-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  staticStyle: { width: "100%" },
                  attrs: { type: "button" },
                  on: { click: _vm.openSubscriptionQuoteModal },
                },
                [
                  _vm._v(
                    "\n                        Request subscription quote\n                    "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  staticStyle: { width: "100%" },
                  attrs: { type: "button" },
                  on: { click: _vm.openAdvertisingQuoteRequestModal },
                },
                [
                  _vm._v(
                    "\n                        Request advertising quote\n                    "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "grid-item-flyer" },
          [
            _c("img", {
              staticClass: "my-2",
              staticStyle: { cursor: "pointer" },
              attrs: { src: require("../../images/space_insight_2020.png") },
              on: { click: _vm.purchaseBook },
            }),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/signup" } }, [
              _c("img", {
                staticClass: "my-2",
                attrs: { src: require("../../images/services_overview.png") },
              }),
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/signup" } }, [
              _c("img", {
                staticClass: "my-2",
                attrs: {
                  src: require("../../images/services_overview_reverse.png"),
                },
              }),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(1),
      ]),
      _vm._v(" "),
      _vm.subscriptionQuoteModalStore.isVisible
        ? _c("SubscriptionQuoteModal")
        : _vm._e(),
      _vm._v(" "),
      _vm.advertisingQuoteModalStore.isVisible
        ? _c("AdvertisingQuoteModal")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "video",
      {
        staticClass: "grid-item-video my-2",
        attrs: { width: "100%", autoplay: "", controls: "" },
      },
      [
        _c("source", {
          attrs: {
            src: require("../../images/GSE_Promo_Video_rev6_1080p.mp4"),
          },
        }),
        _vm._v(
          "\n            Your browser doesn't support this video\n        "
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "grid-item-conference sticky" }, [
      _c("div", { staticClass: "sticky" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://meet.google.com/bpe-dmxe-bsw",
              title:
                "By phone, dial +1 240-360-0653 and enter this PIN: 305 899 961#",
            },
          },
          [
            _c("img", {
              staticClass: "my-2",
              attrs: {
                src: require("../../images/commercial_exhibit_room.png"),
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: "https://meet.google.com/wic-vpte-phq",
              title:
                "By phone, dial +1 240-514-8453 and enter this PIN: 206 235 379#",
            },
          },
          [
            _c("img", {
              staticClass: "my-2",
              attrs: {
                src: require("../../images/government_exhibit_room.png"),
              },
            }),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }