var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isAddModalVisible
        ? _c("AddEmailModal", {
            on: { submit: _vm.add, close: _vm.hideAddModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isEditModalVisible
        ? _c("EditEmailModal", {
            attrs: { value: _vm.formattedValue[_vm.editedIndex] },
            on: { submit: _vm.edit, close: _vm.hideEditModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("table", { staticClass: "table table-hover" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.formattedValue, function (item, index) {
            return _c("tr", { key: index }, [
              _c("td", [_vm._v(_vm._s(item.type))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(item.email))]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("MoreActions", [
                    _c(
                      "span",
                      {
                        staticClass: "dropdown-item clickable",
                        on: {
                          click: function ($event) {
                            return _vm.displayEditModal(index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                            View\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "dropdown-item clickable text-danger",
                        on: {
                          click: function ($event) {
                            return _vm.destroy(index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                            Delete\n                        "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("tfoot", [
          _c("tr", [
            _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary btn-add",
                  attrs: { type: "button" },
                  on: { click: _vm.displayAddModal },
                },
                [_c("i", { staticClass: "fas fa-plus text-primary" })]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("E-mail")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }