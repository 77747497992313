var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "signin-box" }, [
    _c(
      "form",
      {
        staticClass: "wizard",
        staticStyle: { "border-radius": "4px", width: "500px" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "wizard-body py-3" }, [
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v("Select Your Industry Segment"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "grid",
                "grid-template-columns": "auto 25% 25% auto",
                "grid-template-rows": "80px 80px 80px",
                "text-align": "center",
                "align-items": "center",
                gap: "20px",
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "signup-action-btn",
                  staticStyle: {
                    height: "100%",
                    width: "auto",
                    "grid-column": "span 2",
                  },
                  on: {
                    click: () =>
                      _vm.setIndustrySegment(
                        _vm.IndustrySegment.CommericalSpace
                      ),
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "40px" },
                    attrs: {
                      src: require("../../../../../images/signup/globe.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v("Commerical Space")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "signup-action-btn",
                  staticStyle: {
                    height: "100%",
                    width: "auto",
                    "grid-column": "span 2",
                  },
                  on: {
                    click: () =>
                      _vm.setIndustrySegment(_vm.IndustrySegment.Government),
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../../../images/signup/gov.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v("Government")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "signup-action-btn",
                  staticStyle: {
                    height: "100%",
                    width: "auto",
                    "grid-column": "span 2",
                  },
                  on: {
                    click: () =>
                      _vm.setIndustrySegment(
                        _vm.IndustrySegment.FinanceInvestment
                      ),
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "30px" },
                    attrs: {
                      src: require("../../../../../images/signup/charts.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v("Finance & Investment")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "signup-action-btn",
                  staticStyle: {
                    height: "100%",
                    width: "auto",
                    "grid-column": "span 2",
                  },
                  on: {
                    click: () =>
                      _vm.setIndustrySegment(_vm.IndustrySegment.Academia),
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../../../images/signup/hat.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v("Academia")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "signup-action-btn",
                  staticStyle: {
                    "grid-column-start": "2",
                    "grid-column-end": "span 2",
                    display: "inline-block",
                    height: "100%",
                    width: "auto",
                  },
                  on: {
                    click: () =>
                      _vm.setIndustrySegment(_vm.IndustrySegment.Other),
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../../../images/signup/plus.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v("Other")]),
                ]
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }