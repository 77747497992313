var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "mb-3 flex-right" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-secondary",
            attrs: { href: _vm.organizationUrl, target: "_blank" },
          },
          [_vm._v("\n            View Dashboard Profile\n        ")]
        ),
      ]),
      _vm._v(" "),
      _c("OrganizationProfileForm", {
        attrs: {
          loading: _vm.organizationStore.isLoading,
          values: _vm.organizationStore.data,
          errors: _vm.organizationStore.formErrors,
          admin: _vm.isUserAdmin,
        },
        on: { submit: _vm.updateOrganization },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }