var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "InlineFormMultiSelect",
    _vm._g(
      _vm._b(
        {
          attrs: { value: _vm.formattedValue, options: _vm.formattedOptions },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.$scopedSlots, function (_, slot) {
                return {
                  key: slot,
                  fn: function (scope) {
                    return [_vm._t(slot, null, null, scope)]
                  },
                }
              }),
            ],
            null,
            true
          ),
        },
        "InlineFormMultiSelect",
        _vm.$attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }