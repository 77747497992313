var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.editStore.notFound
    ? _c("NotFound")
    : _c(
        "LoadingOverlay",
        {
          attrs: {
            loading: _vm.editStore.isLoading,
            failed: _vm.editStore.hasLoadingFailed,
          },
          on: { retry: _vm.editStore.load },
        },
        [
          _c("OrganizationProfileForm", {
            attrs: {
              loading: _vm.editStore.isUpdating,
              values: _vm.editStore.data,
              mergerAcquisitions:
                _vm.organizationMergerAcquisitionsEditStore.data,
              errors: _vm.editStore.formErrors,
              admin: true,
            },
            on: { submit: _vm.updateOrganization },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }