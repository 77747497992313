var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ReportForm", {
        attrs: {
          loading: _vm.editStore.isLoading,
          values: _vm.editStore.data,
          errors: _vm.editStore.formErrors,
          admin: true,
          submitText: "Update",
        },
        on: { submit: _vm.updateReport, destroy: _vm.destroyReport },
        model: {
          value: _vm.media,
          callback: function ($$v) {
            _vm.media = $$v
          },
          expression: "media",
        },
      }),
      _vm._v(" "),
      _c(
        "ActionButton",
        {
          staticClass: "btn-secondary",
          attrs: { type: "button" },
          on: { click: _vm.sendTestEmail },
        },
        [_vm._v("\n        Send Test Email\n    ")]
      ),
      _vm._v(" "),
      _c(
        "ActionButton",
        {
          staticClass: "btn-secondary",
          attrs: { type: "button" },
          on: { click: _vm.sendEmail },
        },
        [_vm._v("\n        Send Email\n    ")]
      ),
      _vm._v(" "),
      _c(
        "ActionButton",
        {
          staticClass: "btn-danger",
          attrs: { type: "button" },
          on: { click: _vm.destroyReport },
        },
        [_vm._v("Delete")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }