var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("InvestmentsTable", {
        model: {
          value: _vm.values.investments,
          callback: function ($$v) {
            _vm.$set(_vm.values, "investments", $$v)
          },
          expression: "values.investments",
        },
      }),
      _vm._v(" "),
      _c("MergerAcquisitionTable", {
        model: {
          value: _vm.mergerAcquisitions,
          callback: function ($$v) {
            _vm.mergerAcquisitions = $$v
          },
          expression: "mergerAcquisitions",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }