var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "signin-box" }, [
    _c(
      "form",
      {
        staticClass: "wizard",
        staticStyle: { "border-radius": "4px" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "wizard-body py-3" }, [
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v("Enter Your Email Verification Code"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                margin: "64px",
                display: "flex",
                "flex-direction": "column",
                "align-items": "center",
              },
            },
            [
              _c("div", { attrs: { id: "divOuter" } }, [
                _c("div", { attrs: { id: "divInner" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value.verificationCode,
                        expression: "value.verificationCode",
                      },
                    ],
                    attrs: {
                      id: "partitioned",
                      type: "text",
                      maxlength: "6",
                      oninput:
                        "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                      onKeyPress: "if(this.value.length==6) return false;",
                    },
                    domProps: { value: _vm.value.verificationCode },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.value,
                          "verificationCode",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn",
                  staticStyle: { "font-size": "0.65rem", color: "#19799f" },
                  attrs: { type: "button" },
                  on: { click: _vm.getVerifcationCode },
                },
                [_vm._v("\n                    Re-send Code\n                ")]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "wizard-footer",
            staticStyle: {
              "flex-direction": "column",
              "align-items": "center",
              gap: "8px",
              border: "0",
            },
          },
          [
            _c(
              "ActionButton",
              {
                staticClass: "btn-primary signup-action-btn",
                attrs: { type: "submit", loading: _vm.signUpStore.isLoading },
              },
              [_vm._v("\n                Activate & Enter\n            ")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }