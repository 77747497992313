var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("p", [_vm._v("M&A:")]),
    _vm._v(" "),
    _c(
      "table",
      {
        staticClass: "table table-hover",
        staticStyle: { display: "block", "overflow-x": "auto" },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.formattedValue, function (item, index) {
            return _c("tr", { key: index }, [
              _c(
                "td",
                [
                  _c("FormDate", {
                    attrs: { required: "" },
                    model: {
                      value: item.announceDate,
                      callback: function ($$v) {
                        _vm.$set(item, "announceDate", $$v)
                      },
                      expression: "item.announceDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormMultiSelect", {
                    staticStyle: { "min-width": "300px" },
                    attrs: {
                      options: _vm.organizations,
                      value: item.targetOrganization.organizationName,
                      optionsLimit: 2,
                      taggable: "",
                      required: "",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.selectTargetOrganization($event, index)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormMultiSelect", {
                    staticStyle: { "min-width": "300px" },
                    attrs: {
                      options: _vm.organizations,
                      value: item.acquirerOrganization.organizationName,
                      optionsLimit: 2,
                      taggable: "",
                      required: "",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.selectAcquirerOrganization($event, index)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.generalTransactionType,
                      callback: function ($$v) {
                        _vm.$set(item, "generalTransactionType", $$v)
                      },
                      expression: "item.generalTransactionType",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.transactionSubtype,
                      callback: function ($$v) {
                        _vm.$set(item, "transactionSubtype", $$v)
                      },
                      expression: "item.transactionSubtype",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.status,
                      callback: function ($$v) {
                        _vm.$set(item, "status", $$v)
                      },
                      expression: "item.status",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.investmentAmount,
                      callback: function ($$v) {
                        _vm.$set(item, "investmentAmount", $$v)
                      },
                      expression: "item.investmentAmount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.transactionEnterpriseValue,
                      callback: function ($$v) {
                        _vm.$set(item, "transactionEnterpriseValue", $$v)
                      },
                      expression: "item.transactionEnterpriseValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.transactionCurrency,
                      callback: function ($$v) {
                        _vm.$set(item, "transactionCurrency", $$v)
                      },
                      expression: "item.transactionCurrency",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.targetRegion,
                      callback: function ($$v) {
                        _vm.$set(item, "targetRegion", $$v)
                      },
                      expression: "item.targetRegion",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.targetCountry,
                      callback: function ($$v) {
                        _vm.$set(item, "targetCountry", $$v)
                      },
                      expression: "item.targetCountry",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.acquirerRegion,
                      callback: function ($$v) {
                        _vm.$set(item, "acquirerRegion", $$v)
                      },
                      expression: "item.acquirerRegion",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.acquirerCountry,
                      callback: function ($$v) {
                        _vm.$set(item, "acquirerCountry", $$v)
                      },
                      expression: "item.acquirerCountry",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.targetGeneralSectorCategory,
                      callback: function ($$v) {
                        _vm.$set(item, "targetGeneralSectorCategory", $$v)
                      },
                      expression: "item.targetGeneralSectorCategory",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.investorGeneralSectorCategory,
                      callback: function ($$v) {
                        _vm.$set(item, "investorGeneralSectorCategory", $$v)
                      },
                      expression: "item.investorGeneralSectorCategory",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.targetProfile,
                      callback: function ($$v) {
                        _vm.$set(item, "targetProfile", $$v)
                      },
                      expression: "item.targetProfile",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.enterpriseValue,
                      callback: function ($$v) {
                        _vm.$set(item, "enterpriseValue", $$v)
                      },
                      expression: "item.enterpriseValue",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.trailingRev,
                      callback: function ($$v) {
                        _vm.$set(item, "trailingRev", $$v)
                      },
                      expression: "item.trailingRev",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.trailingEbitda,
                      callback: function ($$v) {
                        _vm.$set(item, "trailingEbitda", $$v)
                      },
                      expression: "item.trailingEbitda",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.notes,
                      callback: function ($$v) {
                        _vm.$set(item, "notes", $$v)
                      },
                      expression: "item.notes",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("FormInput", {
                    staticStyle: { width: "175px" },
                    model: {
                      value: item.sourceLink,
                      callback: function ($$v) {
                        _vm.$set(item, "sourceLink", $$v)
                      },
                      expression: "item.sourceLink",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("td", [
                _c(
                  "span",
                  {
                    staticClass: "clickable text-danger",
                    on: {
                      click: function ($event) {
                        return _vm.destroy(index)
                      },
                    },
                  },
                  [_vm._v("Delete")]
                ),
              ]),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("tfoot", [
          _c("tr", [
            _c("td", { staticClass: "text-center", attrs: { colspan: "10" } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary btn-add",
                  attrs: { type: "button" },
                  on: { click: _vm.add },
                },
                [_c("i", { staticClass: "fas fa-plus text-primary" })]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Announce Date")]),
        _vm._v(" "),
        _c("th", [_vm._v("Target Company")]),
        _vm._v(" "),
        _c("th", [_vm._v("Acquirer")]),
        _vm._v(" "),
        _c("th", [_vm._v("General Transaction Type")]),
        _vm._v(" "),
        _c("th", [_vm._v("Transaction Subtype")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Investment Amount")]),
        _vm._v(" "),
        _c("th", [_vm._v("Transaction Enterprise Value")]),
        _vm._v(" "),
        _c("th", [_vm._v("Transaction Currency")]),
        _vm._v(" "),
        _c("th", [_vm._v("Target Region")]),
        _vm._v(" "),
        _c("th", [_vm._v("Target Country")]),
        _vm._v(" "),
        _c("th", [_vm._v("Acquirer Region")]),
        _vm._v(" "),
        _c("th", [_vm._v("Acquirer Country")]),
        _vm._v(" "),
        _c("th", [_vm._v("Target General Sector Category")]),
        _vm._v(" "),
        _c("th", [_vm._v("Investor General Sector Category")]),
        _vm._v(" "),
        _c("th", [_vm._v("Target Profile / Description")]),
        _vm._v(" "),
        _c("th", [_vm._v("Enterprise Value / EBITDA")]),
        _vm._v(" "),
        _c("th", [_vm._v("Trailing Rev. $M")]),
        _vm._v(" "),
        _c("th", [_vm._v("Trailing EBITDA $M")]),
        _vm._v(" "),
        _c("th", [_vm._v("Notes")]),
        _vm._v(" "),
        _c("th", [_vm._v("Source Link")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }