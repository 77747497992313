var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("section", [_c("SignInBox", { staticClass: "mx-1 my-5 mx-md-5" })], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }