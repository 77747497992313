var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-sm-flex justify-content-between bg-secondary" },
      [_vm._t("header")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "blockquote comment mb-4" },
      [
        _vm._t("body"),
        _vm._v(" "),
        _vm.author
          ? _c("div", { staticClass: "testimonial-footer" }, [
              _c("div", { staticClass: "d-table-cell" }, [
                _c("div", { staticClass: "blockquote-footer" }, [
                  _vm._v(_vm._s(_vm.author)),
                ]),
              ]),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }