var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "list-group-item-wrapper" },
    [
      _vm.to
        ? _c(
            "router-link",
            {
              staticClass: "list-group-item",
              class: [_vm.customClass, { active: _vm.active }],
              attrs: { to: _vm.to },
            },
            [
              _c("div", [
                _vm.icon
                  ? _c("i", { class: [_vm.icon, "text-muted"] })
                  : _vm._e(),
                _vm._v("\n            " + _vm._s(_vm.name) + "\n        "),
              ]),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "list-group-item clickable",
              class: [_vm.customClass, { active: _vm.active }],
              on: { click: _vm.toggle },
            },
            [
              _c("div", [
                _vm.icon
                  ? _c("i", { class: [_vm.icon, "text-muted"] })
                  : _vm._e(),
                _vm._v("\n            " + _vm._s(_vm.name) + "\n        "),
              ]),
              _vm._v(" "),
              _vm.hasDefaultSlot
                ? _c(
                    "span",
                    { staticClass: "badge badge-pill badge-secondary" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.localExpanded ? "-" : "+") +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
      _vm._v(" "),
      _vm.hasDefaultSlot && _vm.localExpanded
        ? _c("div", [_vm._t("default")], 2)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }