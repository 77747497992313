var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("SpotlightForm", {
        attrs: {
          loading: _vm.createStore.isLoading,
          values: _vm.createStore.data,
          errors: _vm.createStore.formErrors,
          admin: true,
          submitText: "Create",
        },
        on: { submit: _vm.createSpotlight },
        model: {
          value: _vm.media,
          callback: function ($$v) {
            _vm.media = $$v
          },
          expression: "media",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }