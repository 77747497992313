var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "text-muted text-center" }, [
        _vm._v(
          "\n        Please enter any noteworthy past projects your organization has participated in. This\n        helps us characterize the organizational experience and heritage of technologies that\n        have been fielded.\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "divider w-25 my-4" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pb-3" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-primary",
              attrs: { to: "/member/organization/past-projects/create" },
            },
            [_vm._v("\n            Create\n        ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "LoadingOverlay",
        {
          attrs: {
            loading: _vm.listStore.isLoading,
            failed: _vm.listStore.failed,
          },
          on: { retry: _vm.listStore.reload },
        },
        [
          _c(
            "div",
            {
              staticClass: "table-responsive",
              staticStyle: { "padding-bottom": "100px" },
            },
            [
              _c("table", { staticClass: "table table-hover mb-0" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("Title")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Type")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Date")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Status")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Actions")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.listStore.items, function (item) {
                      return _c("tr", [
                        _c(
                          "td",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "navi-link",
                                attrs: {
                                  to: `/member/organization/past-projects/${item.pastprojectid}`,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(item.title) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-capitalize" }, [
                          _vm._v(_vm._s(item.projectType)),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("toDateUtcString")(item.dateConducted)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("span", {
                            staticClass:
                              "badge badge-accent m-0 text-capitalize",
                            domProps: {
                              textContent: _vm._s(_vm.getStatus(item)),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "MoreActions",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      to: `/member/organization/past-projects/${item.pastprojectid}`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    View\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "dropdown-item clickable text-danger",
                                    on: {
                                      click: function ($event) {
                                        return _vm.destroy(item.pastprojectid)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    Delete\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _vm.listStore.noRecords ? _c("NoRecords") : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }