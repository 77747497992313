var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitForm.apply(null, arguments)
        },
      },
    },
    [
      _c("p", [
        _vm._v(
          "\n        Please note, your affiliation request requires a manual review. We conduct a manual\n        verification step. We estimate that your request will be processed within 24 hours.\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("FormInput", {
                attrs: {
                  label: "Organization",
                  error: _vm.errors.organizationName,
                  required: "",
                },
                model: {
                  value: _vm.values.organizationName,
                  callback: function ($$v) {
                    _vm.$set(_vm.values, "organizationName", $$v)
                  },
                  expression: "values.organizationName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("FormEnumMultiSelect", {
                attrs: {
                  label: "Primary Job",
                  enum: _vm.UserRoleInOrganization,
                  error: _vm.errors.roleInOrganization,
                  allowEmpty: false,
                  required: "",
                },
                model: {
                  value: _vm.values.roleInOrganization,
                  callback: function ($$v) {
                    _vm.$set(_vm.values, "roleInOrganization", $$v)
                  },
                  expression: "values.roleInOrganization",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("FormCheckbox", {
            attrs: {
              label:
                "I certify that I am authorized to manage this organizations profile.",
            },
            on: { input: _vm.orgCheckboxInput },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "ActionButton",
        {
          staticClass: "btn-primary",
          attrs: {
            disabled: !_vm.orgAgreementAccepted,
            loading: _vm.requestAffiliationStore.isLoading,
          },
        },
        [_vm._v("\n        Affiliate\n    ")]
      ),
      _vm._v(" "),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }