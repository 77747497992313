var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h3", { staticClass: "h6 mt-3" }, [
        _vm._v("Key Performance Indicators"),
      ]),
      _vm._v(" "),
      _c("InlineFormCurrency", {
        attrs: { label: "Revenue (Recent, US$M)", error: _vm.errors.revenue },
        model: {
          value: _vm.kpi.revenue,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "revenue", $$v)
          },
          expression: "kpi.revenue",
        },
      }),
      _vm._v(" "),
      _c("InlineFormCurrency", {
        attrs: { label: "Net Profit", error: _vm.errors.netProfit },
        model: {
          value: _vm.kpi.netProfit,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "netProfit", $$v)
          },
          expression: "kpi.netProfit",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Net Profit Margin %",
          error: _vm.errors.netProfitMargin,
        },
        model: {
          value: _vm.kpi.netProfitMargin,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "netProfitMargin", $$v)
          },
          expression: "kpi.netProfitMargin",
        },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Size Quintile",
          error: _vm.errors.sizeQuintile,
          options: _vm.quintileSizeOptions,
        },
        model: {
          value: _vm.kpi.sizeQuintile,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "sizeQuintile", $$v)
          },
          expression: "kpi.sizeQuintile",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Employee Base", error: _vm.errors.employeeBase },
        model: {
          value: _vm.kpi.employeeBase,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "employeeBase", $$v)
          },
          expression: "kpi.employeeBase",
        },
      }),
      _vm._v(" "),
      _c("FormDate", {
        attrs: {
          label: "Last Financial Event",
          error: _vm.errors.lastFinancingEvent,
        },
        model: {
          value: _vm.kpi.lastFinancingEvent,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "lastFinancingEvent", $$v)
          },
          expression: "kpi.lastFinancingEvent",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Last Financial Event Type",
          error: _vm.errors.lastFinancingEventType,
        },
        model: {
          value: _vm.kpi.lastFinancingEventType,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "lastFinancingEventType", $$v)
          },
          expression: "kpi.lastFinancingEventType",
        },
      }),
      _vm._v(" "),
      _c("InlineFormCurrency", {
        attrs: {
          label: "Last Financial Event Size (US$M)",
          error: _vm.errors.lastFinancingEventSize,
        },
        model: {
          value: _vm.kpi.lastFinancingEventSize,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "lastFinancingEventSize", $$v)
          },
          expression: "kpi.lastFinancingEventSize",
        },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Private/Public",
          error: _vm.errors.companyStatus,
          options: _vm.publicPrivate,
        },
        model: {
          value: _vm.kpi.companyStatus,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "companyStatus", $$v)
          },
          expression: "kpi.companyStatus",
        },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Ownership",
          error: _vm.errors.ownership,
          options: _vm.ownership,
        },
        model: {
          value: _vm.kpi.ownership,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "ownership", $$v)
          },
          expression: "kpi.ownership",
        },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Primary Customer Focus",
          error: _vm.errors.primaryCustomerFocus,
          options: _vm.customerFocus,
        },
        model: {
          value: _vm.kpi.primaryCustomerFocus,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "primaryCustomerFocus", $$v)
          },
          expression: "kpi.primaryCustomerFocus",
        },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Satellite & Space Focus",
          error: _vm.errors.spaceFocus,
          options: _vm.spaceFocus,
        },
        model: {
          value: _vm.kpi.spaceFocus,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "spaceFocus", $$v)
          },
          expression: "kpi.spaceFocus",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Exchange", error: _vm.errors.exchange },
        model: {
          value: _vm.kpi.exchange,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "exchange", $$v)
          },
          expression: "kpi.exchange",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Ticker", error: _vm.errors.ticker },
        model: {
          value: _vm.kpi.ticker,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "ticker", $$v)
          },
          expression: "kpi.ticker",
        },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Currency",
          error: _vm.errors.currency,
          options: _vm.currencies,
        },
        model: {
          value: _vm.kpi.currency,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "currency", $$v)
          },
          expression: "kpi.currency",
        },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Fiscal Year Month",
          error: _vm.errors.fiscalYearMonth,
          options: _vm.months,
        },
        model: {
          value: _vm.kpi.fiscalYearMonth,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "fiscalYearMonth", $$v)
          },
          expression: "kpi.fiscalYearMonth",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Financial Data Type",
          error: _vm.errors.financialDataType,
        },
        model: {
          value: _vm.kpi.financialDataType,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "financialDataType", $$v)
          },
          expression: "kpi.financialDataType",
        },
      }),
      _vm._v(" "),
      _c("InlineFormCurrency", {
        attrs: { label: "Share Price", error: _vm.errors.sharePrice },
        model: {
          value: _vm.kpi.sharePrice,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "sharePrice", $$v)
          },
          expression: "kpi.sharePrice",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "FD Shares Outstanding",
          error: _vm.errors.fdSharesOutstanding,
        },
        model: {
          value: _vm.kpi.fdSharesOutstanding,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "fdSharesOutstanding", $$v)
          },
          expression: "kpi.fdSharesOutstanding",
        },
      }),
      _vm._v(" "),
      _c("InlineFormCurrency", {
        attrs: {
          label: "Market Capitalization",
          error: _vm.errors.marketCapitalization,
        },
        model: {
          value: _vm.kpi.marketCapitalization,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "marketCapitalization", $$v)
          },
          expression: "kpi.marketCapitalization",
        },
      }),
      _vm._v(" "),
      _c("InlineFormCurrency", {
        attrs: { label: "Debt", error: _vm.errors.debt },
        model: {
          value: _vm.kpi.debt,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "debt", $$v)
          },
          expression: "kpi.debt",
        },
      }),
      _vm._v(" "),
      _c("InlineFormCurrency", {
        attrs: { label: "Cash", error: _vm.errors.cash },
        model: {
          value: _vm.kpi.cash,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "cash", $$v)
          },
          expression: "kpi.cash",
        },
      }),
      _vm._v(" "),
      _c("InlineFormCurrency", {
        attrs: { label: "Enterprise Value", error: _vm.errors.enterpriseValue },
        model: {
          value: _vm.kpi.enterpriseValue,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "enterpriseValue", $$v)
          },
          expression: "kpi.enterpriseValue",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Valuation Notes", error: _vm.errors.valuationNotes },
        model: {
          value: _vm.kpi.valuationNotes,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "valuationNotes", $$v)
          },
          expression: "kpi.valuationNotes",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: {
          label: "Current Year Net Leverage",
          error: _vm.errors.leverage,
        },
        model: {
          value: _vm.kpi.leverage,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "leverage", $$v)
          },
          expression: "kpi.leverage",
        },
      }),
      _vm._v(" "),
      _c("YearlyFinancialTable", {
        model: {
          value: _vm.kpi.yearlyFinancialData,
          callback: function ($$v) {
            _vm.$set(_vm.kpi, "yearlyFinancialData", $$v)
          },
          expression: "kpi.yearlyFinancialData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }