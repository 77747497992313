var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group row" },
    [
      _c("FormLabel", {
        staticClass: "col-5 col-form-label",
        class: _vm.labelClass,
        attrs: { label: _vm.label, help: _vm.help, required: _vm.required },
        on: { click: _vm.focus },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-7" },
        [
          _c(
            "Multiselect",
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.focused,
                      expression: "focused",
                    },
                  ],
                  ref: "select",
                  attrs: {
                    "track-by": "id",
                    label: "name",
                    closeOnSelect: !_vm.multiple,
                    showLabels: false,
                    value: _vm.formattedValue,
                    options: _vm.formattedOptions,
                    multiple: _vm.multiple,
                    required: _vm.required,
                    placeholder: _vm.placeholder,
                    "tag-placeholder": "Add new item",
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function (_, slot) {
                        return {
                          key: slot,
                          fn: function (scope) {
                            return [_vm._t(slot, null, null, scope)]
                          },
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                },
                "Multiselect",
                _vm.$attrs,
                false
              ),
              _vm.listeners
            )
          ),
          _vm._v(" "),
          _c("InputField", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.focused,
                expression: "!focused",
              },
            ],
            attrs: {
              value: _vm.displayText,
              error: _vm.error,
              placeholder: _vm.placeholder,
            },
            on: { focus: _vm.focus },
          }),
          _vm._v(" "),
          _c("FormFeedback", { attrs: { error: _vm.error } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }