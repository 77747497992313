var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "iziToast-wrapper iziToast-wrapper-topRight" },
    _vm._l(
      _vm.notificationStore.notifications.values(),
      function (notification) {
        return _c(
          "div",
          {
            key: notification.id,
            staticClass: "iziToast-capsule",
            staticStyle: { height: "auto" },
          },
          [
            _c("Notification", {
              attrs: { title: notification.title, type: notification.type },
              on: {
                close: function ($event) {
                  return _vm.notificationStore.close(notification.id)
                },
              },
            }),
          ],
          1
        )
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }