var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "signin-box" }, [
    _c(
      "form",
      {
        staticClass: "wizard",
        staticStyle: { "border-radius": "4px" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "wizard-body py-3" }, [
          _c("div", { staticClass: "px-2 text-center" }, [
            _c("figure", { staticClass: "m-3" }, [
              _c("img", {
                attrs: {
                  src: require("../../../../../images/logo_icon.svg"),
                  alt: "satelite",
                  width: "100",
                  height: "100",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("FormInput", {
                staticClass: "mb-0 col-10",
                attrs: {
                  placeholder: "Professional Email Address",
                  type: "email",
                  required: "",
                },
                model: {
                  value: _vm.value.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "email", $$v)
                  },
                  expression: "value.email",
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn col-2 p-0",
                  staticStyle: { color: "#19799f" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.showWhyText = !_vm.showWhyText
                    },
                  },
                },
                [_vm._v("\n                    Why?\n                ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "my-2",
              style: `height: ${
                _vm.showWhyText ? "40px" : "0"
              }; transition: height 250ms; overflow: hidden; color: #19799f;`,
            },
            [
              _vm._v(
                "\n                To maintain a professional environment, we ask for a professional email.​\n            "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "wizard-footer",
            staticStyle: {
              "flex-direction": "column",
              "align-items": "center",
              gap: "8px",
              border: "0",
            },
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "ActionButton",
              {
                staticClass: "btn-primary signup-action-btn",
                attrs: { type: "submit", loading: _vm.loading },
              },
              [_vm._v("\n                Agree & Register\n            ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "font-size": "0.75rem" } },
              [
                _vm._v(
                  "\n                Already have an account?\n                "
                ),
                _c(
                  "router-link",
                  {
                    staticStyle: { "text-decoration": "none" },
                    attrs: { to: "/signin" },
                  },
                  [_vm._v("Sign in.")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticStyle: { "font-size": "0.75rem" } }, [
      _vm._v(
        "\n                By clicking Agree and Register, you agree to the Global Space Exchange, Inc.\n                "
      ),
      _c(
        "a",
        {
          staticStyle: { "text-decoration": "none", "font-weight": "bold" },
          attrs: {
            href: "https://www.globalspaceexchange.com/terms-of-service",
            target: "_blank",
          },
        },
        [
          _vm._v(
            "\n                    Site Terms & Membership License,\n                "
          ),
        ]
      ),
      _vm._v("\n                and\n                "),
      _c(
        "a",
        {
          staticStyle: { "text-decoration": "none", "font-weight": "bold" },
          attrs: {
            href: "https://www.globalspaceexchange.com/privacy-policy",
            target: "_blank",
          },
        },
        [_vm._v("\n                    Privacy Policy.\n                ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }