var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "search-wrapper control has-icons-left" }, [
    _c("input", {
      ref: "searchInput",
      staticClass: "form-control form-control-lg search-input border-primary",
      attrs: {
        type: "text",
        placeholder: "Enter a Keyword, Phrase, Part Number, etc..",
        autocomplete: "off",
        autofocus: "",
      },
      domProps: { value: _vm.value },
      on: {
        change: function ($event) {
          return _vm.$emit("change", $event.target.value)
        },
        input: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      },
    }),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "icon is-left" }, [
      _c("i", { staticClass: "fas fa-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }