var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.subscriptionPlan
        ? _c("PlanCard", {
            attrs: {
              name: _vm.subscriptionPlan.name,
              price: _vm.subscriptionPlan.price,
              interval: _vm.subscriptionPlan.interval,
              active: _vm.isSubscriptionActive,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _vm.isSubscriptionActive
                        ? _c("span", { staticClass: "badge badge-success" }, [
                            _vm._v("Active"),
                          ])
                        : _c("span", { staticClass: "badge badge-danger" }, [
                            _vm._v("Inactive"),
                          ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              177226212
            ),
          })
        : _c("div", [_vm._v("You are not subscribed to any plan.")]),
      _vm._v(" "),
      _c(
        "LoadingOverlay",
        {
          attrs: {
            loading: _vm.listStore.isLoading,
            failed: _vm.listStore.failed,
          },
          on: { retry: _vm.listStore.reload },
        },
        [
          _c("div", { staticClass: "table-responsive mt-4" }, [
            _c("table", { staticClass: "table table-hover mb-0" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("ID")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Type")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Transaction / Request Date")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Status")]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.orders, function (order) {
                    return _c("tr", [
                      _c("td", { staticClass: "navi-link" }, [
                        _vm._v(_vm._s(order.orderid)),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-capitalize" }, [
                        _vm._v(_vm._s(_vm._f("studly")(order.type))),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("toLocaleDateTimeString")(order.createdAt)
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "span",
                          {
                            staticClass: "badge m-0 text-capitalize",
                            class: _vm.badgeClass(order),
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm._f("studly")(order.status)) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  _vm.noRecords ? _c("NoRecords") : _vm._e(),
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "my-5" }, [
        _c("div", { staticClass: "mb-2" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: { click: _vm.subscribeUser },
            },
            [_vm._v("\n                Subscribe\n            ")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-2" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: { click: _vm.openAdvertisingQuoteRequestModal },
            },
            [
              _vm._v(
                "\n                Request advertising quote\n            "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.advertisingQuoteModalStore.isVisible
        ? _c("AdvertisingQuoteModal")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }