var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "footer",
    { staticClass: "credentialFooter" },
    [
      _vm._v("\n    © 2022 Global Space Exchange, Inc.\n    "),
      _c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")]),
      _vm._v(" "),
      _c(
        "a",
        { attrs: { href: "https://www.globalspaceexchange.com/about-us" } },
        [_vm._v("About")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.globalspaceexchange.com/terms-of-service",
          },
        },
        [_vm._v("Site Terms")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: { href: "https://www.globalspaceexchange.com/privacy-policy" },
        },
        [_vm._v("Privacy Policy")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: { href: "https://www.globalspaceexchange.com/privacy-policy" },
        },
        [_vm._v("Cookie Policy")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }