var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "pb-3" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-primary",
              attrs: { to: _vm.createPastProjectUrl },
            },
            [_vm._v("Create")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "LoadingOverlay",
        {
          attrs: {
            loading: _vm.listStore.isLoading,
            failed: _vm.listStore.failed,
          },
          on: { retry: _vm.listStore.reload },
        },
        [
          _c(
            "div",
            {
              staticClass: "table-responsive",
              staticStyle: { "padding-bottom": "50px" },
            },
            [
              _c("table", { staticClass: "table table-hover mb-0" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("Title")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Type")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Date")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Status")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Actions")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.listStore.items, function (pastProject) {
                      return _c("PastProjectListItem", {
                        key: pastProject.pastprojectid,
                        attrs: {
                          pastProject: pastProject,
                          organizationId: _vm.organizationId,
                        },
                        on: { destroy: _vm.listStore.reload },
                      })
                    }),
                    _vm._v(" "),
                    _vm.listStore.noRecords ? _c("NoRecords") : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("v-pagination", {
        attrs: {
          pageCount: _vm.listStore.pageCount,
          value: _vm.listStore.page,
        },
        on: { input: _vm.listStore.changePage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }