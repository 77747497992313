var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "signin-box" }, [
    _c(
      "form",
      {
        staticClass: "wizard",
        staticStyle: { "border-radius": "4px" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "wizard-body py-3" }, [
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v("Enter Your Information Below"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-6" },
              [
                _c("FormInput", {
                  attrs: { placeholder: "First Name", required: "" },
                  model: {
                    value: _vm.value.firstName,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "firstName", $$v)
                    },
                    expression: "value.firstName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-6" },
              [
                _c("FormInput", {
                  attrs: { placeholder: "Last Name", required: "" },
                  model: {
                    value: _vm.value.lastName,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "lastName", $$v)
                    },
                    expression: "value.lastName",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("FormInput", {
                  attrs: { placeholder: "Organization Name" },
                  model: {
                    value: _vm.value.organizationName,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "organizationName", $$v)
                    },
                    expression: "value.organizationName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-12" },
              [
                _c("FormEnumMultiSelect", {
                  attrs: {
                    placeholder: "Primary Role",
                    enum: _vm.UserRoleInOrganization,
                    allowEmpty: "",
                  },
                  model: {
                    value: _vm.value.roleInOrganization,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "roleInOrganization", $$v)
                    },
                    expression: "value.roleInOrganization",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "wizard-footer",
            staticStyle: {
              "flex-direction": "column",
              "align-items": "center",
              gap: "8px",
              border: "0",
            },
          },
          [
            _c(
              "ActionButton",
              {
                staticClass: "btn-primary signup-action-btn",
                attrs: { type: "submit" },
              },
              [_vm._v("\n                Submit\n            ")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }