var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "signin-box" }, [
    _c(
      "form",
      {
        staticClass: "wizard",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.signin.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "wizard-body py-3" }, [
          _c("div", { staticClass: "px-2 text-center" }, [
            _c("h1", [_vm._v("Login")]),
            _vm._v(" "),
            _c("div", { staticClass: "center" }, [
              _c(
                "span",
                { staticClass: "px-4 py-1 mb-3 font-weight-bold" },
                [
                  _vm._v(
                    "\n                        If you don't currently have an account,\n                        "
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "link",
                      staticStyle: { color: "#7dbee0" },
                      attrs: { to: "/signup" },
                    },
                    [
                      _vm._v(
                        "\n                            Click Here\n                        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              class: ["form-control", { "is-invalid": _vm.emailError }],
              attrs: {
                type: "email",
                placeholder: "Email",
                autocomplete: "email",
                required: "",
              },
              domProps: { value: _vm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.email = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "invalid-feedback" }, [
              _vm._v(_vm._s(_vm.emailError)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              class: ["form-control", { "is-invalid": _vm.passwordError }],
              attrs: {
                type: "password",
                placeholder: "Password",
                autocomplete: "current-password",
                required: "",
              },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.password = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "invalid-feedback" }, [
              _vm._v(_vm._s(_vm.passwordError)),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-left" },
            [
              _c(
                "router-link",
                {
                  staticClass: "link",
                  staticStyle: { color: "#7dbee0" },
                  attrs: { to: "/password-reset" },
                },
                [
                  _vm._v(
                    "\n                    Forgot password?\n                "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "wizard-footer" },
          [
            _c(
              "ActionButton",
              {
                staticClass: "btn-primary",
                staticStyle: { "background-color": "#035c96" },
                attrs: { type: "submit", loading: _vm.loading },
              },
              [_vm._v("\n                Sign In\n            ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "text-left", staticStyle: { padding: "15px 1rem" } },
      [
        _vm._v(
          "\n            Note: this site uses different authorization credentials than the legacy Quilty\n            research platform - please reach out to\n            "
        ),
        _c(
          "a",
          {
            staticClass: "link",
            staticStyle: { color: "#7dbee0", "text-decoration": "underline" },
            attrs: { href: "mailto:platformsupport@quiltyspace.com" },
          },
          [
            _vm._v(
              "\n                platformsupport@quiltyspace.com\n            "
            ),
          ]
        ),
        _vm._v(
          "\n            if you have not yet received your new login.\n        "
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }