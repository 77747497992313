var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-sm-12" },
      [
        _c("FormLabel", {
          staticStyle: { display: "block" },
          attrs: { label: "Report PDF", for: "reportPDF" },
        }),
        _vm._v(" "),
        _c(
          "LoadingOverlay",
          {
            attrs: {
              loading: _vm.loadingPDFBlob,
              failed: _vm.errorLoadingPDFBlob,
            },
            on: { retry: _vm.fetchPDFBlob },
          },
          [
            _vm.tempPDF || _vm.pdfBlob
              ? _c(
                  "label",
                  {
                    staticStyle: {
                      "text-align": "center",
                      cursor: "pointer",
                      display: "block",
                      height: "600px",
                    },
                    attrs: { for: "reportPDF" },
                  },
                  [
                    _c("iframe", {
                      staticStyle: { "max-width": "100%", height: "100%" },
                      attrs: { src: _vm.tempPDF || _vm.pdfBlob },
                    }),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("input", {
          style:
            _vm.tempPDF || _vm.values.pdf
              ? { visibility: "hidden" }
              : { visibility: "visible" },
          attrs: { id: "reportPDF", type: "file", accept: "application/pdf" },
          on: { change: _vm.changeReportPDF },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }