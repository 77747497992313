var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("MergerAcquisitionForm", {
        attrs: {
          loading: _vm.editStore.isLoading,
          values: _vm.editStore.data,
          errors: _vm.editStore.formErrors,
          admin: true,
          submitText: "Update",
        },
        on: { submit: _vm.updateMergerAcquisition },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }