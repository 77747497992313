var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Modal",
    {
      attrs: { title: "Schedule Session: " + _vm.modalMenuText },
      on: { close: _vm.close },
    },
    [
      _c("ScheduleSessionForm", {
        on: { affiliationChosen: _vm.affiliationChosen },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }