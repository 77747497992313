var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h3", { staticClass: "h6 mt-3" }, [
        _vm._v("Quilty Resource Information"),
      ]),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Title", error: _vm.errors.title, required: "" },
        model: {
          value: _vm.values.title,
          callback: function ($$v) {
            _vm.$set(_vm.values, "title", $$v)
          },
          expression: "values.title",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTextarea", {
        attrs: { label: "Summary", rows: 6, error: _vm.errors.summary },
        model: {
          value: _vm.values.summary,
          callback: function ($$v) {
            _vm.$set(_vm.values, "summary", $$v)
          },
          expression: "values.summary",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Highlight Bullets")]),
      _vm._v(" "),
      _c("ReportBulletPointTable", {
        model: {
          value: _vm.values.highlightBullets,
          callback: function ($$v) {
            _vm.$set(_vm.values, "highlightBullets", $$v)
          },
          expression: "values.highlightBullets",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Metadata")]),
      _vm._v(" "),
      _c("ReportMetadataTable", {
        model: {
          value: _vm.values.metadata,
          callback: function ($$v) {
            _vm.$set(_vm.values, "metadata", $$v)
          },
          expression: "values.metadata",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Tags")]),
      _vm._v(" "),
      _c("InlineFormTreeselect", {
        attrs: {
          label: "Products",
          options: _vm.productOptions,
          sortValueBy: "LEVEL",
          error: _vm.errors.products,
          multiple: "",
          flat: "",
        },
        model: {
          value: _vm.values.products,
          callback: function ($$v) {
            _vm.$set(_vm.values, "products", $$v)
          },
          expression: "values.products",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTreeselect", {
        attrs: {
          label: "Services",
          options: _vm.serviceOptions,
          sortValueBy: "LEVEL",
          error: _vm.errors.services,
          multiple: "",
          flat: "",
        },
        model: {
          value: _vm.values.services,
          callback: function ($$v) {
            _vm.$set(_vm.values, "services", $$v)
          },
          expression: "values.services",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTreeselect", {
        attrs: {
          label: "Operating Regimes",
          options: _vm.operatingRegimeOptions,
          sortValueBy: "LEVEL",
          error: _vm.errors.operatingRegimeTags,
          multiple: "",
          flat: "",
        },
        model: {
          value: _vm.values.operatingRegimeTags,
          callback: function ($$v) {
            _vm.$set(_vm.values, "operatingRegimeTags", $$v)
          },
          expression: "values.operatingRegimeTags",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTreeselect", {
        attrs: {
          label: "Applications",
          options: _vm.applicationOptions,
          sortValueBy: "LEVEL",
          error: _vm.errors.applicationTags,
          multiple: "",
          flat: "",
        },
        model: {
          value: _vm.values.applicationTags,
          callback: function ($$v) {
            _vm.$set(_vm.values, "applicationTags", $$v)
          },
          expression: "values.applicationTags",
        },
      }),
      _vm._v(" "),
      _c("InlineFormMultiSelect", {
        attrs: {
          label: "Related Organizations",
          options: _vm.organizations,
          error: _vm.errors.organizations,
          value: _vm.quiltyResourceOrganizationNames,
          optionsLimit: 20,
          multiple: "",
        },
        on: { input: _vm.selectOrganizationName },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Custom Tags",
          error: _vm.errors.tags,
          taggable: "",
          multiple: "",
        },
        model: {
          value: _vm.values.tags,
          callback: function ($$v) {
            _vm.$set(_vm.values, "tags", $$v)
          },
          expression: "values.tags",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Reach")]),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Distribution List",
          options: _vm.distroList,
          error: _vm.errors.distroList,
          multiple: "",
        },
        model: {
          value: _vm.values.distroList,
          callback: function ($$v) {
            _vm.$set(_vm.values, "distroList", $$v)
          },
          expression: "values.distroList",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Misc.")]),
      _vm._v(" "),
      _c("InlineFormDate", {
        attrs: {
          label: "Date created",
          error: _vm.errors.postDate,
          required: "",
        },
        model: {
          value: _vm.values.postDate,
          callback: function ($$v) {
            _vm.$set(_vm.values, "postDate", $$v)
          },
          expression: "values.postDate",
        },
      }),
      _vm._v(" "),
      _c("FormCheckbox", {
        attrs: {
          error: _vm.errors.isRevised,
          label: "Apply 'revision' notificaton?",
        },
        model: {
          value: _vm.values.isRevised,
          callback: function ($$v) {
            _vm.$set(_vm.values, "isRevised", $$v)
          },
          expression: "values.isRevised",
        },
      }),
      _vm._v(" "),
      _c("FormCheckbox", {
        attrs: { error: _vm.errors.isPublished, label: "Is Published?" },
        model: {
          value: _vm.values.isPublished,
          callback: function ($$v) {
            _vm.$set(_vm.values, "isPublished", $$v)
          },
          expression: "values.isPublished",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }