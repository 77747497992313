var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "center" }, [
      _c(
        "div",
        [
          _vm._m(0),
          _vm._v(" "),
          _c("RequestForm", {
            staticClass: "container my-5",
            on: { submit: _vm.onSubmit },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text-center my-5" }, [
      _c("h3", { staticClass: "h4 pb-1" }, [_vm._v("Contact Request Form")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                    Please complete this short form and a representative will contact you\n                    shortly.\n                "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }