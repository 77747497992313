var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c("h3", { staticClass: "h6 mt-3" }, [
        _vm._v("Merger / Acquisition Information"),
      ]),
      _vm._v(" "),
      _c("InlineFormDate", {
        attrs: { label: "Announce Date", required: "" },
        model: {
          value: _vm.values.announceDate,
          callback: function ($$v) {
            _vm.$set(_vm.values, "announceDate", $$v)
          },
          expression: "values.announceDate",
        },
      }),
      _vm._v(" "),
      _c("InlineFormMultiSelect", {
        attrs: {
          label: "Target Organization",
          options: _vm.organizations,
          value: _vm.values.targetOrganization.organizationName,
          optionsLimit: 20,
          taggable: "",
          required: "",
        },
        on: { input: _vm.selectTargetOrganization },
      }),
      _vm._v(" "),
      _c("InlineFormMultiSelect", {
        attrs: {
          label: "Acquirer Organization",
          options: _vm.organizations,
          value: _vm.values.acquirerOrganization.organizationName,
          optionsLimit: 20,
          taggable: "",
          required: "",
        },
        on: { input: _vm.selectAcquirerOrganization },
      }),
      _vm._v(" "),
      _c("InlineFormMultiSelect", {
        attrs: {
          label: "Investors",
          value: _vm.values.investors,
          taggable: "",
          multiple: "",
        },
        on: { input: _vm.selectInvestors },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "General Transaction Type",
          options: _vm.generalTransactionTypes,
          required: "",
        },
        model: {
          value: _vm.values.generalTransactionType,
          callback: function ($$v) {
            _vm.$set(_vm.values, "generalTransactionType", $$v)
          },
          expression: "values.generalTransactionType",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Transaction Subtype" },
        model: {
          value: _vm.values.transactionSubtype,
          callback: function ($$v) {
            _vm.$set(_vm.values, "transactionSubtype", $$v)
          },
          expression: "values.transactionSubtype",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Status" },
        model: {
          value: _vm.values.status,
          callback: function ($$v) {
            _vm.$set(_vm.values, "status", $$v)
          },
          expression: "values.status",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Investment Amount" },
        model: {
          value: _vm.values.investmentAmount,
          callback: function ($$v) {
            _vm.$set(_vm.values, "investmentAmount", $$v)
          },
          expression: "values.investmentAmount",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Transaction Enterprise Value" },
        model: {
          value: _vm.values.transactionEnterpriseValue,
          callback: function ($$v) {
            _vm.$set(_vm.values, "transactionEnterpriseValue", $$v)
          },
          expression: "values.transactionEnterpriseValue",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Transaction Currency" },
        model: {
          value: _vm.values.transactionCurrency,
          callback: function ($$v) {
            _vm.$set(_vm.values, "transactionCurrency", $$v)
          },
          expression: "values.transactionCurrency",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Target Region" },
        model: {
          value: _vm.values.targetRegion,
          callback: function ($$v) {
            _vm.$set(_vm.values, "targetRegion", $$v)
          },
          expression: "values.targetRegion",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Target Country" },
        model: {
          value: _vm.values.targetCountry,
          callback: function ($$v) {
            _vm.$set(_vm.values, "targetCountry", $$v)
          },
          expression: "values.targetCountry",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Acquier Region" },
        model: {
          value: _vm.values.acquirerRegion,
          callback: function ($$v) {
            _vm.$set(_vm.values, "acquirerRegion", $$v)
          },
          expression: "values.acquirerRegion",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Acquier Country" },
        model: {
          value: _vm.values.acquirerCountry,
          callback: function ($$v) {
            _vm.$set(_vm.values, "acquirerCountry", $$v)
          },
          expression: "values.acquirerCountry",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Target General Sector Category" },
        model: {
          value: _vm.values.targetGeneralSectorCategory,
          callback: function ($$v) {
            _vm.$set(_vm.values, "targetGeneralSectorCategory", $$v)
          },
          expression: "values.targetGeneralSectorCategory",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Investor General Sector Category" },
        model: {
          value: _vm.values.investorGeneralSectorCategory,
          callback: function ($$v) {
            _vm.$set(_vm.values, "investorGeneralSectorCategory", $$v)
          },
          expression: "values.investorGeneralSectorCategory",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Target Profile / Description" },
        model: {
          value: _vm.values.targetProfile,
          callback: function ($$v) {
            _vm.$set(_vm.values, "targetProfile", $$v)
          },
          expression: "values.targetProfile",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Enterprise Value / EBITDA" },
        model: {
          value: _vm.values.enterpriseValue,
          callback: function ($$v) {
            _vm.$set(_vm.values, "enterpriseValue", $$v)
          },
          expression: "values.enterpriseValue",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Trailing Rev. $M" },
        model: {
          value: _vm.values.trailingRev,
          callback: function ($$v) {
            _vm.$set(_vm.values, "trailingRev", $$v)
          },
          expression: "values.trailingRev",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Trailing EBITDA $M" },
        model: {
          value: _vm.values.trailingEbitda,
          callback: function ($$v) {
            _vm.$set(_vm.values, "trailingEbitda", $$v)
          },
          expression: "values.trailingEbitda",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Notes" },
        model: {
          value: _vm.values.notes,
          callback: function ($$v) {
            _vm.$set(_vm.values, "notes", $$v)
          },
          expression: "values.notes",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Source Link" },
        model: {
          value: _vm.values.sourceLink,
          callback: function ($$v) {
            _vm.$set(_vm.values, "sourceLink", $$v)
          },
          expression: "values.sourceLink",
        },
      }),
      _vm._v(" "),
      _c("hr", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "ActionButton",
        { staticClass: "btn-primary", attrs: { loading: _vm.loading } },
        [_vm._v("\n        " + _vm._s(_vm.submitText) + "\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }