import { render, staticRenderFns } from "./QuiltyResourceListView.vue?vue&type=template&id=04885f85&scoped=true&"
import script from "./QuiltyResourceListView.vue?vue&type=script&lang=ts&"
export * from "./QuiltyResourceListView.vue?vue&type=script&lang=ts&"
import style0 from "./QuiltyResourceListView.vue?vue&type=style&index=0&id=04885f85&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04885f85",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('04885f85')) {
      api.createRecord('04885f85', component.options)
    } else {
      api.reload('04885f85', component.options)
    }
    module.hot.accept("./QuiltyResourceListView.vue?vue&type=template&id=04885f85&scoped=true&", function () {
      api.rerender('04885f85', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "landing/src/views/admin/quiltyResources/QuiltyResourceListView.vue"
export default component.exports