var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("LogoCard", {
    attrs: {
      logo: _vm.authStore.user.organization.corpLogo,
      isProcessing: _vm.transaction.isProcessing,
    },
    on: {
      change: function ($event) {
        return _vm.transaction.run(_vm.updateLogo)
      },
    },
    model: {
      value: _vm.logoFile,
      callback: function ($$v) {
        _vm.logoFile = $$v
      },
      expression: "logoFile",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }