var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "mt-5 d-flex justify-content-center" },
      [
        _c("SearchInput", {
          model: {
            value: _vm.query,
            callback: function ($$v) {
              _vm.query = $$v
            },
            expression: "query",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container py-5 mb-3" },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between align-items-center pb-2 border-bottom",
          },
          [
            _c("h1", { staticClass: "h3" }, [
              _c(
                "span",
                { staticClass: "font-weight-light font-family-body text-lg" },
                [
                  _vm._v(
                    "\n                    Search results for:\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(
                "\n                " + _vm._s(_vm.query) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("i", {
              staticClass:
                "d-none d-sm-block display-4 fe-icon-search text-muted opacity-50",
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "LoadingOverlay",
          {
            attrs: {
              loading: _vm.searchStore.isLoading,
              failed: _vm.searchStore.failed,
            },
            on: { retry: _vm.searchStore.load },
          },
          [
            !_vm.isValid
              ? _c("div", { staticClass: "center my-4" }, [
                  _c("div", { staticClass: "iziToast iziToast-danger" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iziToast-body",
                        staticStyle: { "padding-left": "33px" },
                      },
                      [
                        _c("i", { staticClass: "iziToast-icon fe-icon-slash" }),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "Type at least " +
                              _vm._s(_vm.minQueryLength) +
                              " characters"
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ])
              : _vm.searchStore.results
              ? _c("div", [
                  _c("div", { staticClass: "center mt-5" }, [
                    _c(
                      "div",
                      {
                        staticClass: "widget",
                        staticStyle: { width: "500px", "max-width": "100%" },
                      },
                      [
                        _c("div", { staticClass: "widget-title" }, [
                          _vm._v("Organizations"),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          _vm.searchStore.results.organizations,
                          function (organization) {
                            return _c("ResultCard", {
                              key: organization.organizationid,
                              attrs: {
                                id: organization.organizationid,
                                title: organization.organizationName,
                                subtitle: organization.country,
                              },
                            })
                          }
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-center" }, [
                          _c("div", { staticClass: "h5" }, [
                            _c("i", { staticClass: "fe-icon-more-horizontal" }),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-muted" }, [
                            _vm._v(
                              "\n                                Full results available for paid memberships.\n                            "
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }