var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("MergerAcquisitionForm", {
    attrs: { values: _vm.createStore.data, submitText: "Create" },
    on: { submit: _vm.createMergerAcquisition },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }