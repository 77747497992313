var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Account Creation")]),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "First Name", required: "" },
        model: {
          value: _vm.registeredUserData.firstName,
          callback: function ($$v) {
            _vm.$set(_vm.registeredUserData, "firstName", $$v)
          },
          expression: "registeredUserData.firstName",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Last Name", required: "" },
        model: {
          value: _vm.registeredUserData.lastName,
          callback: function ($$v) {
            _vm.$set(_vm.registeredUserData, "lastName", $$v)
          },
          expression: "registeredUserData.lastName",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Email", required: "" },
        model: {
          value: _vm.registeredUserData.email,
          callback: function ($$v) {
            _vm.$set(_vm.registeredUserData, "email", $$v)
          },
          expression: "registeredUserData.email",
        },
      }),
      _vm._v(" "),
      _c("InlineFormMultiSelect", {
        attrs: {
          label: "Organization",
          options: _vm.organizationOptions,
          optionsLimit: 5,
          taggable: "",
        },
        on: { input: _vm.addToOrganization },
        model: {
          value: _vm.registeredUserData.organization,
          callback: function ($$v) {
            _vm.$set(_vm.registeredUserData, "organization", $$v)
          },
          expression: "registeredUserData.organization",
        },
      }),
      _vm._v(" "),
      _vm.hasAdminAccess
        ? _c("InlineFormMultiSelect", {
            attrs: {
              label: "GSE Subscription",
              options: [
                { id: "free", name: "Free" },
                { id: _vm.subscriptionNames.Enterprise, name: "Enterprise" },
              ],
            },
            model: {
              value: _vm.chosenSubscription,
              callback: function ($$v) {
                _vm.chosenSubscription = $$v
              },
              expression: "chosenSubscription",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("InlineFormMultiSelect", {
        attrs: {
          label: "QRP Subscription",
          options: [
            { id: "free", name: "Free" },
            { id: _vm.quiltySubscriptionNames.Basic, name: "Basic" },
            {
              id: _vm.quiltySubscriptionNames.Intelligence,
              name: "Intelligence",
            },
            { id: _vm.quiltySubscriptionNames.Enterprise, name: "Enterprise" },
          ],
        },
        model: {
          value: _vm.chosenQuiltySubscription,
          callback: function ($$v) {
            _vm.chosenQuiltySubscription = $$v
          },
          expression: "chosenQuiltySubscription",
        },
      }),
      _vm._v(" "),
      _c("input", {
        ref: "userProfileLogo",
        attrs: { id: "userProfileLogo", type: "file", accept: "image/*" },
        on: { change: _vm.changeUserLogo },
      }),
      _vm._v(" "),
      _c("hr", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "ActionButton",
        { staticClass: "btn-primary", attrs: { type: "submit" } },
        [_vm._v("Create")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }