var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "organization-edit" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "ul",
          {
            staticClass: "nav nav-tabs nav-tabs-style-3 nav-tabs-bordered",
            staticStyle: { "justify-content": "flex-start" },
          },
          _vm._l(_vm.enrichedTabs, function (tab, index) {
            return _c("li", { key: tab.id, staticClass: "nav-item" }, [
              _c("a", {
                staticClass: "nav-link center clickable",
                class: [{ active: tab.active }],
                domProps: { innerHTML: _vm._s(tab.title) },
                on: {
                  click: function ($event) {
                    return _vm.activateTab(index)
                  },
                },
              }),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "tab-content border" }, [
          _c(
            "div",
            { staticClass: "tab-pane fade show active" },
            [
              _c(_vm.tabs[_vm.activeTab].component, {
                tag: "component",
                attrs: {
                  admin: _vm.admin,
                  values: _vm.values,
                  errors: _vm.errors,
                },
                model: {
                  value: _vm.media,
                  callback: function ($$v) {
                    _vm.media = $$v
                  },
                  expression: "media",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "my-3" }),
        _vm._v(" "),
        _c(
          "ActionButton",
          { staticClass: "btn-primary", attrs: { loading: _vm.loading } },
          [_vm._v("\n            " + _vm._s(_vm.submitText) + "\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }