var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("footer", { staticClass: "mt-5" }, [
    _c("hr", { staticClass: "bar accent" }),
    _vm._v(" "),
    _c("div", { staticClass: "container pt-2" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-3 pb-4 mb-2" }, [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "router-link",
                {
                  staticClass: "d-inline-block mx-2 mt-2 mb-4",
                  attrs: { to: "/", exact: "" },
                },
                [
                  _c("img", {
                    staticClass: "footer-logo",
                    attrs: {
                      src: require("../../../../common/images/logo.svg"),
                      alt: "GSX",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "mb-4" }, [
            _vm._v(
              "\n                    The Space Industry’s go-to resource for rapid access to industry knowledge,\n                    insights, products and services.\n                "
            ),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-9 col-links" }, [
          _c("div", { staticClass: "p-lg-5" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "widget widget-categories" }, [
                  _c("ul", [
                    _vm._m(3),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: "/privacy" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.scrollToTop.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                            Privacy Policy\n                                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "widget widget-categories" }, [
                  _c("ul", [
                    _vm._m(4),
                    _vm._v(" "),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: { to: "/terms" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.scrollToTop.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                            Terms & Conditions\n                                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "bar accent" }, [
      _vm._v(
        "\n        Copyright © " +
          _vm._s(new Date().getFullYear()) +
          ". All rights reserved. Global Space Exchange,\n        Inc.\n    "
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", { staticClass: "list-icon pb-2" }, [
      _c("li", [
        _c("i", { staticClass: "fe-icon-map-pin opacity-60" }),
        _vm._v(" "),
        _c("strong", [
          _vm._v(
            "\n                             800 Maine Ave. SW #200,\n                            "
          ),
          _c("br"),
          _vm._v(
            "\n                             Washington, DC 20024, USA\n                        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fe-icon-phone opacity-60" }),
        _vm._v(" "),
        _c(
          "a",
          { staticClass: "navi-link", attrs: { href: "tel:+12026008252" } },
          [_vm._v(" +1 202 600 8252")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("i", { staticClass: "fe-icon-mail opacity-60" }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "navi-link",
            attrs: { href: "mailto:support@globalspaceexchange.com" },
          },
          [
            _vm._v(
              "\n                             support@globalspaceexchange.com\n                        "
            ),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "d-none" }, [
      _c("a", { staticClass: "social-btn sb-style-6 sb-facebook" }, [
        _c("i", { staticClass: "socicon-facebook" }),
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "social-btn sb-style-6 sb-twitter" }, [
        _c("i", { staticClass: "socicon-twitter" }),
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "social-btn sb-style-6 sb-youtube" }, [
        _c("i", { staticClass: "socicon-youtube" }),
      ]),
      _vm._v(" "),
      _c("a", { staticClass: "social-btn sb-style-6 sb-instagram" }, [
        _c("i", { staticClass: "socicon-instagram" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "widget mb-0" }, [
      _c("h4", { staticClass: "widget-title" }, [_vm._v("Useful Links")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [
      _c("a", { attrs: { href: "https://globalspaceexchange.com" } }, [
        _vm._v("Home"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [
      _c("a", { attrs: { href: "https://globalspaceexchange.com/about-us" } }, [
        _vm._v(
          "\n                                            About Us\n                                        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }