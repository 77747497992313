var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "signin-box" }, [
    _c(
      "form",
      {
        staticClass: "wizard",
        staticStyle: { "border-radius": "4px" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.signin.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "wizard-body py-3" },
          [
            _c("div", { staticClass: "px-2 text-center" }, [
              _c("figure", { staticClass: "m-3" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../images/logo_icon.svg"),
                    alt: "satelite",
                    width: "100",
                    height: "100",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                class: ["form-control", { "is-invalid": _vm.emailError }],
                attrs: {
                  type: "email",
                  placeholder: "Email",
                  autocomplete: "email",
                  required: "",
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.emailError)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group form-group mb-2" }, [
              `${_vm.passwordIsVisible ? "text" : "password"}` === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    class: [
                      "form-control",
                      { "is-invalid": _vm.passwordError },
                      "pl-5",
                    ],
                    attrs: {
                      placeholder: "Password (8 or more characters)",
                      autocomplete: "current-password",
                      required: "",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.password)
                        ? _vm._i(_vm.password, null) > -1
                        : _vm.password,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.password,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.password = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.password = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.password = $$c
                        }
                      },
                    },
                  })
                : `${_vm.passwordIsVisible ? "text" : "password"}` === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    class: [
                      "form-control",
                      { "is-invalid": _vm.passwordError },
                      "pl-5",
                    ],
                    attrs: {
                      placeholder: "Password (8 or more characters)",
                      autocomplete: "current-password",
                      required: "",
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.password, null) },
                    on: {
                      change: function ($event) {
                        _vm.password = null
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    class: [
                      "form-control",
                      { "is-invalid": _vm.passwordError },
                      "pl-5",
                    ],
                    attrs: {
                      placeholder: "Password (8 or more characters)",
                      autocomplete: "current-password",
                      required: "",
                      type: `${_vm.passwordIsVisible ? "text" : "password"}`,
                    },
                    domProps: { value: _vm.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.password = $event.target.value
                      },
                    },
                  }),
              _vm._v(" "),
              _c("i", {
                class: `far ${
                  _vm.passwordIsVisible ? "fa-eye" : "fa-eye-slash"
                }`,
                style: `position: absolute; top: 50%; transform: translate(100%, ${
                  _vm.passwordError ? "-100%" : "-50%"
                } ); z-index: 5; cursor: pointer;`,
                on: {
                  click: function ($event) {
                    _vm.passwordIsVisible = !_vm.passwordIsVisible
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "form-help",
                  style: `position: absolute; top: 50%; right: 0; transform: translate(-50%, ${
                    _vm.passwordError ? "-100%" : "-50%"
                  } ); z-index: 5; cursor: help;`,
                  attrs: { title: _vm.PASSWORD_HELP },
                },
                [_c("i", { staticClass: "fe-icon-help-circle" })]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.passwordError)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "navi-link",
                staticStyle: { "font-size": "0.75rem", color: "#19799f" },
                attrs: { to: "/password-reset" },
              },
              [_vm._v("\n                Reset Password.\n            ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "wizard-footer",
            staticStyle: {
              "flex-direction": "column",
              "align-items": "center",
              gap: "8px",
              border: "0",
            },
          },
          [
            _c(
              "ActionButton",
              {
                staticClass: "btn-primary",
                staticStyle: {
                  border: "1px solid #19799f",
                  "border-radius": "4px",
                  "background-color": "white",
                  color: "#19799f",
                  width: "200px",
                },
                attrs: { type: "submit", loading: _vm.loading },
              },
              [_vm._v("\n                Sign In\n            ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "font-size": "0.75rem" } },
              [
                _vm._v("\n                No Account?\n                "),
                _c(
                  "router-link",
                  {
                    staticStyle: { "text-decoration": "none" },
                    attrs: { to: "/signup" },
                  },
                  [
                    _vm._v(
                      "\n                    Register here.\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }