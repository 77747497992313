var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.updatePassword.apply(null, arguments)
        },
      },
    },
    [
      _c("h2", { staticClass: "h5 text-center text-sm-left" }, [
        _vm._v("Change password"),
      ]),
      _vm._v(" "),
      _vm.updatedSuccessfully
        ? _c(
            "div",
            {
              staticClass:
                "iziToast fadeInDown iziToast-success w-100 mt-2 mb-4",
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-5" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("FormInput", {
              attrs: {
                label: "Old password",
                type: "password",
                error: _vm.errors.oldPassword,
                required: "",
              },
              model: {
                value: _vm.values.oldPassword,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "oldPassword", $$v)
                },
                expression: "values.oldPassword",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("FormInput", {
              attrs: {
                label: "New password",
                type: "password",
                help: _vm.PASSWORD_HELP,
                error: _vm.errors.newPassword,
                required: "",
              },
              model: {
                value: _vm.values.newPassword,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "newPassword", $$v)
                },
                expression: "values.newPassword",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("FormInput", {
              attrs: {
                label: "Confirm new password",
                type: "password",
                error: _vm.errors.confirmNewPassword,
                required: "",
              },
              model: {
                value: _vm.values.confirmNewPassword,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "confirmNewPassword", $$v)
                },
                expression: "values.confirmNewPassword",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c(
              "ActionButton",
              {
                staticClass: "btn-primary",
                attrs: {
                  type: "submit",
                  loading: _vm.changePasswordStore.isLoading,
                },
              },
              [_vm._v("\n                Update password\n            ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "iziToast-body", staticStyle: { "padding-left": "33px" } },
      [
        _c("i", { staticClass: "iziToast-icon fe-icon-bell" }),
        _vm._v(" "),
        _c("p", [_vm._v("Password has been successfully updated.")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }