var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.saveProfile.apply(null, arguments)
        },
      },
    },
    [
      _c("h2", { staticClass: "h5 text-center text-sm-left" }, [
        _vm._v("Profile"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row mb-5" },
        [
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("FormInput", {
                attrs: {
                  label: "First Name",
                  error: _vm.errors.firstName,
                  required: "",
                },
                model: {
                  value: _vm.values.firstName,
                  callback: function ($$v) {
                    _vm.$set(_vm.values, "firstName", $$v)
                  },
                  expression: "values.firstName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("FormInput", {
                attrs: {
                  label: "Last Name",
                  error: _vm.errors.lastName,
                  required: "",
                },
                model: {
                  value: _vm.values.lastName,
                  callback: function ($$v) {
                    _vm.$set(_vm.values, "lastName", $$v)
                  },
                  expression: "values.lastName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("AddressFormGroup", {
            attrs: { errors: _vm.errors, values: _vm.values },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("FormInput", {
                attrs: { label: "Primary Occupation/Profession", required: "" },
                model: {
                  value: _vm.values.roleInOrganization,
                  callback: function ($$v) {
                    _vm.$set(_vm.values, "roleInOrganization", $$v)
                  },
                  expression: "values.roleInOrganization",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("FormTextarea", {
                attrs: {
                  label: "Professional Expertise",
                  error: _vm.errors.expertise,
                  rows: "5",
                },
                model: {
                  value: _vm.values.expertise,
                  callback: function ($$v) {
                    _vm.$set(_vm.values, "expertise", $$v)
                  },
                  expression: "values.expertise",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("FormTextarea", {
                attrs: {
                  label: "Professional Interests",
                  error: _vm.errors.professionalInterests,
                  rows: "5",
                },
                model: {
                  value: _vm.values.professionalInterests,
                  callback: function ($$v) {
                    _vm.$set(_vm.values, "professionalInterests", $$v)
                  },
                  expression: "values.professionalInterests",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("h2", { staticClass: "h5 text-center text-sm-left" }, [
        _vm._v("Contact"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-5" }, [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("FormInput", {
              attrs: {
                label: "E-mail Address",
                type: "email",
                value: _vm.authStore.user.email,
                readonly: "",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c("FormTel", {
              attrs: {
                label: "Phone Number",
                type: "tel",
                error: _vm.errors.phone,
                required: "",
              },
              model: {
                value: _vm.values.phone,
                callback: function ($$v) {
                  _vm.$set(_vm.values, "phone", $$v)
                },
                expression: "values.phone",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12 my-2" },
          [
            _c("FormCheckbox", {
              attrs: {
                label: "Opt out of receiving newsletter and marketing emails",
                value: !_vm.values.newsletterEmail,
              },
              on: {
                input: function ($event) {
                  _vm.values.newsletterEmail = !$event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "h2",
        {
          staticClass: "h5 text-center text-sm-left",
          attrs: { id: "affiliation" },
        },
        [_vm._v("Affiliation")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-5" }, [
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("FormInput", {
              attrs: {
                label: "Organization",
                value: _vm.organization,
                readonly: "",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c(
              "FormSelect",
              {
                attrs: {
                  customClass: "text-capitalize",
                  label: "Organization Type",
                  error: _vm.errors.affiliation,
                  required: "",
                },
                model: {
                  value: _vm.values.affiliation,
                  callback: function ($$v) {
                    _vm.$set(_vm.values, "affiliation", $$v)
                  },
                  expression: "values.affiliation",
                },
              },
              _vm._l(_vm.affiliations, function (item) {
                return _c("option", { domProps: { value: item } }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(item) +
                      "\n                "
                  ),
                ])
              }),
              0
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "ActionButton",
            {
              staticClass: "btn-primary",
              attrs: { type: "submit", loading: _vm.profileStore.isLoading },
            },
            [_vm._v("\n            Update Profile\n        ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }