var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "btn-group dropleft more-actions" }, [
    _c("span", { staticClass: "clickable", on: { click: _vm.show } }, [
      _c("i", { staticClass: "fe-icon-more-horizontal" }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "dropdown-menu",
        class: { show: _vm.isVisible },
        on: { click: _vm.hide },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }