var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticStyle: { "flex-grow": "1", display: "flex" } }, [
    _c(
      "section",
      { staticClass: "cred-container" },
      [
        _c("h5", { staticClass: "signInText" }, [
          _vm._v("Maximize Your Impact in Space"),
        ]),
        _vm._v(" "),
        _c("SignInBox", { staticClass: "mx-1 mb-5 mx-md-5" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }