var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "tab-content" }, [
      _c(
        "div",
        { staticClass: "tab-pane fade show active" },
        [_c("router-view", { attrs: { editStore: _vm.editStore } })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }