var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("Breadcrumbs", {
        attrs: { title: _vm.title, breadcrumbs: _vm.breadcrumbs },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container mb-4 member-view" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-4 pb-5" },
            [
              _c(
                "div",
                { staticClass: "author-card pb-3" },
                [
                  _vm.isOrganizationTabActive && _vm.isAffiliated
                    ? _c("OrganizationLogoEditCard")
                    : _c("div", { staticClass: "author-card-cover default" }),
                  _vm._v(" "),
                  _c("AuthorCardProfile"),
                ],
                1
              ),
              _vm._v(" "),
              _c("TierProgressBar"),
              _vm._v(" "),
              _c("div", { staticClass: "wizard" }, [
                _c(
                  "nav",
                  { staticClass: "list-group list-group-flush" },
                  [
                    _c("ListGroupItem", {
                      attrs: {
                        name: "Personal Profile",
                        icon: "fe-icon-user",
                        to: "/member/profile",
                      },
                    }),
                    _vm._v(" "),
                    _c("ListGroupItem", {
                      attrs: {
                        name: "Subscriptions/Downloads",
                        icon: "fe-icon-credit-card",
                        to: "/member/subscription",
                      },
                    }),
                    _vm._v(" "),
                    _c("ListGroupItem", {
                      attrs: {
                        name: "My Requests",
                        icon: "fe-icon-tag",
                        to: "/member/requests",
                      },
                    }),
                    _vm._v(" "),
                    _c("ListGroupItem", {
                      attrs: {
                        name: "Security",
                        icon: "fas fa-shield-alt",
                        to: "/member/security",
                      },
                    }),
                    _vm._v(" "),
                    _vm.authStore.user.organization
                      ? _c(
                          "ListGroupItem",
                          {
                            attrs: {
                              name: _vm.authStore.user.organization
                                .organizationName,
                              icon: "far fa-building",
                              expanded: _vm.isOrganizationTabActive,
                            },
                          },
                          [
                            _c("ListGroupItem", {
                              attrs: {
                                name: "Organization Profile",
                                to: "/member/organization/profile",
                                customClass: "subitem",
                              },
                            }),
                            _vm._v(" "),
                            _c("ListGroupItem", {
                              attrs: {
                                name: "Past Projects",
                                to: "/member/organization/past-projects",
                                customClass: "subitem",
                              },
                            }),
                          ],
                          1
                        )
                      : _c("ListGroupItem", {
                          attrs: {
                            name: "Apply For Affiliation",
                            icon: "fas fa-plus",
                            to: "/member/request-affiliation",
                          },
                        }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-8 pb-5" }, [_c("router-view")], 1),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }