var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "card" }, [
    _c(
      "div",
      { staticClass: "card-header" },
      [
        _vm.to
          ? _c(
              "router-link",
              { staticClass: "mobile-menu-link", attrs: { to: _vm.to } },
              [_vm._v(_vm._s(_vm.title))]
            )
          : _c("span", { staticClass: "mobile-menu-link" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
        _vm._v(" "),
        _c("span", {
          class: { collapsed: !_vm.active },
          attrs: { "data-toggle": "collapse" },
          on: { click: _vm.toggle },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "collapse", class: { show: _vm.active } }, [
      _c("div", { staticClass: "card-body" }, [_vm._t("default")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }