
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { SpotlightCreateStore } from "../../../stores/admin/spotlight/SpotlightCreateStore";
import { NotificationStore } from "../../../../../common/src/stores/NotificationStore";
import { SpotlightListStore } from "../../../stores/admin/spotlight/SpotlightListStore";
import { handleError } from "../../../../../common/src/utility/errorUtils";
import SpotlightForm from "../../../molecules/spotlight/SpotlightForm.vue";
@Component({
    components: {
        SpotlightForm,
    },
})
export default class SpotlightCreateView extends Vue {
    @Inject("container") readonly container!: Container;
    readonly createStore: SpotlightCreateStore = this.container.get(SpotlightCreateStore);
    readonly listStore: SpotlightListStore = this.container.get(SpotlightListStore);
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);

    media?: { picture?: File } = {};
    async createSpotlight() {
        this.createStore.spotlightMedia.set("picture", this.media?.picture!);
        await this.createStore.submit();
        if (this.createStore.failed) {
            this.notificationStore.warning("Could not create an spotlight.");
        } else {
            this.notificationStore.success("You have created spotlight successfully.");
            this.listStore.reload().catch(handleError);
            this.$router.push("/admin/spotlight");
        }
    }
}
