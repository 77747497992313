import { render, staticRenderFns } from "./FeedbackListView.vue?vue&type=template&id=35d76d90&scoped=true&"
import script from "./FeedbackListView.vue?vue&type=script&lang=ts&"
export * from "./FeedbackListView.vue?vue&type=script&lang=ts&"
import style0 from "./FeedbackListView.vue?vue&type=style&index=0&id=35d76d90&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35d76d90",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('35d76d90')) {
      api.createRecord('35d76d90', component.options)
    } else {
      api.reload('35d76d90', component.options)
    }
    module.hot.accept("./FeedbackListView.vue?vue&type=template&id=35d76d90&scoped=true&", function () {
      api.rerender('35d76d90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "landing/src/views/admin/feedback/FeedbackListView.vue"
export default component.exports