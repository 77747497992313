var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-sm-12" },
      [
        _c("FormLabel", {
          staticStyle: { display: "block" },
          attrs: { label: "FlashNews Picture", for: "flashNewsPicture" },
        }),
        _vm._v(" "),
        _vm.tempPicture || _vm.values.picture
          ? _c(
              "label",
              {
                staticStyle: {
                  "text-align": "center",
                  cursor: "pointer",
                  display: "block",
                },
                attrs: { for: "flashNewsPicture" },
              },
              [
                _c("img", {
                  staticStyle: { "max-width": "100%", "max-height": "600px" },
                  attrs: { src: _vm.tempPicture || _vm.values.picture },
                }),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          style:
            _vm.tempPicture || _vm.values.picture
              ? { visibility: "hidden" }
              : { visibility: "visible" },
          attrs: { id: "flashNewsPicture", type: "file", accept: "image/*" },
          on: { change: _vm.changeFlashNewsPicture },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }