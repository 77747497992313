var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "InlineFormSelect",
    _vm._g(
      _vm._b(
        { attrs: { customClass: "text-capitalize" } },
        "InlineFormSelect",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("option", { attrs: { value: "" } }),
      _vm._v(" "),
      _vm._l(_vm.yesNo, function (item) {
        return _c("option", { domProps: { value: item } }, [
          _vm._v(_vm._s(item)),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }