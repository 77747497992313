var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.isCommercialSchedulerVisible || _vm.isGovernmentSchedulerVisible
      ? _c("div", { staticClass: "row" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-gradient col-12",
              on: { click: _vm.resetForm },
            },
            [_vm._v("Reset Form")]
          ),
        ])
      : _c("div", { staticClass: "row" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-gradient col-12 col-md-5",
              on: { click: _vm.showCommercialScheduler },
            },
            [_vm._v("\n            Commercial\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-gradient col-12 offset-md-2 col-md-5",
              on: { click: _vm.showGovernmentScheduler },
            },
            [_vm._v("\n            Government\n        ")]
          ),
        ]),
    _vm._v(" "),
    _vm.isCommercialSchedulerVisible
      ? _c("div", [
          _c("div", {
            staticClass: "calendly-inline-widget",
            staticStyle: { "min-width": "320px", height: "630px" },
            attrs: {
              "data-url":
                "https://calendly.com/globalspaceexchange_smallsat/commercial",
            },
          }),
          _vm._v(" "),
          _c("script", {
            attrs: {
              type: "application/javascript",
              src: "https://assets.calendly.com/assets/external/widget.js",
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isGovernmentSchedulerVisible
      ? _c("div", [
          _c("div", {
            staticClass: "calendly-inline-widget",
            staticStyle: { "min-width": "320px", height: "630px" },
            attrs: {
              "data-url":
                "https://calendly.com/globalspaceexchange_smallsat_usg",
            },
          }),
          _vm._v(" "),
          _c("script", {
            attrs: {
              type: "application/javascript",
              src: "https://assets.calendly.com/assets/external/widget.js",
            },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }