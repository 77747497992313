var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "page-title d-flex" }, [
    _c("div", { staticClass: "container text-left align-self-center" }, [
      _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
        _c(
          "ol",
          { staticClass: "breadcrumb" },
          _vm._l(_vm.breadcrumbs, function (breadcrumb) {
            return _c(
              "li",
              { key: breadcrumb.name, staticClass: "breadcrumb-item" },
              [
                breadcrumb.to
                  ? _c("router-link", {
                      attrs: { to: breadcrumb.to },
                      domProps: { textContent: _vm._s(breadcrumb.name) },
                    })
                  : _c("span", [_vm._v(_vm._s(breadcrumb.name))]),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "page-title-heading" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }