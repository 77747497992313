var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group row" },
    [
      _c("FormLabel", {
        staticClass: "col-3 col-form-label",
        attrs: { label: _vm.label, help: _vm.help },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "col-9" }, [
        _c(
          "div",
          { staticClass: "list-group" },
          [
            _vm._l(_vm.value, function (file) {
              return _c(
                "div",
                {
                  staticClass:
                    "list-group-item d-flex justify-content-between align-items-center",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "truncated",
                      attrs: { href: file.url, target: "_blank" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm._f("lastUrlSegment")(file.url)) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "clickable text-danger",
                      on: {
                        click: function ($event) {
                          return _vm.tryToRemove(file.fileid)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fe-icon-x" })]
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "list-group-item clickable",
                class: { disabledLook: !_vm.agreementAccepted },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("span", [_vm._v("Upload")]),
                _vm._v(" "),
                _c("input", {
                  staticClass: "d-none",
                  attrs: {
                    disabled: !_vm.agreementAccepted,
                    type: "file",
                    multiple: "",
                  },
                  on: { change: _vm.onFilesUpload },
                }),
              ]
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row col-12" },
        [
          _c("FormLabel", {
            staticClass: "col-8 col-form-label",
            attrs: {
              label:
                "I agree not to upload export controlled information to Global Space Exchange, Inc. and certify that all information uploaded is not subject to export control laws.",
            },
          }),
          _vm._v(" "),
          _c("input", {
            staticClass: "col-3",
            attrs: { type: "checkbox" },
            on: {
              change: function ($event) {
                _vm.agreementAccepted = !_vm.agreementAccepted
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", [_c("i", { staticClass: "fe-icon-plus" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }