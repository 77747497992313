var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dataTables_wrapper dt-bootstrap4 no-footer" },
    [
      _c("div", { staticClass: "dataTables_length" }, [
        _c("label", [
          _vm._v("\n            Show\n            "),
          _c(
            "select",
            {
              staticClass:
                "custom-select custom-select-sm form-control form-control-sm",
              attrs: { name: "basic-datatable_length" },
              domProps: { value: _vm.listStore.pageSize },
              on: {
                change: function ($event) {
                  return _vm.listStore.changePageSize($event.target.value)
                },
              },
            },
            [
              _c("option", { domProps: { value: 10 } }, [_vm._v("10")]),
              _vm._v(" "),
              _c("option", { domProps: { value: 25 } }, [_vm._v("25")]),
            ]
          ),
          _vm._v("\n            entries\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "LoadingOverlay",
        {
          attrs: {
            loading: _vm.listStore.isLoading,
            failed: _vm.listStore.failed,
          },
          on: { retry: _vm.listStore.reload },
        },
        [
          _c(
            "div",
            {
              staticClass: "table-responsive",
              staticStyle: { "padding-bottom": "20px" },
            },
            [
              _c("table", { staticClass: "table dataTable" }, [
                _c("thead", [
                  _c(
                    "tr",
                    { attrs: { role: "row" } },
                    _vm._l(_vm.enrichedColumns, function (column) {
                      return _c("th", {
                        key: column.id,
                        class: column.customClass,
                        domProps: { textContent: _vm._s(column.name) },
                        on: {
                          click: function ($event) {
                            return _vm.collectionStore.changeSort(column.id)
                          },
                        },
                      })
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(
                      _vm.collectionStore.items,
                      function (quiltyResource) {
                        return [
                          _c(
                            "tr",
                            {
                              key:
                                "quiltyResource_" +
                                quiltyResource.quiltyResourceId,
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: () => _vm.rowSelect(quiltyResource),
                              },
                            },
                            [
                              _c("td", [
                                _c("img", {
                                  attrs: {
                                    width: "200px",
                                    src: quiltyResource.picture,
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "h4",
                                  {
                                    staticStyle: {
                                      "font-size": "1.125rem",
                                      "font-weight": "500",
                                      margin: "10px 0",
                                      "line-height": "1.1",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(quiltyResource.title) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("div", [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(quiltyResource.summary) +
                                      "\n                                "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("div", [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm._f("toDateStringWeekday")(
                                          quiltyResource.postDate
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "tr",
                            { key: "tags_" + quiltyResource.quiltyResourceId },
                            [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    border: "none",
                                    "padding-top": "0",
                                  },
                                  attrs: { colspan: "5" },
                                },
                                _vm._l(
                                  _vm.getAllTags(quiltyResource),
                                  function (tag) {
                                    return _c(
                                      "span",
                                      {
                                        key:
                                          "tag_" +
                                          tag.label +
                                          "_" +
                                          quiltyResource.quiltyResourceId,
                                        class: `badge badge-${_vm.searchingTag(
                                          tag.label
                                        )} rounded-pill `,
                                        staticStyle: {
                                          margin: "4px",
                                          padding: "4px 8px",
                                        },
                                      },
                                      [
                                        tag.link
                                          ? _c(
                                              "router-link",
                                              { attrs: { to: tag.link } },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(tag.label) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(tag.label) +
                                                  "\n                                    "
                                              ),
                                            ]),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]
                      }
                    ),
                    _vm._v(" "),
                    _vm.listStore.noRecords ? _c("NoRecords") : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("v-pagination", {
        attrs: {
          pageCount: _vm.listStore.pageCount,
          value: _vm.listStore.page,
        },
        on: { input: _vm.listStore.changePage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }