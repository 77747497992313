var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Spotlight Information")]),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Title", error: _vm.errors.title, required: "" },
        model: {
          value: _vm.values.title,
          callback: function ($$v) {
            _vm.$set(_vm.values, "title", $$v)
          },
          expression: "values.title",
        },
      }),
      _vm._v(" "),
      _c("InlineFormDate", {
        attrs: { label: "Posted Date", error: _vm.errors.postDate },
        model: {
          value: _vm.values.postDate,
          callback: function ($$v) {
            _vm.$set(_vm.values, "postDate", $$v)
          },
          expression: "values.postDate",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "Author", error: _vm.errors.author, required: "" },
        model: {
          value: _vm.values.author,
          callback: function ($$v) {
            _vm.$set(_vm.values, "author", $$v)
          },
          expression: "values.author",
        },
      }),
      _vm._v(" "),
      _c("InlineFormInput", {
        attrs: { label: "URL", error: _vm.errors.url },
        model: {
          value: _vm.values.url,
          callback: function ($$v) {
            _vm.$set(_vm.values, "url", $$v)
          },
          expression: "values.url",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Narrative")]),
      _vm._v(" "),
      _c("quill-editor", {
        ref: "myQuillEditor",
        attrs: {
          options: {
            modules: {
              toolbar: [
                [{ header: [1, 2, 3, 4, false] }],
                ["bold", "italic", "underline"],
                [{ color: [] }, { background: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ align: [] }],
                ["image", "link"],
              ],
            },
          },
        },
        model: {
          value: _vm.values.narrative,
          callback: function ($$v) {
            _vm.$set(_vm.values, "narrative", $$v)
          },
          expression: "values.narrative",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Highlight Bullets")]),
      _vm._v(" "),
      _c("ReportBulletPointTable", {
        model: {
          value: _vm.values.highlightBullets,
          callback: function ($$v) {
            _vm.$set(_vm.values, "highlightBullets", $$v)
          },
          expression: "values.highlightBullets",
        },
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "h6 mt-3" }, [_vm._v("Tags")]),
      _vm._v(" "),
      _c("InlineFormTreeselect", {
        attrs: {
          label: "Products",
          options: _vm.productOptions,
          sortValueBy: "LEVEL",
          error: _vm.errors.products,
          multiple: "",
          flat: "",
        },
        model: {
          value: _vm.values.products,
          callback: function ($$v) {
            _vm.$set(_vm.values, "products", $$v)
          },
          expression: "values.products",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTreeselect", {
        attrs: {
          label: "Service Selection",
          options: _vm.serviceOptions,
          sortValueBy: "LEVEL",
          error: _vm.errors.services,
          multiple: "",
          flat: "",
        },
        model: {
          value: _vm.values.services,
          callback: function ($$v) {
            _vm.$set(_vm.values, "services", $$v)
          },
          expression: "values.services",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTreeselect", {
        attrs: {
          label: "Operating Regimes",
          options: _vm.operatingRegimeOptions,
          sortValueBy: "LEVEL",
          error: _vm.errors.operatingRegimeTags,
          multiple: "",
          flat: "",
        },
        model: {
          value: _vm.values.operatingRegimeTags,
          callback: function ($$v) {
            _vm.$set(_vm.values, "operatingRegimeTags", $$v)
          },
          expression: "values.operatingRegimeTags",
        },
      }),
      _vm._v(" "),
      _c("InlineFormTreeselect", {
        attrs: {
          label: "Applications",
          options: _vm.applicationOptions,
          sortValueBy: "LEVEL",
          error: _vm.errors.applicationTags,
          multiple: "",
          flat: "",
        },
        model: {
          value: _vm.values.applicationTags,
          callback: function ($$v) {
            _vm.$set(_vm.values, "applicationTags", $$v)
          },
          expression: "values.applicationTags",
        },
      }),
      _vm._v(" "),
      _c("InlineFormMultiSelect", {
        attrs: {
          label: "Related Organizations",
          options: _vm.organizations,
          error: _vm.errors.organizationNames,
          value: _vm.spotlightOrganizationNames,
          optionsLimit: 20,
          multiple: "",
        },
        on: { input: _vm.selectOrganizationName },
      }),
      _vm._v(" "),
      _c("InlineFormSimpleMultiSelect", {
        attrs: {
          label: "Custom Tags",
          error: _vm.errors.tags,
          taggable: "",
          multiple: "",
        },
        model: {
          value: _vm.values.tags,
          callback: function ($$v) {
            _vm.$set(_vm.values, "tags", $$v)
          },
          expression: "values.tags",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }