var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticStyle: { "flex-grow": "1", display: "flex" } }, [
    _c("section", { staticClass: "cred-container" }, [
      _c("h5", { staticStyle: { color: "white", "font-size": "1.4rem" } }, [
        _vm._v("Forgot your password?"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "signin-box" }, [
        _c(
          "form",
          {
            staticClass: "wizard",
            staticStyle: { "border-radius": "4px" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.resetPassword.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "wizard-body py-3" },
              [
                _c("div", { staticClass: "px-2 text-center" }, [
                  _c("figure", { staticClass: "m-3" }, [
                    _vm.hasQuiltyInUrl
                      ? _c("img", {
                          attrs: {
                            src: require("../../../common/images/Quilty_Logo_Assets/QS/SVG/QS_Logo_Black_and_Blue_on_Transparent.svg"),
                            alt: "satelite",
                            width: "100",
                            height: "100",
                          },
                        })
                      : _c("img", {
                          attrs: {
                            src: require("../../images/logo_icon.svg"),
                            alt: "satelite",
                            width: "100",
                            height: "100",
                          },
                        }),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("Change your password in three easy steps.")]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("FormInput", {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Enter your email address",
                    type: "email",
                    error: _vm.errors.email,
                    required: "",
                  },
                  model: {
                    value: _vm.values.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.values, "email", $$v)
                    },
                    expression: "values.email",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "wizard-footer",
                staticStyle: {
                  "flex-direction": "column",
                  "align-items": "center",
                  gap: "8px",
                  border: "0",
                },
              },
              [
                _c(
                  "ActionButton",
                  {
                    staticClass: "btn-primary",
                    staticStyle: {
                      border: "1px solid #19799f",
                      "border-radius": "4px",
                      "background-color": "white",
                      color: "#19799f",
                      width: "250px",
                    },
                    attrs: {
                      type: "submit",
                      loading: _vm.passwordResetStore.isLoading,
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Get new password\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { "font-size": "0.75rem" } },
                  [
                    _vm._v(
                      "\n                        Remember your credentials?\n                        "
                    ),
                    _c(
                      "router-link",
                      {
                        staticStyle: { "text-decoration": "none" },
                        attrs: { to: "/signin" },
                      },
                      [
                        _vm._v(
                          "\n                            Sign in here.\n                        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ol", { staticClass: "list-unstyled" }, [
      _c("li", [
        _c("span", { staticClass: "font-weight-medium text-accent" }, [
          _vm._v("1."),
        ]),
        _vm._v(
          "\n                            Fill in your email address below.\n                        "
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("span", { staticClass: "font-weight-medium text-accent" }, [
          _vm._v("2."),
        ]),
        _vm._v(
          "\n                            We'll email you a link where you can change your password.\n                        "
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("span", { staticClass: "font-weight-medium text-accent" }, [
          _vm._v("3."),
        ]),
        _vm._v(
          "\n                            Provide new password.\n                        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }