var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticStyle: { "flex-grow": "1", display: "flex" } }, [
    _c("section", { staticClass: "cred-container" }, [
      _c("h5", { staticStyle: { color: "white", "font-size": "1.4rem" } }, [
        _vm._v("Change Password"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "signin-box" }, [
        _c(
          "form",
          {
            staticClass: "wizard",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.changePassword.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "wizard-body pt-6 pb-0" }, [
              _c("div", { staticClass: "col-sm-12 input-group form-group" }, [
                `${_vm.passwordIsVisible ? "text" : "password"}` === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.values.password,
                          expression: "values.password",
                        },
                      ],
                      class: ["form-control", "pl-5"],
                      attrs: {
                        placeholder: "Password (8 or more characters)",
                        autocomplete: "current-password",
                        required: "",
                        pattern:
                          "^(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+,.?])[A-Za-z\\d!@#$%^&*()_+,.?]{8,}$",
                        title:
                          "Password must be at least 8 characters long, including 1 number, 1 capital letter, and 1 special character.",
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.values.password)
                          ? _vm._i(_vm.values.password, null) > -1
                          : _vm.values.password,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.values.password,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.values,
                                  "password",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.values,
                                  "password",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.values, "password", $$c)
                          }
                        },
                      },
                    })
                  : `${_vm.passwordIsVisible ? "text" : "password"}` === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.values.password,
                          expression: "values.password",
                        },
                      ],
                      class: ["form-control", "pl-5"],
                      attrs: {
                        placeholder: "Password (8 or more characters)",
                        autocomplete: "current-password",
                        required: "",
                        pattern:
                          "^(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+,.?])[A-Za-z\\d!@#$%^&*()_+,.?]{8,}$",
                        title:
                          "Password must be at least 8 characters long, including 1 number, 1 capital letter, and 1 special character.",
                        type: "radio",
                      },
                      domProps: { checked: _vm._q(_vm.values.password, null) },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.values, "password", null)
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.values.password,
                          expression: "values.password",
                        },
                      ],
                      class: ["form-control", "pl-5"],
                      attrs: {
                        placeholder: "Password (8 or more characters)",
                        autocomplete: "current-password",
                        required: "",
                        pattern:
                          "^(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+,.?])[A-Za-z\\d!@#$%^&*()_+,.?]{8,}$",
                        title:
                          "Password must be at least 8 characters long, including 1 number, 1 capital letter, and 1 special character.",
                        type: `${_vm.passwordIsVisible ? "text" : "password"}`,
                      },
                      domProps: { value: _vm.values.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.values, "password", $event.target.value)
                        },
                      },
                    }),
                _vm._v(" "),
                _c("i", {
                  class: `far ${
                    _vm.passwordIsVisible ? "fa-eye" : "fa-eye-slash"
                  }`,
                  style: `position: absolute; top: 50%; transform: translate(100%, -50% ); z-index: 1; cursor: pointer;`,
                  on: {
                    click: function ($event) {
                      _vm.passwordIsVisible = !_vm.passwordIsVisible
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "form-help",
                    style: `position: absolute; top: 50%; right: 0; transform: translate(-200%, -50%); z-index: 1; cursor: help;`,
                    attrs: { title: _vm.PASSWORD_HELP },
                  },
                  [_c("i", { staticClass: "fe-icon-help-circle" })]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-12" },
                [
                  _c("FormInput", {
                    attrs: {
                      placeholder: "Confirm Password",
                      type: "password",
                      required: "",
                    },
                    model: {
                      value: _vm.values.confirmPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.values, "confirmPassword", $$v)
                      },
                      expression: "values.confirmPassword",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "wizard-footer",
                staticStyle: {
                  "flex-direction": "column",
                  "align-items": "center",
                  gap: "8px",
                  border: "0",
                },
              },
              [
                _c(
                  "ActionButton",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      loading: _vm.changePasswordStore.isLoading,
                      type: "submit",
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Change Password\n                    "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }