var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group row" },
    [
      _c("FormLabel", {
        staticClass: "col-5 col-form-label",
        attrs: { label: _vm.label, required: _vm.required },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-7" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.date,
                expression: "date",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "date", required: _vm.required },
            domProps: { value: _vm.date },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.date = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("FormFeedback", { attrs: { error: _vm.error } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }