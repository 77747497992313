var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("PastProjectForm", {
    attrs: {
      loading: _vm.createStore.isLoading,
      errors: _vm.createStore.formErrors,
      values: _vm.createStore.data,
    },
    on: { submit: _vm.submit },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }