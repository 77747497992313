var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "LoadingOverlay",
    {
      attrs: {
        loading: _vm.fileListStore.isLoading,
        failed: _vm.fileListStore.isFailed,
      },
      on: { retry: _vm.fileListStore.reload },
    },
    [
      _c("FilesUpload", {
        attrs: { label: "P&S Catalog", value: _vm.value },
        on: {
          upload: _vm.fileListStore.upload,
          remove: _vm.fileListStore.remove,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }