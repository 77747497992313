var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("h1", { staticClass: "h3 bar tertiary" }, [
        _vm._v("Terms and Conditions"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container my-5" }, [
        _c(
          "p",
          {
            staticStyle: {
              "margin-top": "0in",
              "margin-right": "0in",
              "margin-bottom": "12pt",
              "margin-left": "0in",
              "line-height": "19.2pt",
              "font-size": "15px",
              "font-family": "'Calibri', sans-serif",
              color: "black",
              border: "none",
              "text-align": "center",
            },
          },
          [
            _c("strong", [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "15px",
                    "font-family": "Calibri, sans-serif",
                  },
                },
                [
                  _vm._v(
                    "\n                    Global Space Exchange, Inc. \n                "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticStyle: {
              "margin-top": "0in",
              "margin-right": "0in",
              "margin-bottom": "12pt",
              "margin-left": "0in",
              "line-height": "19.2pt",
              "font-size": "15px",
              "font-family": "'Calibri', sans-serif",
              color: "black",
              border: "none",
              "text-align": "center",
            },
          },
          [
            _c(
              "span",
              { staticStyle: { "font-family": "Calibri, sans-serif" } },
              [
                _c("span", { staticStyle: { "font-size": "15px" } }, [
                  _c("strong", [_vm._v("Site Terms and Conditions of Use")]),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticStyle: {
              "margin-top": "0in",
              "margin-right": "0in",
              "margin-bottom": "12pt",
              "margin-left": "0in",
              "line-height": "19.2pt",
              "font-size": "15px",
              "font-family": "'Calibri', sans-serif",
              color: "black",
              border: "none",
              "text-align": "center",
            },
          },
          [
            _c(
              "span",
              { staticStyle: { "font-family": "Calibri, sans-serif" } },
              [
                _c("span", { staticStyle: { "font-size": "15px" } }, [
                  _vm._v(
                    "\n                    Version 1.3; Effective Date: May 29, 2020; Last revised on: May 29, 2020\n                "
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "decimal",
              "margin-left": "-0.25in",
            },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                      _vm._v("Introduction."),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("ol", { staticStyle: { "list-style-type": "lower-alpha" } }, [
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                The website located at \n                                "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.globalspaceexchange.com",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "black",
                                  "text-decoration": "none",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                        https://www.globalspaceexchange.com\n                                    "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(
                          "\n                                , and associated subdomains, applications, widgets, and services\n                                (the “Site”) is a copyrighted work belonging to\n                                Global Space Exchange, Inc. (“GSE”,\n                                “us”, “our”, and “we”).\n                                These Site Terms and Conditions of Use (“Terms”) set\n                                forth the legally binding terms and conditions that govern your\n                                use of the Site.  By accessing or using the Site, you and\n                                any associated agents or representatives (“you”,\n                                “your”,  and “yourself”) are\n                                accepting these Terms on behalf of yourself and any entity that\n                                you represent, and you represent and warrant that you have the\n                                authority, right, and capacity to enter into these Terms on\n                                behalf of yourself or the entity that you represent. You may not\n                                access or use the Site or accept the Terms if you are not at\n                                least 18 years of age. If you do not agree with all of the\n                                provisions of these Terms, do not access or use the Site.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                Acceptance of Terms of Use and Future Modifications\n                                (Amendments).\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticStyle: {
              "margin-top": "0in",
              "margin-right": "0in",
              "margin-bottom": "5pt",
              "margin-left": "1in",
              "line-height": "16.55pt",
              "font-size": "15px",
              "font-family": "'Calibri', sans-serif",
              color: "black",
              border: "none",
            },
          },
          [
            _c(
              "span",
              { staticStyle: { "font-family": "Calibri, sans-serif" } },
              [
                _c("span", { staticStyle: { "font-size": "15px" } }, [
                  _vm._v(
                    "\n                    By using or causing access to this Site, you agree to be bound by these\n                    Terms, as amended from time to time with or without notice to you and as\n                    described in Section 15. In addition, if you are using a particular service\n                    on this Site or accessed via this Site, you will be subject to any rules or\n                    guidelines applicable to those services, and they will be incorporated by\n                    reference within these Terms of Use. Please read the Site's Privacy\n                    Policy, which is incorporated into these Terms by reference in Section 11\n                    below.\n                "
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("2. Site Use."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v("License. "),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticStyle: {
              "margin-top": "0in",
              "margin-right": "0in",
              "margin-bottom": "5pt",
              "margin-left": "1in",
              "line-height": "16.55pt",
              "font-size": "15px",
              "font-family": "'Calibri', sans-serif",
              color: "black",
              border: "none",
            },
          },
          [
            _c(
              "span",
              { staticStyle: { "font-family": "Calibri, sans-serif" } },
              [
                _c("span", { staticStyle: { "font-size": "15px" } }, [
                  _vm._v(
                    "\n                    Subject to these Terms, we grant you a non-transferable, non-exclusive,\n                    revocable, limited license to use and access the Site and the information\n                    contained therein. The license remains active for the period of time that\n                    you purchase.\n                "
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-roman",
              "margin-left": "0.8500000000000001in",
            },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        Acceptable Use / Use of Information & Reports.\n                    "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("ol", { staticStyle: { "list-style-type": "lower-alpha" } }, [
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You may access Site content to review information provided by\n                                GSE and Third-Party Content as described in Sections 2 and 13.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You may utilize Site content as a business intelligence tool to\n                                inform business decisions, conduct market research, discover\n                                trends, and review our analyses and reports.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You may include licensed Site content in presentations, in\n                                research reports, and in any other activity as long as it\n                                remains non-competitive to GSE as described in Section (2)\n                                (a.)(ii.) and the respective data owner is prominently cited as\n                                described in Section (2) (a.) (v.). \n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You agree you shall not provide Site content to third parties\n                                except as described in these Terms.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v("Competitive Activity."),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("ol", { staticStyle: { "list-style-type": "lower-alpha" } }, [
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You agree to use Site content expressly for activities\n                                non-competitive to GSE.  Non-competitive activity is\n                                defined as any use permitted by these Terms that does not\n                                attempt to aggregate our data, replicate our analytics,\n                                platform(s), website, or utilize other information or products\n                                by you or a third-party to create similar product or service\n                                offerings available commercially or privately, with or without\n                                compensation. If there is any question that your use of Site\n                                content may be considered competitive, you agree to contact us\n                                for advance approval of your intended use for Site content 20\n                                business days prior to your need date. Without prior approval,\n                                use of Site content for purposes competitive to us shall be\n                                prohibited. We will review requests within 15 business days and\n                                provide an approval or disproval notification. You agree that we\n                                may employ Site and user traffic monitoring for enforcement\n                                purposes.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v("Downloads."),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("ol", { staticStyle: { "list-style-type": "lower-alpha" } }, [
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                Site content intended for download is indicated as such and is\n                                denoted by a button that initiates a download process.\n                                Downloadable information may be utilized by you for internal\n                                review and analytic purposes; however, the information remains\n                                the property of GSE granted to you under the terms of your\n                                license.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You agree to delete downloaded Site content upon expiry of your\n                                license term and further agree that an authorized agent of the\n                                member shall certify, in writing, that Site content has been\n                                deleted within 30 days of license expiration.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v("Ownership of information."),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("ol", { staticStyle: { "list-style-type": "lower-alpha" } }, [
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You agree and acknowledge that all Site content is owned by GSE\n                                or Site members.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                Site members are asked to provide certain information in user\n                                profiles or in order to support industry analysis; data provided\n                                for these purposes shall not be considered proprietary to the\n                                respective member. You agree that information you enter into\n                                user or company profiles and responses to inquiries such as, but\n                                not limited to, surveys shall not be considered proprietary data\n                                and that if entered by you, you agree not to hold us liable for\n                                proprietary data infringement. Further, you agree that we may\n                                utilized data you enter for our legitimate business purposes.\n                                For example, the we may utilize the number of employees entered\n                                to generate industry reports on workforce sizing.\n                                "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          "\n                                 \n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        You agree that content derived from this Site is subject to these Terms\n                        and shall not be communicated in any form without the following readily\n                        visible disclaimer: “This information was derived from Global\n                        Space Exchange content.” You further agree that the types of\n                        insights and analytics derived from GSE data are not proprietary to you\n                        and that you will not assert claims of any kind against GSE for similar\n                        works, insights, analytics, etc. that we may produce. \n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "2" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v("Account information."),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-roman",
              "margin-left": "0.8500000000000001in",
            },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        You are responsible for maintaining the confidentiality of your password\n                        and you will be responsible for all usage of your user account and/or\n                        user name, whether authorized or not authorized by you. You agree to\n                        notify us via email at \n                        "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "mailto:support@globalspaceexchange.com",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "black",
                              "text-decoration": "none",
                            },
                          },
                          [
                            _vm._v(
                              "\n                                support@globalspaceexchange.com\n                            "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n                         and change your password within 1 business day of any unauthorized\n                        use of your user account, user name or password.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "3" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v("Certain Restrictions. "),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-roman",
              "margin-left": "0.8500000000000001in",
            },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        The rights granted to you in these Terms are subject to the following\n                        restrictions: \n                    "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("ol", { staticStyle: { "list-style-type": "lower-alpha" } }, [
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You shall not license, sell, rent, lease, transfer, assign,\n                                distribute, host, or otherwise utilize the Site or Site content\n                                for purposes not expressly authorized by these Terms, whether in\n                                whole or in part.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You shall not modify, make derivative works of, disassemble,\n                                reverse compile or reverse engineer any part of the Site.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You shall not access the Site in order to create a similar or\n                                competitive website, product, or service. You shall not access\n                                the site via any automated form of access such as bots,\n                                crawlers, scripts, etc. and shall make no attempt to aggregate\n                                Site information.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                Except as expressly stated herein, no part of the Site may be\n                                copied, reproduced, distributed, republished, downloaded,\n                                displayed, posted or transmitted in any form or by any\n                                means. Unless otherwise indicated, any future release,\n                                update, or other addition to functionality of the Site shall be\n                                subject to these Terms. If any Site content contains copyright\n                                or other proprietary markings, any disclosures of the respective\n                                information authorized by these Terms shall retain the\n                                respective markings.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "4" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v("Modification of Site. "),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-roman",
              "margin-left": "0.8500000000000001in",
            },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        We reserve the right, at any time, to modify, suspend, or discontinue\n                        the Site (in whole or in part) with or without notice to you.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v(
                    "\n                        3. Warranty of Information / Limitation of Liability.\n                    "
                  ),
                ]),
                _vm._v("\n                     \n                "),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v("Background."),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-roman",
              "margin-left": "0.75in",
            },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        GSE is a knowledge and technology collaboration platform. While GSE does\n                        work to ensure quality of the information contained on the Site and\n                        related applications, the nature and breadth of the content makes it\n                        impractical to provide any form of warranty.  \n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "2" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v("“As-Is and As-Available”"),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-roman",
              "margin-left": "0.75in",
            },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        The Site is provided on an “as-is” and\n                        “as-available” basis, and GSE (and our suppliers and\n                        members) expressly disclaim any and all warranties and conditions of any\n                        kind, whether express, implied, or statutory, including all warranties\n                        or conditions of merchantability, fitness for a particular purpose,\n                        title, quiet enjoyment, availability, accuracy, error-free, secure,\n                        devoid of harmful code of any type, legal, safe, or non-infringement. If\n                        applicable law requires any warranties with respect to the Site, all\n                        such warranties are limited in duration to ninety (90) days from the\n                        date of first use. Some jurisdictions do not allow the exclusion of\n                        implied warranties or limitations on implied warranty duration, so the\n                        above exclusion may not apply to you. The other terms and conditions of\n                        use remain enforceable notwithstanding.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "3" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v("Limitation of Liability"),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-roman",
              "margin-left": "0.75in",
            },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        GSE will not be liable to you or to any other party for any direct,\n                        indirect, incidental, special, consequential or exemplary damages,\n                        including but not limited to, damages for loss of profits, goodwill,\n                        use, data, or other intangible losses, regardless of whether we were\n                        advised of the possibility of such damage. In no event shall our\n                        liability exceed one thousand U.S. dollars (USD 1000.00) in aggregate.\n                        In the case that applicable law may not allow the limitation or\n                        exclusion of liability or incidental or consequential damages, the above\n                        limitation or exclusion may not apply to you, although our liability\n                        will be limited to the fullest extent permitted by applicable law.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("4. Copyright, Trademarks, Rights"),
                ]),
                _vm._v("\n                    .\n                "),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        Copyright © 2020, Global Space Exchange, Inc. All rights\n                        reserved. All trademarks, logos and service marks\n                        (“Marks”) displayed on the Site are the property of their\n                        respective owners. By using our Site, you are not granted any rights to\n                        use these Marks without the permission of their respective owners.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        Notification of Copyright Infringement. \n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-roman",
              "margin-left": "0.75in",
            },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        If you believe that your intellectual property has been used in any way\n                        that could be considered a copyright infringement or a violation of your\n                        intellectual property rights, you agree to contact our copyright agent\n                        to address the assertion via email:\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "1in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        Attn: COPYWRIGHT, Global Space Exchange,\n                        Inc.; support@globalspaceexchange.com, or via mail:\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        Attn: COPYWRIGHT, Global Space Exchange, Inc.; 800 Maine Ave. SW, Suite\n                        #200; Washington DC, 20024.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _vm._v("5. Submission of Content."),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        By providing any content to this Site: (a) you agree to grant GSE a\n                        worldwide, royalty-free, perpetual, non-exclusive right and license\n                        (including any moral rights or other necessary rights.) to use, display,\n                        reproduce, modify, adapt, publish, distribute, perform, promote,\n                        archive, translate and to create derivative works and compilations, in\n                        whole or in part. Such license will apply with respect to any form,\n                        media, technology already known at the time of provision or developed\n                        subsequently (b) you warrant and represent that you have all legal,\n                        moral and other rights that may be necessary to grant us the license\n                        specified in this Section 5: (c) you acknowledge and agree that we have\n                        the right (but not obligation), at our entire discretion, to refuse to\n                        publish, to remove or to block access to any content you provide, at any\n                        time and for any reason, with or without notice; (e) you agree and\n                        warrant that you will not submit proprietary, confidential, or export\n                        controlled content to the Site.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("6. Professional Conduct."),
                ]),
                _vm._v("\n                      \n                "),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        We seek to create a trusted resource for the space industry with\n                        collaborative input from verified members.  We expect our members\n                        to hold themselves to a high professional standard. It is through\n                        respect and professionalism that we may collectively advance the state\n                        of the industry and achieve the impossible.  With that background;\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-roman",
              "margin-left": "0.75in",
            },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        By using this Site or any service provided, you explicitly agree that:\n                    "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("ol", { staticStyle: { "list-style-type": "lower-alpha" } }, [
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You will not provide any content or conduct yourself in any way\n                                that may be construed as: unlawful; illegal; threatening:\n                                harmful: abusive; harassing: stalking: tortious; defamatory;\n                                libelous; vulgar; obscene; offensive; objectionable;\n                                pornographic; designed to interfere with or disrupt the\n                                operation of this Site or any service provided; infected with a\n                                virus or other destructive or deleterious programming routine;\n                                giving rise to civil or criminal liability; or in violation of\n                                an applicable local, national or international law. \n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You will not collect or harvest any information about other\n                                users.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You will not provide, and you will not use this Site to provide,\n                                any content or service in any commercial manner, or in any\n                                manner that would involve junk mail, spam, chain letters,\n                                pyramid schemes or any other form of unauthorized advertising or\n                                commerce, you will not use this Site to promote or operate any\n                                service or content without our prior written consent. \n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("7. Misrepresentation."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        You expressly agree you will not impersonate or misrepresent your\n                        association with any person or entity: you will not forge or otherwise\n                        seek to conceal or misrepresent the origin of any content provided by\n                        you.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("8. Feedback."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        We value your feedback and will work to act upon it as able. If you\n                        provide GSE with any feedback or suggestions regarding the Site\n                        (“Feedback”), you hereby assign to GSE all rights in such\n                        Feedback and agree that GSE shall have the right to use and fully\n                        exploit such Feedback and related information in any manner it deems\n                        appropriate. We will treat any Feedback you provide us as\n                        non-confidential and non-proprietary to you. You agree that you will not\n                        submit to us any information or ideas that you consider to be\n                        confidential or proprietary.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("9. Export."),
                ]),
                _vm._v("\n                      \n                "),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        You agree not to directly or indirectly upload, add content, modify\n                        content, or otherwise make available export-controlled data on the Site.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        Site content may be subject to U.S. export control laws and may be\n                        subject to export or import regulations in other countries. You agree\n                        not to export, reexport, or transfer, directly or indirectly, any\n                        technical data acquired from GSE, or any products utilizing such data,\n                        in violation of the United States or International export laws or\n                        regulations.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("10. Indemnification."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        You agree to indemnify and hold harmless GSE and its officers,\n                        employees, agents, and our subcontractors at any tier, including costs\n                        and attorneys’ fees, from any claim or demand made by any\n                        third-party due to or arising out of (1) your use of the Site, (2) your\n                        violation of these Terms, or (3) your violation of applicable laws or\n                        regulations. GSE reserves the right, at your expense, to assume the\n                        exclusive defense and control of any matter for which you are required\n                        to indemnify us, and you agree to cooperate with our defense of these\n                        claims.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("11. Privacy Policy"),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _c("span", { staticStyle: { "line-height": "107%" } }, [
                      _vm._v(
                        "\n                            The Site Privacy Policy is incorporated herein by reference and is\n                            located at www.globalspaceexchange.com/privacy.  You may also\n                            obtain a copy of the privacy policy by emailing us at\n                            support@globalspaceexchange.com.\n                        "
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("12. Applicable Law."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        You agree that these Terms of Use and any dispute arising out of your\n                        use of this Site or products or services provided will be governed by\n                        and construed in accordance with local laws the District of Columbia,\n                        notwithstanding any differences between the said applicable legislation\n                        and legislation in force at your location. By registering for a user\n                        account on this Site or by using this Site or the services it provides,\n                        you accept that jurisdiction is granted to the courts of the District of\n                        Columbia and that any disputes will be heard by them. \n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("13. Third-Party Content."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        The Site may contain links to third-party websites and services,\n                        content, applications and/or display advertisements for third parties\n                        (collectively, “Third-Party Content”). Such Third-Party\n                        Content are not under the control of GSE, and you agree that GSE is not\n                        responsible for and shall not be held liable for any Third-Party\n                        Content. GSE provides access to Third-Party Content solely as a\n                        convenience to you, and does not review, approve, monitor, endorse,\n                        warrant, or make any representations with respect to Third-Party\n                        Content. You use all Third-Party Content at your own risk and should\n                        apply a suitable level of caution and discretion in doing so. When you\n                        click on any of the Third-Party Content, the applicable\n                        third-party’s terms and policies apply, including the\n                        third-party’s privacy and data gathering practices. You should not\n                        proceed with any transaction in connection with such Third-Party Content\n                        until you have conducted appropriate due diligence.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("14. Release."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        You hereby release and forever discharge GSE (and our officers,\n                        employees, agents, successors, and assigns) from, and hereby waive and\n                        relinquish, each and every past, present and future dispute, claim,\n                        controversy, demand, right, obligation, liability, action and cause of\n                        action of every kind and nature (including personal injuries, death, and\n                        property damage), that has arisen or arises directly or indirectly out\n                        of, or that relates directly or indirectly to, the Site (including any\n                        interactions with, or act or omission of, other Site users or any\n                        Third-Party Links, Applications & Ads). If you are a California\n                        resident, you hereby waive California civil code section 1542 in\n                        connection with the foregoing, which states: “A general release\n                        does not extend to claims which the creditor does not know or suspect to\n                        exist in his or her favor at the time of executing the release, which if\n                        known by him or her must have materially affected his or her settlement\n                        with the debtor.”\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("15. Modification of Terms."),
                ]),
                _vm._v("\n                     \n                "),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        These Terms are subject to occasional revision, and if we make any\n                        substantial changes, we may or may not notify you. If we notify you, we\n                        will send you an e-mail to the last e-mail address you provided to us\n                        (if any), and/or by prominently posting notice of the changes on our\n                        Site. You are responsible for providing us with your most current e-mail\n                        address. In the event that the last e-mail address that you have\n                        provided us is not valid, or for any reason is not capable of delivering\n                        to you the notice described above, our dispatch of the e-mail containing\n                        such notice will nonetheless constitute effective notice of the changes\n                        described in the notice. Any changes to these Terms will be effective\n                        upon the earlier of thirty (30) calendar days following our posting\n                        updated Terms on our Site which applies to you and these changes will be\n                        effective immediately for new users of our Site. Continued use of our\n                        Site following notice of such changes shall indicate your\n                        acknowledgement of such changes and agreement to be bound by the terms\n                        and conditions of such changes.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "#404040" } }, [
                  _vm._v("16. Enforcement."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        We reserve the right (but have no obligation) to review and to\n                        investigate and/or take appropriate action against you in our sole\n                        discretion if you violate the Terms or otherwise create liability for us\n                        or any other person. Such action may include terminating your account in\n                        accordance with Section 17, and/or reporting you to law enforcement\n                        authorities.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("17. Term & Termination."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        Subject to this Section, these Terms will remain in full force and\n                        effect while you use the Site and at any time you have access to content\n                        provided by us or through us by our members. We may suspend or terminate\n                        your rights to use the Site (including your Account) at any time for any\n                        reason at our sole discretion, including for any use of the Site in\n                        violation of these Terms. Upon termination of your rights under these\n                        Terms, your Account and right to access and use the Site will terminate\n                        immediately. You understand that any termination of your Account may\n                        involve deletion of your information associated with your Account from\n                        our live databases. GSE will not have any liability whatsoever to you\n                        for any termination of your rights under these Terms, including for\n                        termination of your Account or deletion of your account information.\n                        Even after your rights under these Terms are terminated, the following\n                        provisions of these Terms will remain in effect: Sections\n                        1-4,9-12,14,16,20,22.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("18. Fees and Payments."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "63.8px",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        You agree that we may collect fees for services and products offered on\n                        our Site through Stripe, a third-party payment processor that accepts\n                        credit or debit cards. Other forms of payment are accepted on a case by\n                        case basis and will be processed over the phone by us.  \n                    "
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        You consent that fees may be collected on an annual, monthly, or\n                        one-time basis as described in the service(s) you select. \n                    "
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        You agree that membership fees are nonrefundable, even if you cancel or\n                        terminate your membership agreement.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        You agree to contact us within one business day if you believe a payment\n                        was made in error.   If we are responsible for the error, we will\n                        work to investigate and resolve the issue in a timely manner.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "5" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        If you cancel, place on hold, revoke, or otherwise disrupt the\n                        processing of a payment, you agree that your rights to access and use of\n                        paid Site content is immediately revoked until such time that proper\n                        payment is processed, and your account balance is paid.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("19. Cancellation of Service."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "63.8px",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        You may terminate your subscription to our services at any time by\n                        emailing \n                        "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "mailto:support@globalspaceexchange.com",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#0563c1",
                              "text-decoration": "underline",
                            },
                          },
                          [
                            _vm._v(
                              "\n                                support@globalspaceexchange.com\n                            "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n                         or calling the number listed on our Site.  \n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "#404040" } }, [
                  _vm._v("20. Electronic Communications."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        The communications between you and GSE use electronic means, whether you\n                        use the Site or send us emails, or whether GSE posts notices on the Site\n                        or communicates with you via email. For contractual purposes;\n                    "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("ol", { staticStyle: { "list-style-type": "lower-alpha" } }, [
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You consent to receive communications from GSE in an electronic\n                                form.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "span",
                    { staticStyle: { "font-family": "Calibri, sans-serif" } },
                    [
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v(
                          "\n                                You agree that all terms and conditions, agreements, notices,\n                                disclosures, and other communications that GSE provides to you\n                                electronically satisfy any legal requirement that such\n                                communications would satisfy if it were be in a hardcopy\n                                writing. The foregoing does not affect your non-waivable rights.\n                            "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "#404040" } }, [
                  _vm._v("21. Miscellaneous."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        No Support or Maintenance. You acknowledge and agree that GSE will have\n                        no obligation to provide you with any support or maintenance in\n                        connection with the Site.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("22. Entire Terms."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        These Terms constitute the entire agreement between you and us regarding\n                        your use of the Site. The section titles in these Terms are for\n                        convenience only and have no legal or contractual effect. The word\n                        “including” means “including without\n                        limitation”. If any provision of these Terms is, for any reason,\n                        held to be invalid or unenforceable, the other provisions of these Terms\n                        will be unimpaired, and the invalid or unenforceable provision will be\n                        deemed modified so that it is valid and enforceable to the maximum\n                        extent permitted by law.  \n                    "
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        Neither Global Space Exchange nor user is an agent or partner of the\n                        other.  \n                    "
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        These Terms, and your rights and obligations herein, may not be\n                        assigned, subcontracted, delegated, or otherwise transferred by you\n                        without our prior written consent, and any attempted assignment,\n                        subcontract, delegation, or transfer in violation of the foregoing will\n                        be null and void.  GSE may freely assign these Terms. The terms and\n                        conditions set forth in these Terms shall be binding upon assignees.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        The failure of either party to assert any right under these Terms will\n                        not be considered to be a waiver of that party's right, and the said\n                        right will remain in full force and effect. You agree that any claim or\n                        cause in respect of this Site or its services must be filed within one\n                        (1) year after such claim or cause arose, or the said claim or cause\n                        will be forever barred, without regard to any contrary legislation. We\n                        may assign our rights and obligations under these Terms of use.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _c("span", { staticStyle: { color: "rgb(64, 64, 64)" } }, [
                  _vm._v("23. Severability."),
                ]),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        In the event that any provision of these Terms of Use is deemed to\n                        conflict with legislation by a court with jurisdiction over the parties,\n                        the said provision will be interpreted to reflect the original\n                        intentions of the parties in accordance with applicable law, and the\n                        remainder of these Terms of Use will remain valid and applicable.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [
          _c(
            "span",
            { staticStyle: { "font-family": "Calibri, sans-serif" } },
            [
              _c("span", { staticStyle: { "font-size": "15px" } }, [
                _vm._v("24. Contact Information."),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: {
              "list-style-type": "lower-alpha",
              "margin-left": "0.5in",
            },
            attrs: { start: "1" },
          },
          [
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        By Mail.  Global Space Exchange, Inc.800 Maine Ave. SW, Suite #200;\n                        Washington DC, 20024.\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                { staticStyle: { "font-family": "Calibri, sans-serif" } },
                [
                  _c("span", { staticStyle: { "font-size": "15px" } }, [
                    _vm._v(
                      "\n                        By email. Send an email support@globalspaceexchange.com\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-family": "Calibri, sans-serif",
                    "font-size": "15px",
                  },
                },
                [
                  _vm._v(
                    "\n                    By Phone: +1-202-600-8252.\n                "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }