var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticStyle: { width: "80%", margin: "50px auto" } }, [
    _c(
      "video",
      {
        attrs: { width: "100%", autoplay: "", controls: "", muted: "" },
        domProps: { muted: true },
      },
      [
        _c("source", {
          attrs: {
            src: require("../../images/New_Format_GSE_Promo_Video_rev7_1080p.mp4"),
          },
        }),
        _vm._v("\n        Your browser doesn't support this video\n    "),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }